.divBottonRight {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  align-items: center;
  height: 100%;
  width: 50%;
}

.titlesGraph {
  display: flex;
  background-color: rgb(36, 116, 236, 0.2);
  color:aqua; 
  font-weight:100; 
  height: 16px;
  width: 100%;
}

.Graph {
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  width: 90%;
  padding-top: 1%;
  height: 100%;
  left:-2.3%;
}

@media screen and (max-width: 900px) {
  .divBottonRight{
    width: 100%;
  }

  .Graph{
    height: 250px;
  } 
}

@media screen and (max-width: 500px) {

  .Graph{
    height: 200px;
    width: 92%;
    margin-left: 15px;
  } 
}