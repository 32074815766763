.containerListSlides {
  width: 100%;
  overflow-y: auto;
}

.containerListSlides ul {
  padding: 0;
}

.containerSlide {
  margin-bottom: 5px;
  list-style: none;
  display: flex;
  align-items: center;
}

.selectSlide {
  width: auto;
  height: auto;
  padding: 5px;
  box-shadow: rgb(15, 103, 204) 0px 30px 60px -12px inset,
    rgba(15, 103, 204) 0px 18px 36px -18px inset;
}

.noSelectSlide {
  width: auto;
  height: auto;
  padding: 5px;

  &:hover {
    box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px inset,
      rgba(0, 0, 0, 0.3) 0px 18px 36px -18px inset;
  }
}

.containerSlide p {
  margin: unset;
  padding-right: 3px;
}

.styleInput {
  display: none;
}
