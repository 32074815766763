$player-color: #30963e
$close-button-color: purple

.audio-player
  position: absolute
  width: 210px
  top: 50px
  left: 50px
  z-index: 1
  overflow: hidden

  .audio-button
    display: flex
    align-items: center
    border: 1px solid $player-color
    backdrop-filter: blur(10px)
    border-radius: 20px
    padding: 5px
    cursor: pointer
    color: $player-color
    background: none
    z-index: -1

    .audio-name
      color: black
      flex: 1
      text-align: center
      margin-left: 10px
      font-weight: bold

    .audio-name-crystal
      color: white !important
      flex: 1
      text-align: center
      margin-left: 10px
      font-weight: bold

    .audio-close
      background: none
      border: none
      color: $player-color
      cursor: pointer
      margin-left: 5px
      font-weight: bold


.audio-modal 
  position: absolute
  width: 75vw
  height: 21.5vh 
  border-radius: 10px
  display: flex
  flex-direction: column
  padding: 0px 40px 30px 10
  justify-content: center
  align-items: center
  top: 50px
  left: calc((90vw - 70vw) / 2)
  


  &.crystal 
    width: 70vw !important
    height: 21vh 
    left: calc((90vw - 70vw) / 2)
    top:28.5px

  

  .controls 
    display: flex
    align-items: center
    margin-top:14px
    margin-bottom: -2.5px  
    z-index: 1

    .restart svg
      width: 42px
      height: 42px

    .volume-button 
      background: none
      border: none
      cursor: pointer

      &:hover 
        background: rgba(128, 0, 128, 0.1)
      
    

    .volume-control 
      margin-left: 10px
      width: 150px
      -webkit-appearance: none
      height: 5px
      background: rgba(0, 0, 0, 0.3)
      border-radius: 5px
      outline: none

      &::-webkit-slider-thumb 
        -webkit-appearance: none
        appearance: none
        width: 15px
        height: 15px
        background: #7719BA
        border-radius: 50%
        cursor: pointer

      &::-moz-range-thumb 
        width: 15px
        height: 15px
        background: #7719BA
        border-radius: 50%
        cursor: pointer
      
    
  

  .audio-close 
    background: rgba(255, 255, 255, 0.1)
    border: none
    font-weight: bold
    font-size: 20px
    color: rgba(112,25,202)
    width: 40px
    height: 40px
    border-radius: 50%
    position: absolute
    top: 3px
    right: 12px
    transition: background 0.3s
    z-index: 1
    
    &:hover 
      background: rgba(128, 0, 128, 0.1)

    .close-icon
      font-size: 30px
      
  .controls button 
    background: none
    border: none
    cursor: pointer
    transition: background 0.3s

    &:hover 
      background: rgba(128, 0, 128, 0.1)
    

    &:focus 
      outline: none
    

    svg 
      width: 45px
      height: 45px
      color: rgb(144,12,244)
    

    &.play svg 
      color: rgb(144,12,244)
      align-items: center
      justify-content: center
      width: 55px
      height: 55px
    

    &.left svg 
      transform: scaleX(-1) !important
    
  


@media (max-width: 1200px) 
   .controls button svg 
    width: 35px
    height: 35px


@media (max-width: 600px) 
  .audio-modal 
    width: 50vw
    z-index: 1000
    position: absolute
    top: 50px !important
    left: none
  
  
    
    &.crystal 
      width: 50vw !important
      top: 50px !important
      left: 24vw !important

    .controls
      display: flex
      align-items: center

      .restart svg
        width: 22px 
        height: 22px 
      
      button
        &.play svg 
          width: 30px
          height: 30px

        svg
          width: 25px
          height: 25px
      

      &.left svg 
        transform: scaleX(-1) !important


    
  @media (max-width: 550px) 
    .audio-modal 
      &.crystal
          width: 40vw 
          top: 60px !important
          left: 24vw !important

    .audio-modal            
          width: 40vw 
          top: 60px !important
          left: 24vw !important
  @media (max-width: 500px) 
    .audio-modal 
      &.crystal
          width: 40vw 
          top: 60px !important
          left: 24vw !important
    .audio-modal         
          width: 40vw 
          top: 60px !important
          left: 24vw !important 



