.editModeButton{
    position: absolute;
    z-index: 100;
    top: 25px;
    right: 35px;
    font-size: 18px;
    padding: 1px 10px;
    border-radius: 3px;
    transition: all 250ms;
    color: blue;
    user-select: none;

    &:hover{
        background-color: aquamarine;
        color: black;
        cursor: pointer;
    }
}

.saveButton{
    position: absolute;
    z-index: 100;
    top: 60px;
    right: 35px;
    font-size: 18px;
    padding: 1px 10px;
    border-radius: 3px;
    transition: all 250ms;
    color: blue;
    user-select: none;

    &:hover{
        background-color: aquamarine;
        color: black;
        cursor: pointer;
    }
}