.blinding {
    animation: toggle 2.5s infinite linear both;
}

.booking-title {
    font-size: 1.5rem;
    color: #fff;
    font-family: Roboto, sans-serif;
    font-weight: 400;
}

.floating-footer {
    position: absolute;
    bottom: 0;
    width: 89.5vw;
}

.footer-content-booking {
    width: 100%;
    margin: 0px;
    display: flex;
    justify-content: center;
    background: rgba(49, 136, 223, 0.4);
    color: white;
    font-size: 24px;
    align-items: center;
    position: absolute;
    bottom: 0;
    left: 0;
    height: 10vh;
    padding: 0px 12.5px;
}

.options-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-direction: row;
    gap: 1em;
}

@media screen and (max-screen: and) {
    .options-container {
        margin-bottom: 50px;
    }
}

.summary-title {
    font-size: 16px;
    position: absolute;
    top: 0px;
    left: 5px;
    margin-bottom: 0px;
    margin-right: 15px;
}

.summary-send {
    position: absolute;
    bottom: 5px;
    right: 10px;
    cursor: pointer;
    align-self: flex-end;
}

.time_text_p {
    margin: 5px;
    align-self: flex-start;
    color: #fff;
    font-size: 14px;
    position: absolute;
    bottom: 0px;
    left: 5px;
}

.responsive-info-title {
    padding-top: 30px;
    position: absolute;
    top: 0;
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center !important;
}

.week-days-responsive-container {
    max-height: 57vh;
    width: 95%;
    min-height: 57vh !important;
    overflow-y: auto;
}

.next_button_p {
    display: none;
}

.back_button_p {
    display: none;
}

.flex-c-ac {
    display: flex;
    align-items: center;
    flex-direction: column;
}

.flex-c-ac-jc {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
}

.booking-buttons {
    width: 25%;
    /* background-color: #5ce550; */
    display: flex;
    min-height: 65px;
    max-height: 65px;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
}

.booking-buttons>p {
    margin-bottom: 0;
    text-align: center;
    width: 100%;
}


.current-selected-time {
    color: #fff;
    padding: 0;
    margin: 0;
    font-size: 25px;
}

.choose-hour-time-input {
    display: flex;
    flex-direction: row;
    margin-left: 10px;
    align-items: center;
}

#interview-wrapper {
    display: flex;
}

.row-texts {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
}

.time-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

#interview-wrapper-responsive {
    display: none;
}

.week-days-wrapper {
    display: flex;
    border: 1.5px solid white;
    flex-direction: column;
    width: 100%;
    height: 100%;
}

.userinfo-tags {
    font-size: 1.1rem;
    width: fit-content;
}


@media screen and (max-width: 750px) {
    #interview-wrapper {
        display: none;
    }

    .time_text_p {
        margin: 0px;
        left: 10px;
        font-size: 12px;
    }

    .summary-title {
        font-size: 11.5px;
    }

    .booking-buttons {
        margin-top: 9px;
    }

    .time-container {
        min-height: 68vh;
    }

    .description_text {
        z-index: 100 !important;
    }

    .back_button_p {
        display: block;
        align-self: flex-start;
        width: fit-content;
        color: #fff;
        font-size: 14px;
        position: absolute;
        top: 0px;
        margin: 0px;
        left: 10px;
        font-size: 12px;
    }

    .options-container {
        margin-bottom: 0;
    }

    .back_button_p:hover {
        cursor: pointer;
    }

    .next_button_p {
        display: block;
        align-self: flex-start;
        color: #fff;
        font-size: 16px !important;
        position: absolute;
        bottom: 0px;
        margin: 0px;
        right: 10px;
        font-size: 12px;
    }

    .next_button_p:hover {
        cursor: pointer;
    }

    #interview-wrapper-responsive {
        display: flex;
        flex-direction: column;
        width: 100%;
    }

    .week-days-wrapper {
        border: none;
    }

    .current-selected-time {
        font-size: 30px;
    }

    .footer-content-booking {
        max-width: 100%;
        margin: 0 auto;
        flex-direction: row;
        height: 11vh;
    }
}



@keyframes toggle {
    0% {
        background: rgba(49, 136, 223, 0.4);
    }

    50% {
        background: rgba(49, 136, 223, 0.1);
    }

    100% {
        background: rgba(49, 136, 223, 0.4);
    }
}

/* animations */

.twinkleText {
    animation-name: twinkle;
    animation-duration: 2s;
    animation-timing-function: linear;
    animation-iteration-count: infinite;

    -webkit-animation-name: twinkle;
    -webkit-animation-duration: s;
    -webkit-animation-timing-function: linear;
    -webkit-animation-iteration-count: infinite;
}

@-moz-keyframes twinkle {
    0% {
        opacity: 1.0;
    }

    50% {
        opacity: 0.65;
    }

    100% {
        opacity: 1.0;
    }
}

@-webkit-keyframes twinkle {
    0% {
        opacity: 1.0;
    }

    50% {
        opacity: 0.65;
    }

    100% {
        opacity: 1.0;
    }
}

@keyframes twinkle {
    0% {
        opacity: 1.0;
    }

    50% {
        opacity: 0.65;
    }

    100% {
        opacity: 1.0;
    }
}

.congratulationsText {
    animation-name: congratulations;
    animation-duration: 2s;
    animation-timing-function: linear;
    animation-iteration-count: infinite;

    -webkit-animation-name: congratulations;
    -webkit-animation-duration: 2s;
    -webkit-animation-timing-function: linear;
    -webkit-animation-iteration-count: infinite;
}

@-moz-keyframes congratulations {
    0% {
        opacity: 1.0;
    }

    50% {
        opacity: 1;
        transform: scale(1.05);
    }

    100% {
        opacity: 1.0;
    }
}

@-webkit-keyframes congratulations {
    0% {
        opacity: 1.0;
    }

    50% {
        opacity: 1;
        transform: scale(1.05);
    }

    100% {
        opacity: 1.0;
    }
}

@keyframes congratulations {
    0% {
        opacity: 1.0;
    }

    50% {
        opacity: 1;
        transform: scale(1.05);
    }

    100% {
        opacity: 1.0;
    }
}

.arrowNextAnimation {
    animation-name: arrow-animation;
    animation-duration: 2s;
    animation-timing-function: linear;
    animation-iteration-count: infinite;

    -webkit-animation-name: arrow-animation;
    -webkit-animation-duration: 2s;
    -webkit-animation-timing-function: linear;
    -webkit-animation-iteration-count: infinite;
}

@-moz-keyframes arrow-animation {
    0% {
        opacity: 1.0;
    }

    50% {
        opacity: 0.4;
    }

    100% {
        opacity: 1.0;
    }
}

@-webkit-keyframes arrow-animation {
    0% {
        opacity: 1.0;
    }

    50% {
        opacity: 0.4;
    }

    100% {
        opacity: 1.0;
    }
}

@keyframes arrow-animation {
    0% {
        opacity: 1.0;
    }

    50% {
        opacity: 0.4;
    }

    100% {
        opacity: 1.0;
    }
}



.Interview-Layouts-enter {
    opacity: 0;
    transform: translateX(1000px);
    transition: all 200ms ease;
}

.Interview-Layouts-enter-active {
    opacity: 1;
    transform: translateX(0px);
    transition: all 200ms ease;
}

.Interview-Layouts-exit {
    transform: translateX(0px);
    transition: all 2000ms ease;
}

.Interview-Layouts-exit-active {
    transform: translateX(-1000px);
    transition: all 2000ms ease;
}

.hide-clock {
    transition: all 1000ms ease;
}

.show-clock {
    opacity: 1;
    transition: all 500ms ease;
}

.description_text {
    position: absolute;
    font-size: 14px;
    top: 0px;
    left: 10px;
}

.rounded {
    position: relative;
}

.rounded>label {
    background-color: #5ce550;
    border: 1px solid #ccc;
    border-radius: 50%;
    cursor: pointer;
    height: 10px;
    right: -15px;
    position: absolute;
    top: -15px;
    width: 10px;
}

.hide-clock-buttons {
    opacity: 0;
    transition: all 200ms ease;
}

.show-clock-buttons {
    opacity: 1;
    transition: all 200ms ease;
}


.Opacity-Animation-enter {
    opacity: 0;
    transition: all 120ms ease;
}

.Opacity-Animation-enter-active {
    opacity: 1;
    transition: all 120ms ease;
}

.Opacity-Animation-exit {
    opacity: 1;
    transition: all 120ms ease;
}

.Opacity-Animation-exit-active {
    opacity: 0;
    transition: all 120ms ease;
}