@import 'assets/scss/main/_variables';

.uploadPicture {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }

.uploadPictureTakePicture{
    cursor: pointer;
}

.uploadPicture{
  div{
    padding: 0 2.5% 0 2.5%;
  }
}

.labelInput{
  display: none;
}

.userData{
  display: flex;
  flex-direction: column;
  align-items: center;

  p{
    margin: 0;
    font-size: 12px;
    color: #fff;
  }
}
