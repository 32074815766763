.divContent {
  width: 90%;
  padding: 2em;
  margin: 0 auto;
  background-color: rgba(84, 110, 255, 0.23);
}

.go-to-back {
  color: white;
  font-size: 1.5em;
  margin-bottom: 1.5em;
  margin-left: 1.5em;
}

@media screen and (min-width: 320px) and (max-width: 480px) {
  .divContent {
    padding: 1.5em 0.2em;
  }

  .go-to-back {
    font-size: 1em;
    margin-left: 1.5em;
    margin-bottom: 5px;
  }
}
