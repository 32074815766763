.question-info {
  position: absolute;
  bottom: 0.313em;
  box-sizing: border-box;
  left: 0.625em;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  & > span {
    color: #000;
    margin-right: 1.3em;

    @media (max-width: 1100px) {
      display: flex;
      flex-direction: row;
      justify-content: center;
    }

    @media (min-width: 1500px) {
      font-size: 1.1em;
    }
    //
    @media (min-width: 1600px) {
      font-size: 1.3em;
    }

    @media (min-width: 1700px) {
      font-size: 1.5em;
    }
  }
}

.checkmark-input {
  color: #000;
}

.responsive-exam-command {
  line-height: 1em;
  padding-left: 2em;
  width: 100%;
  display: flex !important;
  align-self: center;
  text-align: left;
  justify-content: left;
  font-size: 1.5em;
  font-weight: bold;
  color: #025fda;
  overflow: hidden;
  overflow-wrap: break-word;
  word-break: break-word;
  white-space: normal;

  @media (max-width: 1100px) {
    margin-top: -1em;
    z-index: -5;
    line-height: 0.95em;
    font-weight: 400;
    padding-left: 1em;
    text-align: left !important;
    font-size: 1.2em;
  }

  @media (min-width: 1500px) {
    font-size: 1.5em;
  }

  @media (min-width: 1600px) {
    font-size: 1.6em;
  }
  @media (min-width: 1700px) {
    font-size: 1.8em;
  }

  @media screen and (max-height: 620px) {
    & {
      margin-bottom: 10px !important;
    }
  }

  &__display {
    display: none !important;

    @media screen and (max-width: 1100px) {
      & {
        display: flex !important;
        width: fit-content;
        align-self: center;
        text-align: center;
        font-size: 1.5rem;
        font-weight: bold;
        margin-bottom: 0;
      }
    }
  }
}

.statement-input {
  margin-left: 0.188em;
  padding: 0px;

  @media screen and (max-width: 1100px) {
    & {
      padding: 5px 0px;
    }
  }
}

.question-image-usetakingdimentions {
  height: fit-content !important;
  margin-top: 0px;
  border: none !important;

  @media screen and (max-width: 1100px) {
    & {
      // margin-top: 15px;
      height: 13em !important;
    }
  }
}

.button_clear_image {
  margin-top: 25em;
  position: fixed;
  width: 25em;
  z-index: 5;
  padding-left: 5em;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;

  @media (min-width: 1500px) {
    margin-top: 25em;
    width: 32em;
  }

  @media (max-width: 1600px) {
    margin-top: 25em;
    width: 32em;
  }

  @media (min-width: 1700px) {
    margin-top: 26.2em;
    width: 32em;
  }
}
@media (max-width: 1100px) {
  .button_clear_image {
    display: none;
  }
}

@media (min-width: 1100px) {
  .button-clear {
    display: none;
  }
}

.options-container.padding-left {
  @media (min-width: 1100px) {
    padding-left: 11em;
  }
}

.btn_clear {
  background: transparent;
  color: #61fb69;
  border: 1px solid #61fb69;
  cursor: pointer;
  outline: none;
  transition: all 0.3s ease;
}

.btn_clear:hover {
  background-color: rgba(97, 251, 105, 0.1);
}

.btn_clear:active {
  transform: scale(0.98);
}
.audio-options-exam {
  position: relative;
  z-index: 100;
  width: auto;
  max-width: 75%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  padding-left: 0.5em;
  z-index: 2000;

  @media (max-width: 1100px) {
    margin-top: -4em;
    position: relative;
    z-index: 5;
    width: 80%;
    max-width: 100%;
    padding-left: 1em;
    align-items: center;
    justify-content: center;
  }

  @media (min-width: 1500px) {
    max-width: 75%;
  }
  @media (min-width: 1600px) {
    max-width: 78%;
  }
  @media (min-width: 1700px) {
    max-width: 79%;
  }

  &__audio {
    width: 0.983em;
  }

  &__record {
    width: 0.75em; /* Se redujo el ancho del elemento */
  }
}

.form-body .question-body:not(:has(.image-container:not(:empty))) {
  padding-top: 7em;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  height: auto;

  @media (max-width: 1100px) {
    padding-top: 1em;
    justify-content: center;
  }

  @media (min-width: 1500px) {
    padding-top: 8em;
  }
  @media (min-width: 1600px) {
    padding-top: 9em;
  }
  @media (min-width: 1700px) {
    padding-top: 10em;
  }
}

.question {
  width: 100%;
  padding-left: 4em;

  @media (min-width: 1500px) {
    padding-left: 5em;
  }
  @media (min-width: 1600px) {
    padding-left: 5em;
  }
  @media (min-width: 1700px) {
    padding-left: 5em;
  }

  @media screen and (max-width: 1100px) {
    padding-left: 0;
  }

  .form-body {
    height: auto;

    .question-body {
      height: auto;
      display: flex !important;
      justify-content: center;
      align-items: center;
      gap: 0;

      @media (max-width: 1100px) {
      }

      &__width-defined {
        padding-left: 3em;
        width: 85%;
        justify-content: flex-end;
        align-items: flex-start;
        height: 100%;

        @media (max-width: 1100px) {
          padding-left: 0;
          width: 92%;
        }

        @media (min-width: 1500px) {
          padding-left: 3em;
        }

        @media (min-width: 1600px) {
          padding-left: 3em;
        }

        @media (min-width: 1700px) {
          padding-left: 3em;
        }

        .statement-taking-exam {
          .statement__question-number {
            background-color: #481aa7;
            font-size: 1.2rem;

            @media (max-width: 1100px) {
              background-color: #28a745;
            }

            @media (min-width: 1500px) {
              font-size: 1.3rem;
            }

            @media (min-width: 1600px) {
              font-size: 1.5rem;
            }
            @media (min-width: 1700px) {
              font-size: 1.8rem;
            }
          }
        }

        .options-container {
          width: 100%;
          flex-direction: column;
          display: flex;
          justify-content: flex-start;
          align-items: flex-start;
          gap: 0.2em;

          @media (min-width: 1500px) {
            gap: 0.7em;
          }

          @media (min-width: 1600px) {
            gap: 0.8em;
          }

          @media (min-width: 1700px) {
            gap: 0.8em;
          }

          @media screen and (max-width: 1100px) {
            gap: 0.6em;
            width: 100%;
            height: auto !important;
            margin-bottom: 0;
          }

          .options {
            height: 4em;
            width: 70%;
            background-color: #e2fcfd;

            @media (max-width: 1100px) {
              width: 100%;
              height: 5.1em;
              background-color: transparent;
            }

            @media (min-width: 1500px) {
              height: 5.1em;
            }
            @media (min-width: 1600px) {
              height: 5.7em;
            }

            @media (min-width: 1700px) {
              height: 6em;
            }

            .input-container {
              line-height: 1.5em;
              height: 100% !important;
              padding-left: 15px;
              padding-right: 0;
              border: 1px solid var(--border-color);

              &__text {
                color: #000 !important;
                font-size: 1.6rem;

                @media (max-width: 1100px) {
                  font-weight: 400;
                }

                @media (min-width: 1500px) {
                  font-size: 2rem;
                }

                @media (min-width: 1600px) {
                  font-size: 2.2rem;
                }

                @media (min-width: 1700px) {
                  font-size: 2.2rem;
                }
              }
            }

            &__selected {
              box-sizing: border-box;
              background-color: #50baff;

              animation-name: selectAnimation;
              animation-duration: 0.2s;
              animation-timing-function: linear;
              animation-iteration-count: 1;

              -webkit-animation-name: selectAnimation;
              -webkit-animation-duration: 0.2s;
              -webkit-animation-timing-function: linear;
              -webkit-animation-iteration-count: 1;

              @-moz-keyframes selectAnimation {
                0% {
                  opacity: 1;
                }

                50% {
                  background: #75c8ffb9;
                }

                100% {
                  opacity: 1;
                }
              }

              @-webkit-keyframes selectAnimation {
                0% {
                  opacity: 1;
                }

                50% {
                  background: #75c8ffb9;
                }

                100% {
                  opacity: 1;
                }
              }

              @keyframes selectAnimation {
                0% {
                  opacity: 1;
                }

                50% {
                  background: #75c8ffb9;
                }

                100% {
                  opacity: 1;
                }
              }
            }

            @media screen and (max-width: 1100px) {
              & {
                height: 6.5vh;
                padding: 0px 0;

                .input-container {
                  &__text {
                    line-height: 0.95em;
                    font-size: 1.6rem;
                  }
                }
              }
            }
          }

          .image-container:empty ~ * {
            justify-content: center;
          }

          @media screen and (max-width: 1100px) {
            & {
              padding-left: 1.8em;
              margin-top: 1.5em;
            }
          }
        }
      }
    }

    @media screen and (max-width: 1100px) {
      & {
        height: 100%;
      }
    }
  }

  @media screen and (max-width: 1100px) {
    & {
      width: 100%;
      height: 100%;
      justify-content: center;
      align-items: center;
    }
  }

  //yellow- scheme

  &__yellow-scheme {
    width: 100%;
    padding-left: 4em;

    @media (min-width: 1500px) {
      padding-left: 5em;
    }
    @media (min-width: 1600px) {
      padding-left: 5em;
    }
    @media (min-width: 1700px) {
      padding-left: 5em;
    }

    @media screen and (max-width: 1100px) {
      padding-left: 0;
    }

    .form-body {
      height: auto;

      .question-body {
        height: auto;
        display: flex !important;
        justify-content: center;
        align-items: center;
        gap: 0;

        @media (max-width: 1100px) {
        }

        &__width-defined {
          padding-left: 3em;
          width: 85%;
          justify-content: flex-end;
          align-items: flex-start;
          height: 100%;

          @media (max-width: 1100px) {
            padding-left: 0;
            width: 92%;
          }

          @media (min-width: 1500px) {
            padding-left: 3em;
          }

          @media (min-width: 1600px) {
            padding-left: 3em;
          }

          @media (min-width: 1700px) {
            padding-left: 3em;
          }

          .statement-taking-exam {
            .statement__question-number {
              background-color: #fef162; /* Color amarillo */
              font-size: 1.2rem;

              @media (max-width: 1100px) {
                background-color: #fef162;
              }

              @media (min-width: 1500px) {
                font-size: 1.3rem;
              }

              @media (min-width: 1600px) {
                font-size: 1.5rem;
              }
              @media (min-width: 1700px) {
                font-size: 1.8rem;
              }
            }
          }

          .options-container {
            width: 100%;
            flex-direction: column;
            display: flex;
            justify-content: flex-start;
            align-items: flex-start;
            gap: 0.2em;

            @media (min-width: 1500px) {
              gap: 0.7em;
            }

            @media (min-width: 1600px) {
              gap: 0.8em;
            }

            @media (min-width: 1700px) {
              gap: 0.8em;
            }

            @media screen and (max-width: 1100px) {
              gap: 0.6em;
              width: 100%;
              height: auto !important;
              margin-bottom: 0;
            }

            .options {
              height: 4em;
              width: 70%;
              background-color: #fffba0;

              @media (max-width: 1100px) {
                width: 100%;
                height: 5.1em;
                background-color: transparent;
              }

              @media (min-width: 1500px) {
                height: 5.1em;
              }
              @media (min-width: 1600px) {
                height: 5.7em;
              }

              @media (min-width: 1700px) {
                height: 6em;
              }

              .input-container {
                line-height: 1.5em;
                height: 100% !important;
                padding-left: 15px;
                padding-right: 0;
                border: 1px solid var(--border-color);

                &__text {
                  color: #000 !important;
                  font-size: 1.6rem;

                  @media (max-width: 1100px) {
                    font-weight: 400;
                  }

                  @media (min-width: 1500px) {
                    font-size: 2rem;
                  }

                  @media (min-width: 1600px) {
                    font-size: 2.2rem;
                  }

                  @media (min-width: 1700px) {
                    font-size: 2.2rem;
                  }
                }
              }

              &__selected {
                box-sizing: border-box;
                background-color: #f0d279dd;

                animation-name: selectAnimation;
                animation-duration: 0.2s;
                animation-timing-function: linear;
                animation-iteration-count: 1;

                -webkit-animation-name: selectAnimation;
                -webkit-animation-duration: 0.2s;
                -webkit-animation-timing-function: linear;
                -webkit-animation-iteration-count: 1;

                @-moz-keyframes selectAnimation {
                  0% {
                    opacity: 1;
                  }

                  50% {
                    background: #ffecb3b9;
                  }

                  100% {
                    opacity: 1;
                  }
                }

                @-webkit-keyframes selectAnimation {
                  0% {
                    opacity: 1;
                  }

                  50% {
                    background: #ffecb3b9;
                  }

                  100% {
                    opacity: 1;
                  }
                }

                @keyframes selectAnimation {
                  0% {
                    opacity: 1;
                  }

                  50% {
                    background: #ffecb3b9;
                  }

                  100% {
                    opacity: 1;
                  }
                }
              }

              @media screen and (max-width: 1100px) {
                & {
                  height: 6.5vh;
                  padding: 0px 0;

                  .input-container {
                    &__text {
                      line-height: 0.95em;
                      font-size: 1.6rem;
                    }
                  }
                }
              }
            }

            .image-container:empty ~ * {
              justify-content: center;
            }

            @media screen and (max-width: 1100px) {
              & {
                padding-left: 1.8em;
                margin-top: 1.5em;
              }
            }
          }
        }
      }

      @media screen and (max-width: 1100px) {
        & {
          height: 100%;
        }
      }
    }

    @media screen and (max-width: 1100px) {
      & {
        width: 100%;
        height: 100%;
        justify-content: center;
        align-items: center;
      }
    }
  }

  //green scheme

  &__green-scheme {
    width: 100%;
    padding-left: 4em;

    @media (min-width: 1500px) {
      padding-left: 5em;
    }
    @media (min-width: 1600px) {
      padding-left: 5em;
    }
    @media (min-width: 1700px) {
      padding-left: 5em;
    }

    @media screen and (max-width: 1100px) {
      padding-left: 0;
    }

    .form-body {
      height: auto;

      .question-body {
        height: auto;
        display: flex !important;
        justify-content: center;
        align-items: center;
        gap: 0;

        @media (max-width: 1100px) {
        }

        &__width-defined {
          padding-left: 3em;
          width: 85%;
          justify-content: flex-end;
          align-items: flex-start;
          height: 100%;

          @media (max-width: 1100px) {
            padding-left: 0;
            width: 92%;
          }

          @media (min-width: 1500px) {
            padding-left: 3em;
          }

          @media (min-width: 1600px) {
            padding-left: 3em;
          }

          @media (min-width: 1700px) {
            padding-left: 3em;
          }

          .statement-taking-exam {
            .statement__question-number {
              background-color: #a6f369;
              font-size: 1.2rem;

              @media (max-width: 1100px) {
                background-color: #a6f369;
              }

              @media (min-width: 1500px) {
                font-size: 1.3rem;
              }

              @media (min-width: 1600px) {
                font-size: 1.5rem;
              }
              @media (min-width: 1700px) {
                font-size: 1.8rem;
              }
            }
          }

          .options-container {
            width: 100%;
            flex-direction: column;
            display: flex;
            justify-content: flex-start;
            align-items: flex-start;
            gap: 0.2em;

            @media (min-width: 1500px) {
              gap: 0.7em;
            }

            @media (min-width: 1600px) {
              gap: 0.8em;
            }

            @media (min-width: 1700px) {
              gap: 0.8em;
            }

            @media screen and (max-width: 1100px) {
              gap: 0.6em;
              width: 100%;
              height: auto !important;
              margin-bottom: 0;
            }

            .options {
              height: 4em;
              width: 70%;
              background-color: #dcf7cb;

              @media (max-width: 1100px) {
                width: 100%;
                height: 5.1em;
                background-color: transparent;
              }

              @media (min-width: 1500px) {
                height: 5.1em;
              }
              @media (min-width: 1600px) {
                height: 5.7em;
              }

              @media (min-width: 1700px) {
                height: 6em;
              }

              .input-container {
                line-height: 1.5em;
                height: 100% !important;
                padding-left: 15px;
                padding-right: 0;
                border: 1px solid var(--border-color);

                &__text {
                  color: #000 !important;
                  font-size: 1.6rem;

                  @media (max-width: 1100px) {
                    font-weight: 400;
                  }

                  @media (min-width: 1500px) {
                    font-size: 2rem;
                  }

                  @media (min-width: 1600px) {
                    font-size: 2.2rem;
                  }

                  @media (min-width: 1700px) {
                    font-size: 2.2rem;
                  }
                }
              }

              &__selected {
                box-sizing: border-box;
                background-color: #50baff;

                animation-name: selectAnimation;
                animation-duration: 0.2s;
                animation-timing-function: linear;
                animation-iteration-count: 1;

                -webkit-animation-name: selectAnimation;
                -webkit-animation-duration: 0.2s;
                -webkit-animation-timing-function: linear;
                -webkit-animation-iteration-count: 1;

                @-moz-keyframes selectAnimation {
                  0% {
                    opacity: 1;
                  }

                  50% {
                    background: #75c8ffb9;
                  }

                  100% {
                    opacity: 1;
                  }
                }

                @-webkit-keyframes selectAnimation {
                  0% {
                    opacity: 1;
                  }

                  50% {
                    background: #75c8ffb9;
                  }

                  100% {
                    opacity: 1;
                  }
                }

                @keyframes selectAnimation {
                  0% {
                    opacity: 1;
                  }

                  50% {
                    background: #75c8ffb9;
                  }

                  100% {
                    opacity: 1;
                  }
                }
              }

              @media screen and (max-width: 1100px) {
                & {
                  height: 6.5vh;
                  padding: 0px 0;

                  .input-container {
                    &__text {
                      line-height: 0.95em;
                      font-size: 1.6rem;
                    }
                  }
                }
              }
            }

            .image-container:empty ~ * {
              justify-content: center;
            }

            @media screen and (max-width: 1100px) {
              & {
                padding-left: 1.8em;
                margin-top: 1.5em;
              }
            }
          }
        }
      }

      @media screen and (max-width: 1100px) {
        & {
          height: 100%;
        }
      }
    }

    @media screen and (max-width: 1100px) {
      & {
        width: 100%;
        height: 100%;
        justify-content: center;
        align-items: center;
      }
    }
  }

  &__blue-scheme {
    width: 100%;
    padding-left: 4em;

    @media (min-width: 1500px) {
      padding-left: 5em;
    }
    @media (min-width: 1600px) {
      padding-left: 5em;
    }
    @media (min-width: 1700px) {
      padding-left: 5em;
    }

    @media screen and (max-width: 1100px) {
      padding-left: 0;
    }

    .form-body {
      height: auto;

      .question-body {
        height: auto;
        display: flex !important;
        justify-content: center;
        align-items: center;
        gap: 0;

        @media (max-width: 1100px) {
        }

        &__width-defined {
          padding-left: 3em;
          width: 85%;
          justify-content: flex-end;
          align-items: flex-start;
          height: 100%;

          @media (max-width: 1100px) {
            padding-left: 0;
            width: 92%;
          }

          @media (min-width: 1500px) {
            padding-left: 3em;
          }

          @media (min-width: 1600px) {
            padding-left: 3em;
          }

          @media (min-width: 1700px) {
            padding-left: 3em;
          }

          .statement-taking-exam {
            .statement__question-number {
              background-color: #481aa7;
              font-size: 1.2rem;

              @media (max-width: 1100px) {
                background-color: #28a745;
              }

              @media (min-width: 1500px) {
                font-size: 1.3rem;
              }

              @media (min-width: 1600px) {
                font-size: 1.5rem;
              }
              @media (min-width: 1700px) {
                font-size: 1.8rem;
              }
            }
          }

          .options-container {
            width: 100%;
            flex-direction: column;
            display: flex;
            justify-content: flex-start;
            align-items: flex-start;
            gap: 0.2em;

            @media (min-width: 1500px) {
              gap: 0.7em;
            }

            @media (min-width: 1600px) {
              gap: 0.8em;
            }

            @media (min-width: 1700px) {
              gap: 0.8em;
            }

            @media screen and (max-width: 1100px) {
              gap: 0.6em;
              width: 100%;
              height: auto !important;
              margin-bottom: 0;
            }

            .options {
              height: 4em;
              width: 70%;
              background-color: #e2fcfd;

              @media (max-width: 1100px) {
                width: 100%;
                height: 5.1em;
                background-color: transparent;
              }

              @media (min-width: 1500px) {
                height: 5.1em;
              }
              @media (min-width: 1600px) {
                height: 5.7em;
              }

              @media (min-width: 1700px) {
                height: 6em;
              }

              .input-container {
                line-height: 1.5em;
                height: 100% !important;
                padding-left: 15px;
                padding-right: 0;
                border: 1px solid var(--border-color);

                &__text {
                  color: #000 !important;
                  font-size: 1.6rem;

                  @media (max-width: 1100px) {
                    font-weight: 400;
                  }

                  @media (min-width: 1500px) {
                    font-size: 2rem;
                  }

                  @media (min-width: 1600px) {
                    font-size: 2.2rem;
                  }

                  @media (min-width: 1700px) {
                    font-size: 2.2rem;
                  }
                }
              }

              &__selected {
                box-sizing: border-box;
                background-color: #50baff;

                animation-name: selectAnimation;
                animation-duration: 0.2s;
                animation-timing-function: linear;
                animation-iteration-count: 1;

                -webkit-animation-name: selectAnimation;
                -webkit-animation-duration: 0.2s;
                -webkit-animation-timing-function: linear;
                -webkit-animation-iteration-count: 1;

                @-moz-keyframes selectAnimation {
                  0% {
                    opacity: 1;
                  }

                  50% {
                    background: #75c8ffb9;
                  }

                  100% {
                    opacity: 1;
                  }
                }

                @-webkit-keyframes selectAnimation {
                  0% {
                    opacity: 1;
                  }

                  50% {
                    background: #75c8ffb9;
                  }

                  100% {
                    opacity: 1;
                  }
                }

                @keyframes selectAnimation {
                  0% {
                    opacity: 1;
                  }

                  50% {
                    background: #75c8ffb9;
                  }

                  100% {
                    opacity: 1;
                  }
                }
              }

              @media screen and (max-width: 1100px) {
                & {
                  height: 6.5vh;
                  padding: 0px 0;

                  .input-container {
                    &__text {
                      line-height: 0.95em;
                      font-size: 1.6rem;
                    }
                  }
                }
              }
            }

            .image-container:empty ~ * {
              justify-content: center;
            }

            @media screen and (max-width: 1100px) {
              & {
                padding-left: 1.8em;
                margin-top: 1.5em;
              }
            }
          }
        }
      }

      @media screen and (max-width: 1100px) {
        & {
          height: 100%;
        }
      }
    }

    @media screen and (max-width: 1100px) {
      & {
        width: 100%;
        height: 100%;
        justify-content: center;
        align-items: center;
      }
    }
  }
}

.question-view {
  width: 100%;
  height: 97vh;
  display: flex;
  box-sizing: border-box;
  flex-direction: column;
  opacity: 1;
  align-items: center;
  transition: all 2000ms ease;
  position: relative;
  justify-content: center;
  position: relative;

  @media (max-width: 1100px) {
    height: 90vh;
  }

  &__animation {
    border: 1px solid blue;
    width: 100%;
    height: 98vh;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    position: relative;
    opacity: 0;
    transition: all 1000ms ease;

    @media screen and (max-width: 1100px) {
      & {
        height: 90vh;
      }
    }

    .question-view-container {
      width: 100%;
      position: relative;
      margin: 0;
      padding: 0 1.875em;

      &__fullwidth {
        margin: 0;
        width: 100%;
      }

      @media screen and (max-width: 1100px) {
        & {
          width: 100%;
          padding: 0;
          height: 90%;
        }
      }
    }

    .questions-buttons {
      width: 100%;
      justify-content: center;
      position: absolute;
      left: 0;
      bottom: 0;

      @media screen and (max-width: 1100px) {
        & {
          width: 100%;
          justify-content: center;
          margin-left: -5px;
          padding-right: 20px !important;
        }
      }
    }
  }

  .question-view-container {
    padding-top: 10em;

    width: 100%;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;

    @media (min-width: 1500px) {
      padding-top: 10em;
      padding-bottom: 0;
    }

    @media (min-width: 1600px) {
      padding-top: 10em;
      padding-bottom: 0;
    }

    @media (min-width: 1700px) {
      padding-top: 7em;
      padding-bottom: 2em;
    }

    &__fullwidth {
      margin: 0;
      width: 80%;
    }

    @media screen and (max-width: 1100px) {
      & {
        align-items: center;
        justify-content: center;
        width: 100%;
        height: auto;
      }
    }
  }

  .questions-buttons {
    @media screen and (max-width: 1100px) {
      & {
        width: 100%;
        justify-content: center;
        margin-left: -5px;
        justify-content: center;
        align-items: center;
        padding-bottom: 6em;
      }
    }
  }
}

.question-body {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  gap: 1rem;

  &__image-container {
    width: 30em;
    height: 100%;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin: auto 0;
    position: absolute;
    overflow: hidden;

    @media (max-width: 1100px) {
      top: 2em;
      width: 100%;
      align-self: center;
      height: 12em
    }

    @media (min-width: 1500px) {
      width: 42em;
    }

    @media (min-width: 1600px) {
      width: 48em;
    }

    @media (min-width: 1700px) {
      width: 48em;
    }
  }

  .width-defined__no-image {
    margin: 0;
    width: 42.5%;

    @media screen and (max-width: 1100px) {
      & {
        width: 100%;
      }
    }
  }

  &__width-defined {
    width: 90%;
    align-items: center;
    justify-content: center;

    @media screen and (max-width: 1100px) {
      width: 90%;
      height: auto;
    }

    .statement-taking-exam {
      width: 105%;
      flex-direction: row;
      align-items: flex-start;
      margin-bottom: 0.625em;
      justify-content: flex-start;
      white-space: nowrap;
      text-overflow: ellipsis;

      @media (max-width: 1100px) {
      }

      .statement__question-number {
        width: 2em !important;
        min-width: 2em !important;
        height: 2em !important;
        background-color: #282c34;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        color: #fff;
        margin-left: 0;
        margin-right: 0.625em;
        border-radius: 50%;
        font-size: 1.8em;

        @media (min-width: 1500px) {
          font-size: 2em;
        }
        @media (min-width: 1600px) {
          font-size: 2em;
        }
        @media (min-width: 1700px) {
          font-size: 2em;
        }

        & > p {
          margin-top: 1px !important;
          margin-bottom: 0 !important;
        }
      }

      .statement__text {
        margin: 0;
      }

      .statement__exam-text {
        display: flex;
        width: 100%;
        align-items: flex-start;
        background-color: transparent;
        font-size: 2rem;
        resize: none;
        flex-direction: row;
        margin-bottom: 0;
        font-weight: bold;
        text-align: left;
        color: #000;
        overflow: hidden;
        overflow-wrap: break-word;
        word-break: break-word;
        white-space: normal;
        @media (max-width: 1100px) {
          line-height: 1;
          font-weight: 400;
          padding-left: 0.5em;
          font-size: 1.5rem;
          display: flex;
          margin-top: -11.5em;
        }

        @media (min-width: 1500px) {
          font-size: 2em;
          width: 22em;
        }

        @media (min-width: 1600px) {
          font-size: 2.2em;
        }

        @media (min-width: 1700px) {
          font-size: 2.2em;
          width: 30em;
        }

        &:focus {
          outline: none;
        }
      }
    }
  }

  &__num-questions {
    margin-right: 0.625em;
    text-align: left;
  }

  .question-image {
    border: 1px solid var(--border-color);
    display: flex;
    align-items: center;
    justify-content: center;
    width: 42em;
    height: 35em;
  }

  .question-image__image {
    object-fit: contain;
    width: 50em;
    height: 38em;
  }

  @media screen and (min-width: 700px) {
    .question-image__image {
      height: 30em;
    }
  }

  @media screen and (max-width: 1100px) {
    & {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      margin-top: 6em;

      .question-image {
        border: 1px solid var(--border-color);
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: var(--img-height);

        &__image {
          object-fit: contain;
          width: 100%;
          height: 100%;
        }
      }
    }
  }
}

.exam-editor-container {
  .english-test-admin-dashboard {
    .checkmark-radio-button {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      height: 1.25em;
      width: 1.25em;
      margin-right: 1.25em;
      min-height: 1.25em;
      min-width: 1.25em;
      background-color: transparent;
      border-radius: 50%;
      border: 1px solid #61fb69;
      font-size: 16px;
      font-weight: bold;

      &__selected {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 20px;
        margin-right: 20px;
        width: 20px;
        min-height: 20px;
        min-width: 20px;
        border-radius: 50%;
        border: 1px solid #61fb69;
        font-size: 16px;
        font-weight: bold;
        background-color: #61fb69;

        & > p {
          color: #282c34;
        }
      }

      &--small {
        display: block;
        font-size: 0.398em;
        height: 0.398em;
        width: 0.398em;
      }

      @media screen and (max-width: 1100px) {
        & {
          display: flex;
          margin-left: -2.5px;
          flex-direction: column;
          align-items: center;
          margin-right: 10px !important;
          justify-content: center;
          height: 20px;
          width: 20px;
          min-height: 20px;
          min-width: 20px;
          background-color: transparent;
          border-radius: 50%;
          border: 1px solid #61fb69;
          font-size: 16px;
          font-weight: bold;

          &__selected {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            height: 20px;
            margin-left: -2.5px;
            margin-right: 10px !important;
            width: 20px;
            min-height: 20px;
            min-width: 20px;
            border-radius: 50%;
            border: 1px solid #61fb69;
            font-size: 16px;
            font-weight: bold;
            background-color: #61fb69;

            & > p {
              color: #282c34;
            }
          }

          &--small {
            display: block;
            font-size: 15px;
            height: 15px;
            width: 15px;
          }
        }
      }
    }

    .checkmark-label-radio-button {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      height: 0.398em;
      width: 0.398em;
      background-color: transparent;
      border-radius: 50%;
      border: 1px solid var(--border-color);
      font-size: 16px;

      & > p {
        color: var(--color-light-alt);
      }

      &__selected {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 15px;
        width: 15px;
        border-radius: 50%;
        background: var(--color-green);
        font-size: 16px;

        & > p {
          color: var(--color-blue);
          font-weight: bold;
        }
      }

      &--small {
        display: block;
        font-size: 15px;
        height: 15px;
        width: 15px;
      }
    }

    .color-scheme-label-radio-button {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      height: 10px;
      width: 10px;
      margin-right: 5.5px;
      margin-left: 10px;
      background-color: transparent;
      border-radius: 50%;
      border: 1px solid var(--border-color);
      font-size: 16px;

      & > p {
        color: var(--color-light-alt);
      }

      &__selected {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 10px;
        width: 10px;
        margin-left: 10px;
        margin-right: 5.5px;
        border-radius: 50%;
        background: var(--color-green);
        font-size: 16px;

        & > p {
          color: var(--color-blue);
          font-weight: bold;
        }
      }

      &--small {
        display: block;
        font-size: 15px;
        height: 15px;
        width: 15px;
      }
    }

    .question {
      .question-body {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        gap: 1rem;

        &__image-container {
          width: var(--img-width);
          flex: 0 1 auto;
        }

        &__num-questions {
          margin-right: 10px;
          text-align: left;
        }
      }

      .exam-header {
        border-bottom: 1px solid;
        width: 100%;
        margin: 0 !important;
        padding: 1.5px 5px;

        .exam-header-title {
          font-size: 1.3rem;
          margin: 0 5px;

          @media screen and (max-width: 1100px) {
            & {
              font-size: 1.3em;
            }
          }
        }

        @media screen and (max-width: 1100px) {
          & {
            margin-bottom: 10px !important;
            padding: 5px 0;
          }
        }
      }

      .answer-item {
        display: flex;
        flex-direction: row;
        align-items: center;
        border: 1px solid #61fb69;
        width: 100%;
        border-radius: 10px;
        padding: 1.25px 2.5px;

        .no-border {
          border: transparent;
          font-size: 0.95rem;
          height: 30px;
        }
      }

      .question-body__options {
        display: flex;
        flex-direction: column;

        @media screen and (max-width: 1100px) {
          & {
            height: auto !important;
            padding-bottom: 1em;
          }
        }
      }

      .command {
        display: flex;
        align-items: center;
        justify-content: center;

        &__text {
          font-size: 1.3rem;
        }
      }

      .options-container {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 70%;
      }

      .statement {
        display: flex;
        align-items: center;
        min-width: 70%;
        margin: 6px 0px 10px 0;

        &__question-number {
          width: 50px;
          text-align: center;
          margin-right: 5px;
          margin-left: -2.5px;
          font-size: 1.2rem;
          border: 1px solid var(--border-color);
          flex: 0 0 22px;
        }

        &__text {
          width: 100%;
          background-color: transparent;
          font-size: 1.25rem;
          resize: none;
          border: 1px solid var(--border-color);
          text-align: center;

          &:focus {
            outline: none;
          }
        }
      }

      .audio-options {
        width: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        border-bottom: 1px solid var(--border-color);
        margin: 0;
        padding: 0.2em;
        background-color: #61fb693e;
        @media screen and (max-width: 1100px) {
          width: 100%;
          padding-bottom: 1em;
        }

        &__audio {
          width: 19px;
        }

        &__record {
          width: 15px;
        }

        &__exam {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          margin: 10px 1rem;

          &__audio {
            width: 19px;
          }

          &__record {
            width: 15px;
          }
        }
      }

      .question-audio {
        &__upload-audio {
          margin: 0;
          margin-right: 20px;
        }
      }

      .question-image {
        border: 1px solid var(--border-color);
        display: flex;
        align-items: center;
        justify-content: center;
        width: var(--image-width);
        height: var(--img-height);

        &__image {
          object-fit: contain;
          width: 100%;
          height: 100%;
        }
      }

      /* css overrides for non editable questions used when takin the exam */
      &[data-is-editable='false'] {
        .question-body__options {
          justify-self: flex-start;
        }

        .statement {
          padding-left: 2%;
        }

        .statement__question-number {
          background-color: var(--border-color);
          border-radius: 50%;
          width: 22px;
          height: 22px;
          color: white;
          margin: 0;
          padding: 0;
          font-weight: bold;
          display: flex;
          align-items: center;
          justify-content: center;
        }

        .statement__text {
          width: auto;
          margin: 0 0 0 5px;
        }

        .command__text,
        .statement__text,
        .statement__question-number,
        .question-image {
          border: none;
        }
      }
    }

    // light scheme
    &__light {
      .checkmark-radio-button {
        display: flex;

        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 20px;
        width: 20px;
        margin-right: 20px;
        min-height: 20px;
        min-width: 20px;
        font-weight: bold;
        background-color: transparent;
        border-radius: 50%;
        border: 1px solid var(--color-light-alt);
        font-size: 16px;

        & > p {
          color: var(--color-light-alt);
        }

        &__selected {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          font-weight: bold;
          height: 20px;
          margin-left: 15px;
          width: 20px;
          min-height: 20px;
          min-width: 20px;
          margin-right: 20px;
          border-radius: 50%;
          background-color: var(--color-blue);
          font-size: 16px;

          & > p {
            color: #fff;
            font-weight: bold;
          }
        }

        &--small {
          display: block;
          font-size: 15px;
          height: 15px;
          width: 15px;
        }

        @media screen and (max-width: 1100px) {
          display: flex;
          margin-left: -2.5px;
          margin-right: 10px !important;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          height: 20px;
          width: 20px;
          min-height: 20px;
          min-width: 20px;
          font-weight: bold;
          background-color: transparent;
          border-radius: 50%;
          border: 1px solid var(--color-light-alt);
          font-size: 16px;

          & > p {
            color: var(--color-light-alt);
          }

          &__selected {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            font-weight: bold;
            height: 20px;
            margin-left: -2.5px;
            margin-right: 10px !important;
            width: 20px;
            min-height: 20px;
            min-width: 20px;
            border-radius: 50%;
            background-color: var(--color-blue);
            font-size: 16px;

            & > p {
              color: #fff;
              font-weight: bold;
            }
          }

          &--small {
            display: block;
            font-size: 15px;
            height: 15px;
            width: 15px;
          }
        }
      }

      .checkmark-label-radio-button {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 15px;
        width: 15px;
        background-color: transparent;
        border-radius: 50%;
        border: 1px solid var(--border-color-light);
        font-size: 16px;

        & > p {
          color: var(--color-light-alt);
        }

        &__selected {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          height: 15px;
          width: 15px;
          border-radius: 50%;
          background: var(--color-blue);
          font-size: 16px;

          & > p {
            color: var(--color-blue);
            font-weight: bold;
          }
        }

        &--small {
          display: block;
          font-size: 15px;
          height: 15px;
          width: 15px;
        }
      }

      .color-scheme-label-radio-button {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 10px;
        width: 10px;
        margin-right: 5.5px;
        margin-left: 10px;
        background-color: transparent;
        border-radius: 50%;
        border: 1px solid var(--color-light-alt);
        font-size: 16px;

        & > p {
          color: var(--color-light-alt);
        }

        &__selected {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          height: 10px;
          width: 10px;
          margin-left: 10px;
          margin-right: 5.5px;
          border-radius: 50%;
          background: var(--color-blue);
          font-size: 16px;

          & > p {
            font-weight: bold;
          }
        }

        &--small {
          display: block;
          font-size: 15px;
          height: 15px;
          width: 15px;
        }
      }

      .question {
        .question-body {
          display: flex;
          flex-wrap: wrap;
          align-items: center;
          justify-content: center;
          gap: 1rem;

          &__image-container {
            width: var(--img-width);
            flex: 0 1 auto;
          }

          &__num-questions {
            margin-right: 10px;
            text-align: left;
          }
        }

        .exam-header {
          border-bottom: 1px solid var(--color-light-contrast);
          width: 100%;
          margin: 0 !important;
          padding: 1.5px 5px;

          .exam-header-title {
            font-size: 1.3rem;
            color: var(--color-light-contrast);
            margin: 0 5px;

            @media screen and (max-width: 1100px) {
              & {
                font-size: 1.3em;
              }
            }
          }

          @media screen and (max-width: 1100px) {
            & {
              margin-bottom: 10px !important;
              padding: 5px 0;
            }
          }
        }

        .answer-item {
          display: flex;
          flex-direction: row;
          align-items: center;
          border: 1px solid var(--color-light-contrast);
          width: 100%;
          border-radius: 10px;
          padding: 1.25px 2.5px;

          .no-border {
            border: transparent;
            font-size: 0.95rem;
            height: 30px;
          }
        }

        .question-body__options {
          display: flex;
          flex-direction: column;

          @media screen and (max-width: 1100px) {
            & {
              height: 40vh;
            }
          }
        }

        .command {
          display: flex;
          align-items: center;
          justify-content: center;

          &__text {
            font-size: 1.3rem;
          }
        }

        .options-container {
          display: flex;
          flex-direction: column;
          width: 100%;
          height: 70%;
        }

        .statement {
          display: flex;
          align-items: center;
          min-width: 70%;
          margin: 6px 0px 10px 0;

          &__question-number {
            width: 50px;
            text-align: center;
            margin-left: -2.5px;
            margin-right: 5px;
            font-size: 1.2rem;
            border: 1px solid var(--border-color-light);
            flex: 0 0 22px;
          }

          &__text {
            width: 100%;
            background-color: transparent;
            resize: none;
            font-size: 1.2rem;
            border: 1px solid var(--border-color-light);
            text-align: center;

            &:focus {
              outline: none;
            }
          }
        }

        .audio-options {
          display: flex;
          justify-content: flex-start;
          align-items: center;

          &__audio {
            width: 19px;
          }

          &__record {
            width: 15px;
          }
        }

        .question-audio {
          &__upload-audio {
            margin: 0;
            margin-right: 20px;
          }
        }

        .question-image {
          border: 1px solid var(--border-color-light);
          display: flex;
          align-items: center;
          justify-content: center;
          width: var(--image-width);
          height: var(--img-height);

          &__image {
            object-fit: contain;
            width: 100%;
            height: 100%;
          }
        }

        /* css overrides for non editable questions used when takin the exam */
        &[data-is-editable='false'] {
          .question-body__options {
            justify-self: flex-start;
          }

          .statement {
            padding-left: 2%;
          }

          .statement__question-number {
            background-color: var(--border-color);
            border-radius: 50%;
            width: 22px;
            height: 22px;
            color: white;
            margin: 0;
            padding: 0;
            font-weight: bold;
            display: flex;
            align-items: center;
            justify-content: center;
          }

          .statement__text {
            width: auto;
            margin: 0 0 0 5px;
          }

          .command__text,
          .statement__text,
          .statement__question-number,
          .question-image {
            border: none;
          }
        }
      }
    }
  }
}

.question-info {
  position: absolute;
  bottom: 0.313em;
  box-sizing: border-box;
  left: 0.625em;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  & > span {
    color: #000;
    margin-right: 1.3em;

    @media (max-width: 900px) {
      display: flex;
      flex-direction: row;
      justify-content: center;
    }

    @media (min-width: 1500px) {
      font-size: 1.1em;
    }
    //
    @media (min-width: 1600px) {
      font-size: 1.3em;
    }

    @media (min-width: 1700px) {
      font-size: 1.5em;
    }
  }
}

.checkmark-input {
  color: #000;
}

.responsive-exam-command {
  line-height: 1em;
  padding-left: 2em;
  width: 100%;
  display: flex !important;
  align-self: center;
  text-align: left;
  justify-content: left;
  font-size: 1.5em;
  font-weight: bold;
  color: #025fda;
  overflow: hidden;
  overflow-wrap: break-word;
  word-break: break-word;
  white-space: normal;

  @media (max-width: 900px) {
    margin-top: -1em;
    z-index: -5;
    line-height: 0.95em;
    font-weight: 400;
    padding-left: 1em;
    text-align: left !important;
    font-size: 1.2em;
  }

  @media (min-width: 1500px) {
    font-size: 1.5em;
  }

  @media (min-width: 1600px) {
    font-size: 1.6em;
  }
  @media (min-width: 1700px) {
    font-size: 1.8em;
  }

  @media screen and (max-height: 620px) {
    & {
      margin-bottom: 10px !important;
    }
  }

  &__display {
    display: none !important;

    @media screen and (max-width: 900px) {
      & {
        display: flex !important;
        width: fit-content;
        align-self: center;
        text-align: center;
        font-size: 1.5rem;
        font-weight: bold;
        margin-bottom: 0;
      }
    }
  }
}

.statement-input {
  margin-left: 0.188em;
  padding: 0px;

  @media screen and (max-width: 900px) {
    & {
      padding: 5px 0px;
    }
  }
}

.question-image-usetakingdimentions {
  height: fit-content !important;
  margin-top: 0px;
  border: none !important;

  @media screen and (max-width: 900px) {
    & {
      // margin-top: 15px;
      height: 13em !important;
    }
  }
}

.button_clear_image {
  margin-top: 25em;
  position: fixed;
  width: 25em;
  z-index: 5;
  padding-left: 5em;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;

  @media (min-width: 1500px) {
    margin-top: 25em;
    width: 32em;
  }

  @media (max-width: 1600px) {
    margin-top: 25em;
    width: 32em;
  }

  @media (min-width: 1700px) {
    margin-top: 26.2em;
    width: 32em;
  }
}
@media (max-width: 900px) {
  .button_clear_image {
    display: none;
  }
}

@media (min-width: 900px) {
  .button-clear {
    display: none;
  }
}

.options-container.padding-left {
  @media (min-width: 900px) {
    padding-left: 11em;
  }
}

.btn_clear {
  background: transparent;
  color: #61fb69;
  border: 1px solid #61fb69;
  cursor: pointer;
  outline: none;
  transition: all 0.3s ease;
}

.btn_clear:hover {
  background-color: rgba(97, 251, 105, 0.1);
}

.btn_clear:active {
  transform: scale(0.98);
}
.audio-options-exam {
  position: relative;
  z-index: 100;
  width: auto;
  max-width: 75%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  padding-left: 0.5em;
  z-index: 2000;

  @media (min-width: 900px) {
    margin-top: -4em;
    position: relative;
    z-index: 5;
    width: 70%;
    max-width: 100%;
    padding-left: 1em;
    align-items: center;
    justify-content: center;
  }

  @media (max-width: 900px) {
    margin-top: -4em;
    position: relative;
    z-index: 5;
    width: 80%;
    max-width: 100%;
    padding-left: 1em;
    align-items: center;
    justify-content: center;
  }

  @media (min-width: 1500px) {
    max-width: 75%;
  }
  @media (min-width: 1600px) {
    max-width: 78%;
  }
  @media (min-width: 1700px) {
    max-width: 79%;
  }

  &__audio {
    width: 0.983em;
  }

  &__record {
    width: 0.75em; /* Se redujo el ancho del elemento */
  }
}

.form-body .question-body:not(:has(.image-container:not(:empty))) {
  padding-top: 7em;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  height: auto;

  @media (max-width: 900px) {
    padding-top: 1em;
    justify-content: center;
  }

  @media (min-width: 1500px) {
    padding-top: 8em;
  }
  @media (min-width: 1600px) {
    padding-top: 9em;
  }
  @media (min-width: 1700px) {
    padding-top: 10em;
  }
}

.question {
  width: 100%;
  padding-left: 4em;

  @media (min-width: 1500px) {
    padding-left: 5em;
  }
  @media (min-width: 1600px) {
    padding-left: 5em;
  }
  @media (min-width: 1700px) {
    padding-left: 5em;
  }

  @media screen and (max-width: 900px) {
    padding-left: 0;
  }

  .form-body {
    height: auto;

    .question-body {
      height: auto;
      display: flex !important;
      justify-content: center;
      align-items: center;
      gap: 0;

      @media (max-width: 900px) {
      }

      &__width-defined {
        padding-left: 3em;
        width: 85%;
        justify-content: flex-end;
        align-items: flex-start;
        height: 100%;

        @media (max-width: 900px) {
          padding-left: 0;
          width: 92%;
        }

        @media (min-width: 1500px) {
          padding-left: 3em;
        }

        @media (min-width: 1600px) {
          padding-left: 3em;
        }

        @media (min-width: 1700px) {
          padding-left: 3em;
        }

        .statement-taking-exam {
          .statement__question-number {
            background-color: #481aa7;
            font-size: 1.2rem;

            @media (max-width: 900px) {
              background-color: #28a745;
            }

            @media (min-width: 1500px) {
              font-size: 1.3rem;
            }

            @media (min-width: 1600px) {
              font-size: 1.5rem;
            }
            @media (min-width: 1700px) {
              font-size: 1.8rem;
            }
          }
        }

        .options-container {
          width: 100%;
          flex-direction: column;
          display: flex;
          justify-content: flex-start;
          align-items: flex-start;
          gap: 0.2em;

          @media (min-width: 1500px) {
            gap: 0.7em;
          }

          @media (min-width: 1600px) {
            gap: 0.8em;
          }

          @media (min-width: 1700px) {
            gap: 0.8em;
          }

          @media screen and (max-width: 900px) {
            gap: 0.6em;
            width: 100%;
            height: auto !important;
            margin-bottom: 0;
          }

          .options {
            height: 4em;
            width: 70%;
            background-color: #e2fcfd;

            @media (max-width: 900px) {
              width: 100%;
              height: 5.1em;
              background-color: transparent;
            }

            @media (min-width: 1500px) {
              height: 5.1em;
            }
            @media (min-width: 1600px) {
              height: 5.7em;
            }

            @media (min-width: 1700px) {
              height: 6em;
            }

            .input-container {
              line-height: 1.5em;
              height: 100% !important;
              padding-left: 15px;
              padding-right: 0;
              border: 1px solid var(--border-color);

              &__text {
                color: #000 !important;
                font-size: 1.6rem;

                @media (max-width: 900px) {
                  font-weight: 400;
                }

                @media (min-width: 1500px) {
                  font-size: 2rem;
                }

                @media (min-width: 1600px) {
                  font-size: 2.2rem;
                }

                @media (min-width: 1700px) {
                  font-size: 2.2rem;
                }
              }
            }

            &__selected {
              box-sizing: border-box;
              background-color: #50baff;

              animation-name: selectAnimation;
              animation-duration: 0.2s;
              animation-timing-function: linear;
              animation-iteration-count: 1;

              -webkit-animation-name: selectAnimation;
              -webkit-animation-duration: 0.2s;
              -webkit-animation-timing-function: linear;
              -webkit-animation-iteration-count: 1;

              @-moz-keyframes selectAnimation {
                0% {
                  opacity: 1;
                }

                50% {
                  background: #75c8ffb9;
                }

                100% {
                  opacity: 1;
                }
              }

              @-webkit-keyframes selectAnimation {
                0% {
                  opacity: 1;
                }

                50% {
                  background: #75c8ffb9;
                }

                100% {
                  opacity: 1;
                }
              }

              @keyframes selectAnimation {
                0% {
                  opacity: 1;
                }

                50% {
                  background: #75c8ffb9;
                }

                100% {
                  opacity: 1;
                }
              }
            }

            @media screen and (max-width: 900px) {
              & {
                height: 6.5vh;
                padding: 0px 0;

                .input-container {
                  &__text {
                    line-height: 0.95em;
                    font-size: 1.6rem;
                  }
                }
              }
            }
          }

          .image-container:empty ~ * {
            justify-content: center;
          }

          @media screen and (max-width: 900px) {
            & {
              padding-left: 1.8em;
              margin-top: 1.5em;
            }
          }
        }
      }
    }

    @media screen and (max-width: 900px) {
      & {
        height: 100%;
      }
    }
  }

  @media screen and (max-width: 900px) {
    & {
      width: 100%;
      height: 100%;
      justify-content: center;
      align-items: center;
    }
  }

  //yellow- scheme

  &__yellow-scheme {
    width: 100%;
    padding-left: 4em;

    @media (min-width: 1500px) {
      padding-left: 5em;
    }
    @media (min-width: 1600px) {
      padding-left: 5em;
    }
    @media (min-width: 1700px) {
      padding-left: 5em;
    }

    @media screen and (max-width: 900px) {
      padding-left: 0;
    }

    .form-body {
      height: auto;

      .question-body {
        height: auto;
        display: flex !important;
        justify-content: center;
        align-items: center;
        gap: 0;

        @media (max-width: 900px) {
        }

        &__width-defined {
          padding-left: 3em;
          width: 85%;
          justify-content: flex-end;
          align-items: flex-start;
          height: 100%;

          @media (max-width: 900px) {
            padding-left: 0;
            width: 92%;
          }

          @media (min-width: 1500px) {
            padding-left: 3em;
          }

          @media (min-width: 1600px) {
            padding-left: 3em;
          }

          @media (min-width: 1700px) {
            padding-left: 3em;
          }

          .statement-taking-exam {
            .statement__question-number {
              background-color: #fef162; /* Color amarillo */
              font-size: 1.2rem;

              @media (max-width: 900px) {
                background-color: #fef162;
              }

              @media (min-width: 1500px) {
                font-size: 1.3rem;
              }

              @media (min-width: 1600px) {
                font-size: 1.5rem;
              }
              @media (min-width: 1700px) {
                font-size: 1.8rem;
              }
            }
          }

          .options-container {
            width: 100%;
            flex-direction: column;
            display: flex;
            justify-content: flex-start;
            align-items: flex-start;
            gap: 0.2em;

            @media (min-width: 1500px) {
              gap: 0.7em;
            }

            @media (min-width: 1600px) {
              gap: 0.8em;
            }

            @media (min-width: 1700px) {
              gap: 0.8em;
            }

            @media screen and (max-width: 900px) {
              gap: 0.6em;
              width: 100%;
              height: auto !important;
              margin-bottom: 0;
            }

            .options {
              height: 4em;
              width: 70%;
              background-color: #fffba0;

              @media (max-width: 900px) {
                width: 100%;
                height: 5.1em;
                background-color: transparent;
              }

              @media (min-width: 1500px) {
                height: 5.1em;
              }
              @media (min-width: 1600px) {
                height: 5.7em;
              }

              @media (min-width: 1700px) {
                height: 6em;
              }

              .input-container {
                line-height: 1.5em;
                height: 100% !important;
                padding-left: 15px;
                padding-right: 0;
                border: 1px solid var(--border-color);

                &__text {
                  color: #000 !important;
                  font-size: 1.6rem;

                  @media (max-width: 900px) {
                    font-weight: 400;
                  }

                  @media (min-width: 1500px) {
                    font-size: 2rem;
                  }

                  @media (min-width: 1600px) {
                    font-size: 2.2rem;
                  }

                  @media (min-width: 1700px) {
                    font-size: 2.2rem;
                  }
                }
              }

              &__selected {
                box-sizing: border-box;
                background-color: #f0d279dd;

                animation-name: selectAnimation;
                animation-duration: 0.2s;
                animation-timing-function: linear;
                animation-iteration-count: 1;

                -webkit-animation-name: selectAnimation;
                -webkit-animation-duration: 0.2s;
                -webkit-animation-timing-function: linear;
                -webkit-animation-iteration-count: 1;

                @-moz-keyframes selectAnimation {
                  0% {
                    opacity: 1;
                  }

                  50% {
                    background: #ffecb3b9;
                  }

                  100% {
                    opacity: 1;
                  }
                }

                @-webkit-keyframes selectAnimation {
                  0% {
                    opacity: 1;
                  }

                  50% {
                    background: #ffecb3b9;
                  }

                  100% {
                    opacity: 1;
                  }
                }

                @keyframes selectAnimation {
                  0% {
                    opacity: 1;
                  }

                  50% {
                    background: #ffecb3b9;
                  }

                  100% {
                    opacity: 1;
                  }
                }
              }

              @media screen and (max-width: 900px) {
                & {
                  height: 6.5vh;
                  padding: 0px 0;

                  .input-container {
                    &__text {
                      line-height: 0.95em;
                      font-size: 1.6rem;
                    }
                  }
                }
              }
            }

            .image-container:empty ~ * {
              justify-content: center;
            }

            @media screen and (max-width: 900px) {
              & {
                padding-left: 1.8em;
                margin-top: 1.5em;
              }
            }
          }
        }
      }

      @media screen and (max-width: 900px) {
        & {
          height: 100%;
        }
      }
    }

    @media screen and (max-width: 900px) {
      & {
        width: 100%;
        height: 100%;
        justify-content: center;
        align-items: center;
      }
    }
  }

  //green scheme

  &__green-scheme {
    width: 100%;
    padding-left: 4em;

    @media (min-width: 1500px) {
      padding-left: 5em;
    }
    @media (min-width: 1600px) {
      padding-left: 5em;
    }
    @media (min-width: 1700px) {
      padding-left: 5em;
    }

    @media screen and (max-width: 900px) {
      padding-left: 0;
    }

    .form-body {
      height: auto;

      .question-body {
        height: auto;
        display: flex !important;
        justify-content: center;
        align-items: center;
        gap: 0;

        @media (max-width: 900px) {
        }

        &__width-defined {
          padding-left: 3em;
          width: 85%;
          justify-content: flex-end;
          align-items: flex-start;
          height: 100%;

          @media (max-width: 900px) {
            padding-left: 0;
            width: 92%;
          }

          @media (min-width: 1500px) {
            padding-left: 3em;
          }

          @media (min-width: 1600px) {
            padding-left: 3em;
          }

          @media (min-width: 1700px) {
            padding-left: 3em;
          }

          .statement-taking-exam {
            .statement__question-number {
              background-color: #a6f369;
              font-size: 1.2rem;

              @media (max-width: 900px) {
                background-color: #a6f369;
              }

              @media (min-width: 1500px) {
                font-size: 1.3rem;
              }

              @media (min-width: 1600px) {
                font-size: 1.5rem;
              }
              @media (min-width: 1700px) {
                font-size: 1.8rem;
              }
            }
          }

          .options-container {
            width: 100%;
            flex-direction: column;
            display: flex;
            justify-content: flex-start;
            align-items: flex-start;
            gap: 0.2em;

            @media (min-width: 1500px) {
              gap: 0.7em;
            }

            @media (min-width: 1600px) {
              gap: 0.8em;
            }

            @media (min-width: 1700px) {
              gap: 0.8em;
            }

            @media screen and (max-width: 900px) {
              gap: 0.6em;
              width: 100%;
              height: auto !important;
              margin-bottom: 0;
            }

            .options {
              height: 4em;
              width: 70%;
              background-color: #dcf7cb;

              @media (max-width: 900px) {
                width: 100%;
                height: 5.1em;
                background-color: transparent;
              }

              @media (min-width: 1500px) {
                height: 5.1em;
              }
              @media (min-width: 1600px) {
                height: 5.7em;
              }

              @media (min-width: 1700px) {
                height: 6em;
              }

              .input-container {
                line-height: 1.5em;
                height: 100% !important;
                padding-left: 15px;
                padding-right: 0;
                border: 1px solid var(--border-color);

                &__text {
                  color: #000 !important;
                  font-size: 1.6rem;

                  @media (max-width: 900px) {
                    font-weight: 400;
                  }

                  @media (min-width: 1500px) {
                    font-size: 2rem;
                  }

                  @media (min-width: 1600px) {
                    font-size: 2.2rem;
                  }

                  @media (min-width: 1700px) {
                    font-size: 2.2rem;
                  }
                }
              }

              &__selected {
                box-sizing: border-box;
                background-color: #50baff;

                animation-name: selectAnimation;
                animation-duration: 0.2s;
                animation-timing-function: linear;
                animation-iteration-count: 1;

                -webkit-animation-name: selectAnimation;
                -webkit-animation-duration: 0.2s;
                -webkit-animation-timing-function: linear;
                -webkit-animation-iteration-count: 1;

                @-moz-keyframes selectAnimation {
                  0% {
                    opacity: 1;
                  }

                  50% {
                    background: #75c8ffb9;
                  }

                  100% {
                    opacity: 1;
                  }
                }

                @-webkit-keyframes selectAnimation {
                  0% {
                    opacity: 1;
                  }

                  50% {
                    background: #75c8ffb9;
                  }

                  100% {
                    opacity: 1;
                  }
                }

                @keyframes selectAnimation {
                  0% {
                    opacity: 1;
                  }

                  50% {
                    background: #75c8ffb9;
                  }

                  100% {
                    opacity: 1;
                  }
                }
              }

              @media screen and (max-width: 900px) {
                & {
                  height: 6.5vh;
                  padding: 0px 0;

                  .input-container {
                    &__text {
                      line-height: 0.95em;
                      font-size: 1.6rem;
                    }
                  }
                }
              }
            }

            .image-container:empty ~ * {
              justify-content: center;
            }

            @media screen and (max-width: 900px) {
              & {
                padding-left: 1.8em;
                margin-top: 1.5em;
              }
            }
          }
        }
      }

      @media screen and (max-width: 900px) {
        & {
          height: 100%;
        }
      }
    }

    @media screen and (max-width: 900px) {
      & {
        width: 100%;
        height: 100%;
        justify-content: center;
        align-items: center;
      }
    }
  }

  &__blue-scheme {
    width: 100%;
    padding-left: 4em;

    @media (min-width: 1500px) {
      padding-left: 5em;
    }
    @media (min-width: 1600px) {
      padding-left: 5em;
    }
    @media (min-width: 1700px) {
      padding-left: 5em;
    }

    @media screen and (max-width: 900px) {
      padding-left: 0;
    }

    .form-body {
      height: auto;

      .question-body {
        height: auto;
        display: flex !important;
        justify-content: center;
        align-items: center;
        gap: 0;

        @media (max-width: 900px) {
        }

        &__width-defined {
          padding-left: 3em;
          width: 85%;
          justify-content: flex-end;
          align-items: flex-start;
          height: 100%;

          @media (max-width: 900px) {
            padding-left: 0;
            width: 92%;
          }

          @media (min-width: 1500px) {
            padding-left: 3em;
          }

          @media (min-width: 1600px) {
            padding-left: 3em;
          }

          @media (min-width: 1700px) {
            padding-left: 3em;
          }

          .statement-taking-exam {
            .statement__question-number {
              background-color: #481aa7;
              font-size: 1.2rem;

              @media (max-width: 900px) {
                background-color: #28a745;
              }

              @media (min-width: 1500px) {
                font-size: 1.3rem;
              }

              @media (min-width: 1600px) {
                font-size: 1.5rem;
              }
              @media (min-width: 1700px) {
                font-size: 1.8rem;
              }
            }
          }

          .options-container {
            width: 100%;
            flex-direction: column;
            display: flex;
            justify-content: flex-start;
            align-items: flex-start;
            gap: 0.2em;

            @media (min-width: 1500px) {
              gap: 0.7em;
            }

            @media (min-width: 1600px) {
              gap: 0.8em;
            }

            @media (min-width: 1700px) {
              gap: 0.8em;
            }

            @media screen and (max-width: 900px) {
              gap: 0.6em;
              width: 100%;
              height: auto !important;
              margin-bottom: 0;
            }

            .options {
              height: 4em;
              width: 70%;
              background-color: #e2fcfd;

              @media (max-width: 900px) {
                width: 100%;
                height: 5.1em;
                background-color: transparent;
              }

              @media (min-width: 1500px) {
                height: 5.1em;
              }
              @media (min-width: 1600px) {
                height: 5.7em;
              }

              @media (min-width: 1700px) {
                height: 6em;
              }

              .input-container {
                line-height: 1.5em;
                height: 100% !important;
                padding-left: 15px;
                padding-right: 0;
                border: 1px solid var(--border-color);

                &__text {
                  color: #000 !important;
                  font-size: 1.6rem;

                  @media (max-width: 900px) {
                    font-weight: 400;
                  }

                  @media (min-width: 1500px) {
                    font-size: 2rem;
                  }

                  @media (min-width: 1600px) {
                    font-size: 2.2rem;
                  }

                  @media (min-width: 1700px) {
                    font-size: 2.2rem;
                  }
                }
              }

              &__selected {
                box-sizing: border-box;
                background-color: #50baff;

                animation-name: selectAnimation;
                animation-duration: 0.2s;
                animation-timing-function: linear;
                animation-iteration-count: 1;

                -webkit-animation-name: selectAnimation;
                -webkit-animation-duration: 0.2s;
                -webkit-animation-timing-function: linear;
                -webkit-animation-iteration-count: 1;

                @-moz-keyframes selectAnimation {
                  0% {
                    opacity: 1;
                  }

                  50% {
                    background: #75c8ffb9;
                  }

                  100% {
                    opacity: 1;
                  }
                }

                @-webkit-keyframes selectAnimation {
                  0% {
                    opacity: 1;
                  }

                  50% {
                    background: #75c8ffb9;
                  }

                  100% {
                    opacity: 1;
                  }
                }

                @keyframes selectAnimation {
                  0% {
                    opacity: 1;
                  }

                  50% {
                    background: #75c8ffb9;
                  }

                  100% {
                    opacity: 1;
                  }
                }
              }

              @media screen and (max-width: 900px) {
                & {
                  height: 6.5vh;
                  padding: 0px 0;

                  .input-container {
                    &__text {
                      line-height: 0.95em;
                      font-size: 1.6rem;
                    }
                  }
                }
              }
            }

            .image-container:empty ~ * {
              justify-content: center;
            }

            @media screen and (max-width: 900px) {
              & {
                padding-left: 1.8em;
                margin-top: 1.5em;
              }
            }
          }
        }
      }

      @media screen and (max-width: 900px) {
        & {
          height: 100%;
        }
      }
    }

    @media screen and (max-width: 900px) {
      & {
        width: 100%;
        height: 100%;
        justify-content: center;
        align-items: center;
      }
    }
  }
}

.question-view {
  width: 100%;
  height: 97vh;
  display: flex;
  box-sizing: border-box;
  flex-direction: column;
  opacity: 1;
  align-items: center;
  transition: all 2000ms ease;
  position: relative;
  justify-content: center;
  position: relative;

  @media (max-width: 900px) {
    height: 90vh;
  }

  &__animation {
    border: 1px solid blue;
    width: 100%;
    height: 98vh;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    position: relative;
    opacity: 0;
    transition: all 1000ms ease;

    @media screen and (max-width: 900px) {
      & {
        height: 90vh;
      }
    }

    .question-view-container {
      width: 100%;
      position: relative;
      margin: 0;
      padding: 0 1.875em;

      &__fullwidth {
        margin: 0;
        width: 100%;
      }

      @media screen and (max-width: 900px) {
        & {
          width: 100%;
          padding: 0;
          height: 90%;
        }
      }
    }

    .questions-buttons {
      width: 100%;
      justify-content: center;
      position: absolute;
      left: 0;
      bottom: 0;

      @media screen and (max-width: 900px) {
        & {
          width: 100%;
          justify-content: center;
          margin-left: -5px;
          padding-right: 20px !important;
        }
      }
    }
  }

  .question-view-container {
    padding-top: 10em;

    width: 100%;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;

    @media (min-width: 900px) {
      padding-top: 6em;
      padding-bottom: 0;
    }

    @media (min-width: 1500px) {
      padding-top: 10em;
      padding-bottom: 0;
    }

    @media (min-width: 1600px) {
      padding-top: 10em;
      padding-bottom: 0;
    }

    @media (min-width: 1700px) {
      padding-top: 7em;
      padding-bottom: 2em;
    }

    &__fullwidth {
      margin: 0;
      width: 80%;
    }

    @media screen and (max-width: 900px) {
      & {
        align-items: center;
        justify-content: center;
        width: 100%;
        height: auto;
      }
    }
  }

  .questions-buttons {
    @media screen and (max-width: 900px) {
      & {
        width: 100%;
        justify-content: center;
        margin-left: -5px;
        justify-content: center;
        align-items: center;
        padding-bottom: 6em;
      }
    }
  }
}

.question-body {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  gap: 1rem;

  &__image-container {
    width: 30em;
    height: 100%;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin: auto 0;
    position: absolute;
    overflow: hidden;

    @media (max-width: 900px) {
      top: 2em;
      width: 100%;
      align-self: center;
      height: 12em;
    }

    @media (min-width: 1500px) {
      width: 42em;
    }

    @media (min-width: 1600px) {
      width: 48em;
    }

    @media (min-width: 1700px) {
      width: 48em;
    }
  }

  .width-defined__no-image {
    margin: 0;
    width: 42.5%;

    @media screen and (max-width: 900px) {
      & {
        width: 100%;
      }
    }
  }

  &__width-defined {
    width: 90%;
    align-items: center;
    justify-content: center;

    @media screen and (max-width: 900px) {
      width: 90%;
      height: auto;
    }

    .statement-taking-exam {
      width: 105%;
      flex-direction: row;
      align-items: flex-start;
      margin-bottom: 0.625em;
      justify-content: flex-start;
      white-space: nowrap;
      text-overflow: ellipsis;

      @media (max-width: 900px) {
      }

      .statement__question-number {
        width: 2em !important;
        min-width: 2em !important;
        height: 2em !important;
        background-color: #282c34;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        color: #fff;
        margin-left: 0;
        margin-right: 0.625em;
        border-radius: 50%;
        font-size: 1.8em;

        @media (min-width: 1500px) {
          font-size: 2em;
        }
        @media (min-width: 1600px) {
          font-size: 2em;
        }
        @media (min-width: 1700px) {
          font-size: 2em;
        }

        & > p {
          margin-top: 1px !important;
          margin-bottom: 0 !important;
        }
      }

      .statement__text {
        margin: 0;
      }

      .statement__exam-text {
        display: flex;
        width: 100%;
        align-items: flex-start;
        background-color: transparent;
        font-size: 2rem;
        resize: none;
        flex-direction: row;
        margin-bottom: 0;
        font-weight: bold;
        text-align: left;
        color: #000;
        overflow: hidden;
        overflow-wrap: break-word;
        word-break: break-word;
        white-space: normal;
        @media (max-width: 900px) {
          line-height: 1;
          font-weight: 400;
          padding-left: 0.5em;
          font-size: 1.5rem;
          display: flex;
          margin-top: -11.5em;
        }

        @media (min-width: 1500px) {
          font-size: 2em;
          width: 22em;
        }

        @media (min-width: 1600px) {
          font-size: 2.2em;
        }

        @media (min-width: 1700px) {
          font-size: 2.2em;
          width: 30em;
        }

        &:focus {
          outline: none;
        }
      }
    }
  }

  &__num-questions {
    margin-right: 0.625em;
    text-align: left;
  }

  .question-image {
    border: 1px solid var(--border-color);
    display: flex;
    align-items: center;
    justify-content: center;
    width: 42em;
    height: 35em;

    @media (min-width: 900px) {
      width: 28em;
    }
  }

  .question-image__image {
    object-fit: contain;
    width: 50em;
    height: 38em;
  }

  @media screen and (min-width: 700px) {
    .question-image__image {
      height: 30em;
    }
  }

  @media screen and (max-width: 900px) {
    & {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      margin-top: 6em;

      .question-image {
        border: 1px solid var(--border-color);
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: var(--img-height);

        &__image {
          object-fit: contain;
          width: 100%;
          height: 100%;
        }
      }
    }
  }
}

.exam-editor-container {
  .english-test-admin-dashboard {
    .checkmark-radio-button {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      height: 1.25em;
      width: 1.25em;
      margin-right: 1.25em;
      min-height: 1.25em;
      min-width: 1.25em;
      background-color: transparent;
      border-radius: 50%;
      border: 1px solid #61fb69;
      font-size: 16px;
      font-weight: bold;

      &__selected {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 20px;
        margin-right: 20px;
        width: 20px;
        min-height: 20px;
        min-width: 20px;
        border-radius: 50%;
        border: 1px solid #61fb69;
        font-size: 16px;
        font-weight: bold;
        background-color: #61fb69;

        & > p {
          color: #282c34;
        }
      }

      &--small {
        display: block;
        font-size: 0.398em;
        height: 0.398em;
        width: 0.398em;
      }

      @media screen and (max-width: 900px) {
        & {
          display: flex;
          margin-left: -2.5px;
          flex-direction: column;
          align-items: center;
          margin-right: 10px !important;
          justify-content: center;
          height: 20px;
          width: 20px;
          min-height: 20px;
          min-width: 20px;
          background-color: transparent;
          border-radius: 50%;
          border: 1px solid #61fb69;
          font-size: 16px;
          font-weight: bold;

          &__selected {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            height: 20px;
            margin-left: -2.5px;
            margin-right: 10px !important;
            width: 20px;
            min-height: 20px;
            min-width: 20px;
            border-radius: 50%;
            border: 1px solid #61fb69;
            font-size: 16px;
            font-weight: bold;
            background-color: #61fb69;

            & > p {
              color: #282c34;
            }
          }

          &--small {
            display: block;
            font-size: 15px;
            height: 15px;
            width: 15px;
          }
        }
      }
    }

    .checkmark-label-radio-button {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      height: 0.398em;
      width: 0.398em;
      background-color: transparent;
      border-radius: 50%;
      border: 1px solid var(--border-color);
      font-size: 16px;

      & > p {
        color: var(--color-light-alt);
      }

      &__selected {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 15px;
        width: 15px;
        border-radius: 50%;
        background: var(--color-green);
        font-size: 16px;

        & > p {
          color: var(--color-blue);
          font-weight: bold;
        }
      }

      &--small {
        display: block;
        font-size: 15px;
        height: 15px;
        width: 15px;
      }
    }

    .color-scheme-label-radio-button {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      height: 10px;
      width: 10px;
      margin-right: 5.5px;
      margin-left: 10px;
      background-color: transparent;
      border-radius: 50%;
      border: 1px solid var(--border-color);
      font-size: 16px;

      & > p {
        color: var(--color-light-alt);
      }

      &__selected {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 10px;
        width: 10px;
        margin-left: 10px;
        margin-right: 5.5px;
        border-radius: 50%;
        background: var(--color-green);
        font-size: 16px;

        & > p {
          color: var(--color-blue);
          font-weight: bold;
        }
      }

      &--small {
        display: block;
        font-size: 15px;
        height: 15px;
        width: 15px;
      }
    }

    .question {
      .question-body {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        gap: 1rem;

        &__image-container {
          width: var(--img-width);
          flex: 0 1 auto;
        }

        &__num-questions {
          margin-right: 10px;
          text-align: left;
        }
      }

      .exam-header {
        border-bottom: 1px solid;
        width: 100%;
        margin: 0 !important;
        padding: 1.5px 5px;

        .exam-header-title {
          font-size: 1.3rem;
          margin: 0 5px;

          @media screen and (max-width: 900px) {
            & {
              font-size: 1.3em;
            }
          }
        }

        @media screen and (max-width: 900px) {
          & {
            margin-bottom: 10px !important;
            padding: 5px 0;
          }
        }
      }

      .answer-item {
        display: flex;
        flex-direction: row;
        align-items: center;
        border: 1px solid #61fb69;
        width: 100%;
        border-radius: 10px;
        padding: 1.25px 2.5px;

        .no-border {
          border: transparent;
          font-size: 0.95rem;
          height: 30px;
        }
      }

      .question-body__options {
        display: flex;
        flex-direction: column;

        @media screen and (max-width: 900px) {
          & {
            height: auto !important;
            padding-bottom: 1em;
          }
        }
      }

      .command {
        display: flex;
        align-items: center;
        justify-content: center;

        &__text {
          font-size: 1.3rem;
        }
      }

      .options-container {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 70%;
      }

      .statement {
        display: flex;
        align-items: center;
        min-width: 70%;
        margin: 6px 0px 10px 0;

        &__question-number {
          width: 50px;
          text-align: center;
          margin-right: 5px;
          margin-left: -2.5px;
          font-size: 1.2rem;
          border: 1px solid var(--border-color);
          flex: 0 0 22px;
        }

        &__text {
          width: 100%;
          background-color: transparent;
          font-size: 1.25rem;
          resize: none;
          border: 1px solid var(--border-color);
          text-align: center;

          &:focus {
            outline: none;
          }
        }
      }

      .audio-options {
        width: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        border-bottom: 1px solid var(--border-color);
        margin: 0;
        padding: 0.2em;
        background-color: #61fb693e;
        @media screen and (max-width: 900px) {
          width: 100%;
          padding-bottom: 1em;
        }

        &__audio {
          width: 19px;
        }

        &__record {
          width: 15px;
        }

        &__exam {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          margin: 10px 1rem;

          &__audio {
            width: 19px;
          }

          &__record {
            width: 15px;
          }
        }
      }

      .question-audio {
        &__upload-audio {
          margin: 0;
          margin-right: 20px;
        }
      }

      .question-image {
        border: 1px solid var(--border-color);
        display: flex;
        align-items: center;
        justify-content: center;
        width: var(--image-width);
        height: var(--img-height);

        &__image {
          object-fit: contain;
          width: 100%;
          height: 100%;
        }
      }

      /* css overrides for non editable questions used when takin the exam */
      &[data-is-editable='false'] {
        .question-body__options {
          justify-self: flex-start;
        }

        .statement {
          padding-left: 2%;
        }

        .statement__question-number {
          background-color: var(--border-color);
          border-radius: 50%;
          width: 22px;
          height: 22px;
          color: white;
          margin: 0;
          padding: 0;
          font-weight: bold;
          display: flex;
          align-items: center;
          justify-content: center;
        }

        .statement__text {
          width: auto;
          margin: 0 0 0 5px;
        }

        .command__text,
        .statement__text,
        .statement__question-number,
        .question-image {
          border: none;
        }
      }
    }

    // light scheme
    &__light {
      .checkmark-radio-button {
        display: flex;

        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 20px;
        width: 20px;
        margin-right: 20px;
        min-height: 20px;
        min-width: 20px;
        font-weight: bold;
        background-color: transparent;
        border-radius: 50%;
        border: 1px solid var(--color-light-alt);
        font-size: 16px;

        & > p {
          color: var(--color-light-alt);
        }

        &__selected {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          font-weight: bold;
          height: 20px;
          margin-left: 15px;
          width: 20px;
          min-height: 20px;
          min-width: 20px;
          margin-right: 20px;
          border-radius: 50%;
          background-color: var(--color-blue);
          font-size: 16px;

          & > p {
            color: #fff;
            font-weight: bold;
          }
        }

        &--small {
          display: block;
          font-size: 15px;
          height: 15px;
          width: 15px;
        }

        @media screen and (max-width: 900px) {
          display: flex;
          margin-left: -2.5px;
          margin-right: 10px !important;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          height: 20px;
          width: 20px;
          min-height: 20px;
          min-width: 20px;
          font-weight: bold;
          background-color: transparent;
          border-radius: 50%;
          border: 1px solid var(--color-light-alt);
          font-size: 16px;

          & > p {
            color: var(--color-light-alt);
          }

          &__selected {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            font-weight: bold;
            height: 20px;
            margin-left: -2.5px;
            margin-right: 10px !important;
            width: 20px;
            min-height: 20px;
            min-width: 20px;
            border-radius: 50%;
            background-color: var(--color-blue);
            font-size: 16px;

            & > p {
              color: #fff;
              font-weight: bold;
            }
          }

          &--small {
            display: block;
            font-size: 15px;
            height: 15px;
            width: 15px;
          }
        }
      }

      .checkmark-label-radio-button {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 15px;
        width: 15px;
        background-color: transparent;
        border-radius: 50%;
        border: 1px solid var(--border-color-light);
        font-size: 16px;

        & > p {
          color: var(--color-light-alt);
        }

        &__selected {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          height: 15px;
          width: 15px;
          border-radius: 50%;
          background: var(--color-blue);
          font-size: 16px;

          & > p {
            color: var(--color-blue);
            font-weight: bold;
          }
        }

        &--small {
          display: block;
          font-size: 15px;
          height: 15px;
          width: 15px;
        }
      }

      .color-scheme-label-radio-button {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 10px;
        width: 10px;
        margin-right: 5.5px;
        margin-left: 10px;
        background-color: transparent;
        border-radius: 50%;
        border: 1px solid var(--color-light-alt);
        font-size: 16px;

        & > p {
          color: var(--color-light-alt);
        }

        &__selected {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          height: 10px;
          width: 10px;
          margin-left: 10px;
          margin-right: 5.5px;
          border-radius: 50%;
          background: var(--color-blue);
          font-size: 16px;

          & > p {
            font-weight: bold;
          }
        }

        &--small {
          display: block;
          font-size: 15px;
          height: 15px;
          width: 15px;
        }
      }

      .question {
        .question-body {
          display: flex;
          flex-wrap: wrap;
          align-items: center;
          justify-content: center;
          gap: 1rem;

          &__image-container {
            width: var(--img-width);
            flex: 0 1 auto;
          }

          &__num-questions {
            margin-right: 10px;
            text-align: left;
          }
        }

        .exam-header {
          border-bottom: 1px solid var(--color-light-contrast);
          width: 100%;
          margin: 0 !important;
          padding: 1.5px 5px;

          .exam-header-title {
            font-size: 1.3rem;
            color: var(--color-light-contrast);
            margin: 0 5px;

            @media screen and (max-width: 900px) {
              & {
                font-size: 1.3em;
              }
            }
          }

          @media screen and (max-width: 900px) {
            & {
              margin-bottom: 10px !important;
              padding: 5px 0;
            }
          }
        }

        .answer-item {
          display: flex;
          flex-direction: row;
          align-items: center;
          border: 1px solid var(--color-light-contrast);
          width: 100%;
          border-radius: 10px;
          padding: 1.25px 2.5px;

          .no-border {
            border: transparent;
            font-size: 0.95rem;
            height: 30px;
          }
        }

        .question-body__options {
          display: flex;
          flex-direction: column;

          @media screen and (max-width: 900px) {
            & {
              height: 40vh;
            }
          }
        }

        .command {
          display: flex;
          align-items: center;
          justify-content: center;

          &__text {
            font-size: 1.3rem;
          }
        }

        .options-container {
          display: flex;
          flex-direction: column;
          width: 100%;
          height: 70%;
        }

        .statement {
          display: flex;
          align-items: center;
          min-width: 70%;
          margin: 6px 0px 10px 0;

          &__question-number {
            width: 50px;
            text-align: center;
            margin-left: -2.5px;
            margin-right: 5px;
            font-size: 1.2rem;
            border: 1px solid var(--border-color-light);
            flex: 0 0 22px;
          }

          &__text {
            width: 100%;
            background-color: transparent;
            resize: none;
            font-size: 1.2rem;
            border: 1px solid var(--border-color-light);
            text-align: center;

            &:focus {
              outline: none;
            }
          }
        }

        .audio-options {
          display: flex;
          justify-content: flex-start;
          align-items: center;

          &__audio {
            width: 19px;
          }

          &__record {
            width: 15px;
          }
        }

        .question-audio {
          &__upload-audio {
            margin: 0;
            margin-right: 20px;
          }
        }

        .question-image {
          border: 1px solid var(--border-color-light);
          display: flex;
          align-items: center;
          justify-content: center;
          width: var(--image-width);
          height: var(--img-height);

          &__image {
            object-fit: contain;
            width: 100%;
            height: 100%;
          }
        }

        /* css overrides for non editable questions used when takin the exam */
        &[data-is-editable='false'] {
          .question-body__options {
            justify-self: flex-start;
          }

          .statement {
            padding-left: 2%;
          }

          .statement__question-number {
            background-color: var(--border-color);
            border-radius: 50%;
            width: 22px;
            height: 22px;
            color: white;
            margin: 0;
            padding: 0;
            font-weight: bold;
            display: flex;
            align-items: center;
            justify-content: center;
          }

          .statement__text {
            width: auto;
            margin: 0 0 0 5px;
          }

          .command__text,
          .statement__text,
          .statement__question-number,
          .question-image {
            border: none;
          }
        }
      }
    }
  }
}