.popper {
  background-color: rgba(244, 0, 252, 0.1);
  max-height: 40vh;
  overflow-y: auto;
  scrollbar-width: none;

}

.loading-container {
  display: flex;
  align-items: center;
  justify-content: center;

}

.popper::-webkit-scrollbar {
  display: none;
}

.popper-item {
  font-size: 1.1rem;
  color: #fff;
  width: 100%;
  padding: 10px 0;
  text-align: center;
  margin-bottom: 0;
}

.popper-item:hover {
  cursor: pointer;
  background-color: rgba(172, 0, 252, 0.762);
}

.popper-item:active {
  background-color: rgba(77, 6, 110, 0.762);
}