.whiteborard-admin-visits {
  display: flex;
  gap: 15px;



  @media (max-width: 800px) {
    position: fixed;
    top: 70px;
    left: 75px;
    gap: 5px;
  }

 
  & .button--purper { 
    padding: 2px 5px;
    cursor: pointer;
    background-color: rgba(156, 135, 248, 0.50); 
    color: rgb(255, 249, 255); 
    
    @media (max-width: 576px) {
      font-size: 10px;
    }
  }
  & .button--rose { 
    padding: 2px 5px;
    cursor: pointer;
    background-color: rgba(220, 135, 248, 0.50 ); 
    color: rgb(255, 249, 255); 
  
    @media (max-width: 576px) {
      font-size: 10px;
      margin-left: 10px;
    }
  }
  & .button--white { 
    padding: 2px 5px;
    cursor: pointer;
    background-color: #fff; 
    color:  rgba(156, 135, 248, 0.50) }

  & .button--outlined {
    padding: 2px 5px;
    text-align: center;
    cursor: pointer;

    &:active {
      transform: scale(0.95);  
    }
  }

  & .button--contained {
    padding: 2px 5px;
    text-align: center;
    cursor: pointer;
    &:active {
      transform: scale(0.95);  
    }

    @media (max-width: 576px) {
      height: auto;
    }
  }

  .admin-visits-actions {
    height: 20px;

    div:not(:first-child) {
      visibility: hidden;
    } 
    
    &.active div:not(:first-child) {
      visibility: visible;
    }

    & .contained-buttons {
      max-height: 200px;
      margin-top: 7px;
      overflow: auto;
      display: flex;
      flex-direction: column;
      gap: 5px;

      @media (max-width: 576px) {
        gap: 2px;
        font-size: 0.7rem;
      }

      
      
      & * {
        background: rgba(156, 135, 248, 0.50);
        color: rgb(255, 249, 255);
      }
    }
  }
}
