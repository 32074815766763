.itemCall {
  height: 40px !important;
  width: 40px !important;
  position: relative;
  padding-top: 3px;
  text-align: center;
}

.itemCall > div > img {
  height: 26px !important;
  vertical-align: middle;
}

/* Stoja */
.cbutton--effect-stoja::before {
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -35px 0 0 -35px;
  width: 70px;
  height: 70px;
  border-radius: 50%;
  content: '';
  opacity: 0;
  pointer-events: none;
}

.cbutton--effect-stoja::before,
.cbutton--effect-stoja::after {
  box-shadow: 0 0 0 2px rgba(111, 148, 182, 0.5);
}

.cbutton--effect-stoja-left::before,
.cbutton--effect-stoja-left::after {
  -webkit-clip-path: polygon(0% 0%, 50% 0%, 50% 50%, 0% 50%);
  clip-path: polygon(0% 0%, 50% 0%, 50% 50%, 0% 50%);
}

.cbutton--effect-stoja-top::before,
.cbutton--effect-stoja-top::after {
  -webkit-clip-path: polygon(10% 0%, 90% 0%, 50% 50%);
  clip-path: polygon(25% 0%, 75% 0%, 50% 50%);
}

.cbutton--effect-stoja-right::before,
.cbutton--effect-stoja-right::after {
  -webkit-clip-path: polygon(50% 0%, 100% 0%, 100% 50%, 50% 50%);
  clip-path: polygon(50% 0%, 100% 0%, 100% 50%, 50% 50%);
}

.cbutton--effect-stoja.cbutton--click::before {
  -webkit-animation: anim-effect-stoja-1 0.5s forwards;
  animation: anim-effect-stoja-1 0.5s forwards;
}

.cbutton--effect-stoja.cbutton--click::after {
  -webkit-animation: anim-effect-stoja-2 0.5s forwards;
  animation: anim-effect-stoja-2 0.5s forwards;
}

@-webkit-keyframes anim-effect-stoja-1 {
  0% {
    opacity: 1;
    -webkit-transform: scale3d(0.3, 0.3, 1);
    transform: scale3d(0.3, 0.3, 1);
  }
  100% {
    opacity: 0;
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
}

@keyframes anim-effect-stoja-1 {
  0% {
    opacity: 1;
    -webkit-transform: scale3d(0.3, 0.3, 1);
    transform: scale3d(0.3, 0.3, 1);
  }
  100% {
    opacity: 0;
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
}

@-webkit-keyframes anim-effect-stoja-2 {
  0% {
    opacity: 1;
    -webkit-transform: scale3d(0.5, 0.5, 1);
    transform: scale3d(0.5, 0.5, 1);
  }
  100% {
    opacity: 0;
    -webkit-transform: scale3d(1.5, 1.5, 1);
    transform: scale3d(1.5, 1.5, 1);
  }
}

@keyframes anim-effect-stoja-2 {
  0% {
    opacity: 1;
    -webkit-transform: scale3d(0.5, 0.5, 1);
    transform: scale3d(0.5, 0.5, 1);
  }
  100% {
    opacity: 0;
    -webkit-transform: scale3d(1.5, 1.5, 1);
    transform: scale3d(1.5, 1.5, 1);
  }
}

/* Stana */
.cbutton--effect-stana .cbutton__helper {
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -40px 0 0 -40px;
  width: 80px;
  height: 80px;
  opacity: 0;
  pointer-events: none;
}

.cbutton--effect-stana line {
  fill: none;
  stroke-width: 2;
}

.cbutton--box-color-7 line {
  stroke: #0077ff;
}

.cbutton--box-color-8 line {
  stroke: #7bff00;
}

.cbutton--box-color-9 line {
  stroke: #fff;
}

.clip-ring {
  -webkit-transform-origin: 50% 50%;
  transform-origin: 50% 50%;
}

.cbutton--effect-stana .cbutton__helper {
  -webkit-animation: anim-effect-stana 0.4s ease-in forwards;
  animation: anim-effect-stana 0.4s ease-in forwards;

  transition: transform 0.4s ease-in-out;
  -webkit-transform: scale3d(3, 3, 1);
  transform: scale3d(3, 3, 1);
}

@-webkit-keyframes anim-effect-stana {
  0%,
  100% {
    opacity: 1;
  }
  50% {
    opacity: 0.3;

    -webkit-animation-timing: ease-out;
    animation-timing: ease-out;
  }
}

@keyframes anim-effect-stana {
  0%,
  100% {
    opacity: 1;
  }
  50% {
    opacity: 0.3;

    -webkit-animation-timing: ease-out;
    animation-timing: ease-out;
  }
}

/* Ivana */
.cbutton--effect-ivana::before {
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -35px 0 0 -35px;
  width: 70px;
  height: 70px;
  border-radius: 50%;
  content: '';
  opacity: 0;
  pointer-events: none;
}

.cbutton--effect-ivana::before,
.cbutton--effect-ivana::after {
  box-shadow: 0 0 0 2px rgba(111, 148, 182, 0.5);
}

.cbutton--effect-ivana::before,
.cbutton--effect-ivana::after {
  -webkit-animation: anim-effect-ivana-2 0.5s forwards;
  animation: anim-effect-ivana-2 0.5s forwards;

  -webkit-animation-duration: 1.2s;
  animation-duration: 1.2s;
  -webkit-animation-iteration-count: 5;

  -webkit-animation-timing-function: ease-out;
  animation-timing-function: ease-out;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
}

@-webkit-keyframes anim-effect-ivana-1 {
  0% {
    opacity: 1;
    -webkit-transform: scale3d(0.5, 0.5, 1);
    transform: scale3d(0.5, 0.5, 1);
  }
  100% {
    opacity: 0;
    -webkit-transform: scale3d(1.1, 1.1, 1);
    transform: scale3d(1.1, 1.1, 1);
  }
}

@keyframes anim-effect-ivana-1 {
  0% {
    opacity: 1;
    -webkit-transform: scale3d(0.5, 0.5, 1);
    transform: scale3d(0.5, 0.5, 1);
  }
  100% {
    opacity: 0;
    -webkit-transform: scale3d(1.1, 1.1, 1);
    transform: scale3d(1.1, 1.1, 1);
  }
}

@-webkit-keyframes anim-effect-ivana-2 {
  0% {
    opacity: 1;
    -webkit-transform: scale3d(0.5, 0.5, 1);
    transform: scale3d(0.5, 0.5, 1);
  }
  50%,
  100% {
    opacity: 0;
    -webkit-transform: scale3d(1.2, 1.2, 1);
    transform: scale3d(1.2, 1.2, 1);
  }
}

@keyframes anim-effect-ivana-2 {
  0% {
    opacity: 1;
    -webkit-transform: scale3d(0.5, 0.5, 1);
    transform: scale3d(0.5, 0.5, 1);
  }
  50%,
  100% {
    opacity: 0;
    -webkit-transform: scale3d(1.2, 1.2, 1);
    transform: scale3d(1.2, 1.2, 1);
  }
}

/* Jagoda */
.cbutton--effect-jagoda::before {
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -25px 0 0 -25px;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  content: '';
  opacity: 0;
  pointer-events: none;
}

.cbutton--effect-jagoda::after {
  margin: -25px 0 0 -25px;
  width: 50px;
  height: 50px;
}

.cbutton--effect-jagoda::before,
.cbutton--effect-jagoda::after {
  box-shadow: 0 0 0 2px rgba(111, 148, 182, 0.5);
}

.cbutton--effect-jagoda::before,
.cbutton--effect-jagoda::after {
  -webkit-animation-name: anim-effect-jagoda-2, anim-effect-jagoda-1;
  animation-name: anim-effect-jagoda-2, anim-effect-jagoda-1;
  -webkit-animation-duration: 1.2s;
  animation-duration: 1.2s;
  -webkit-animation-iteration-count: 5;

  -webkit-animation-timing-function: ease-out;
  animation-timing-function: ease-out;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
}

.cbutton--effect-jagoda::after {
  -webkit-animation-delay: 0.6s;
  animation-delay: 0.6s;
}

@-webkit-keyframes anim-effect-jagoda-1 {
  0%,
  100% {
    opacity: 0;
  }
  40%,
  60% {
    opacity: 1;
  }
}

@keyframes anim-effect-jagoda-1 {
  0%,
  100% {
    opacity: 0;
  }
  40%,
  60% {
    opacity: 1;
  }
}

@-webkit-keyframes anim-effect-jagoda-2 {
  0% {
    -webkit-transform: scale3d(0.5, 0.5, 1);
    transform: scale3d(0.5, 0.5, 1);
  }
  100% {
    -webkit-transform: scale3d(1.4, 1.4, 1);
    transform: scale3d(1.4, 1.4, 1);
  }
}

@keyframes anim-effect-jagoda-2 {
  0% {
    -webkit-transform: scale3d(0.5, 0.5, 1);
    transform: scale3d(0.5, 0.5, 1);
  }
  100% {
    -webkit-transform: scale3d(1.4, 1.4, 1);
    transform: scale3d(1.4, 1.4, 1);
  }
}

@media (max-width: 480px) {
  .live-icon {
    height: 25px !important;
  }
  .live-p {
    margin-right: 3px;
    margin-top: 5px;
    color: white;
  }
}
