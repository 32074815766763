.notes {
  display: flex;
  position: relative;
  flex-direction: column;

  justify-content: space-between;
  color: rgb(12, 40, 119);
  font-family: 'Roboto';
  font-size: 14px;
  width: 100%;
  max-height: 441px;
  height: 100%;
  background-color: rgba(11, 166, 228, 0.15);
  border-radius: 15px;
  padding: 10px;
  text-align: justify;
  margin: 10px 0;
  z-index: 1050

}

.notesTrue {
  display: flex;
  position: relative;
  flex-direction: column;

  justify-content: space-between;
  color: rgb(12, 40, 119);
  font-family: 'Roboto';
  font-size: 14px;
  width: 100%;
  max-height: 441px;
  height: 100%;
  background-color: rgba(11, 166, 228, 0.15);
  border-radius: 15px;
  padding: 10px;
  text-align: justify;
  margin: 10px 0;
  z-index: 1050;

}

.notesNormal {
  overflow: auto;
}

.imgImage {
  height: 20px;
  width: 30px;
}

.imgMove {
  height: 20px;
  width: 30px;
}

.notesEdit {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  overflow: auto;
  border: 2px dashed #29299d;
  /* Establece un borde punteado de 2px de ancho y color negro */
  border-spacing: 12px;
  border-radius: 5px;
}

.notesEditTrue {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  overflow: auto;
  border: 2px dashed #29299d;
  border-spacing: 12px;
  border-radius: 5px;
}

.divTitleNoteEdit {
  display: flex;
  text-align: center;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  gap: 30%;
}

.edit {
  cursor: pointer;
}

.input {
  background-color: transparent;
  color: #29299d;
  width: 50%;
  border: 1px solid #29299d;
  border-left: none;
  border-top: none;
  border-right: none;
}

.divInputFor {
  display: flex;
  width: 100%;
  height: auto;
  align-items: flex-start;
  gap: 10px;
}

.edit:hover {
  transform: scale(1.3);
}

.createMode {
  position: absolute;
  padding: 0 6%;
  width: 100%;
  top: 15px;
  left: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.createModeTrue {
  position: absolute;
  padding: 0 6%;
  width: 100%;
  top: 25px;
  left: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.create {
  height: 15px;
  cursor: pointer;
  transition: transform 0.5s ease;
}

.createTrue {
  height: 15px;
  cursor: pointer;
  transition: transform 0.5s ease;
}

.closedTrue {
  height: 15px;
  cursor: pointer;
  transform: rotate(135deg);
  transition: transform 0.5s ease;
}

.closedTrueNote {
  height: 15px;
  cursor: pointer;
  transform: rotate(135deg);
  display: none;
}

.closed {
  height: 15px;
  cursor: pointer;
  transform: rotate(135deg);
  transition: transform 0.5s ease;
}

.noteIcon {
  height: 25px;
  display: none;
}

.noteIconTrue {
  height: 15px;
  display: none;
}

.createNote {
  display: none;
}

@media screen and (max-width: 900px) {
  .createMode {
    width: 90%;
    padding: 0;
    margin: 0;
    justify-content: end;
    top: 12px;
  }

  .createModeTrue {
    left: 5%;
    width: 90%;
    justify-content: space-between;
  }

  .notes {
    z-index: 100000;
    background-color: transparent;
  }

  .notesTrue {
    position: fixed;
    margin: 0;
    padding: 0;
    top: 5%;
    left: 5%;
    width: 90%;
    height: 90%;
    max-height: unset;
    min-height: 550px;
    z-index: 1050;
    backdrop-filter: blur(10px);
    border-radius: 0px;
  }

  .create {
    display: none;
  }

  .closed {
    display: none;
  }

  .createTrue {
    display: block;
    height: 15px;
  }

  .closedTrueNote {
    display: block;
  }

  .noteIcon {
    display: block;
    margin-right: 10%;
    cursor: pointer;
  }

  .noteIconTrue {
    display: block;
    margin: 0;
    cursor: pointer;
  }

  .notesEdit {
    width: 80%;
    border: none;
  }

  .notesEditTrue {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    overflow: auto;
    border: 2px dashed #29299d;
    /* Establece un borde punteado de 2px de ancho y color negro */
    border-spacing: 12px;
    border-radius: 5px;
  }

  .createNote {
    display: block;
    font-size: 16px;
    padding: 0;
    padding-bottom: 5%;
    margin: 0 0 0 50%;
    color: #270fc5;
  }
}

@media screen and (max-width: 576px) {
  .notesTrue {
    left: 0;
    width: 100%;
    z-index: 1050;

  }
}

@media screen and (max-width: 480px) {
  .notesTrue {
    left: 5%;
    width: 90%;
    z-index: 1050;

  }

  .noteIcon {
    margin-right: 0;
  }

  .createNote {
    margin: 0 7px 0 auto;
    font-size: 14px;
  }
}

@media screen and (max-width: 319px) {
  .notesTrue {
    left: 0;
    width: 100%;
    z-index: 1050;
  }
}