.menuWhiteboard {
  min-width: max-content;
  position: absolute;
  border: 1px solid rgb(229, 229, 229);
  background: white;
  
  & ul {
    padding: 3px;
    margin: 0;

    & li {
      padding: 5px 10px;
      user-select: none;
    }

    & li:hover {
      background: #e9e9e9;
    }
  }
}