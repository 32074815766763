/*@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500&display=swap');*/

* {
  margin: 0;
}

.container-messages-scroll {
  width: 750px;
  height: 100%;
  overflow: auto;
}

.messages {
  width: 510px;
  overflow: auto;
  display: grid;
  box-sizing: unset;
  align-content: end;
  -webkit-mask-image: linear-gradient(0deg,
      rgba(0, 0, 0, 1) 0%,
      rgba(0, 0, 0, 1) 60%,
      rgba(0, 0, 0, 0) 80%);
  mask-image: linear-gradient(0deg,
      rgba(0, 0, 0, 1) 0%,
      rgba(0, 0, 0, 1) 60%,
      rgba(0, 0, 0, 0) 80%);
  font-family: -apple-system, BlinkMacSystemFont, segoe ui, Roboto, Helvetica, Arial,
    sans-serif, apple color emoji, segoe ui emoji, segoe ui symbol;
  -webkit-font-smoothing: antialiased;
}

.newsvisitors {
  width: 100%;
  margin-right: 10px;
  display: flex;
  flex-direction: column;
  align-self: flex-end;
  overflow: hidden;
  padding-bottom: 20px;
  mask-image: linear-gradient(0deg,
      rgba(0, 0, 0, 1) 0%,
      rgba(0, 0, 0, 1) 50%,
      rgba(0, 0, 0, 0) 300%);
}

.chatall {
  grid-column: 2;
  padding-top: 2px !important;
  display: grid;
  grid-gap: 2px;
  grid-template-columns: 40px 40px;
  padding-left: 14px;
}

.chat-icon {
  grid-column: 2;
  width: 60px;
  margin-bottom: -10px;
  margin-left: 0px;
  cursor: pointer;
}

.chat-banner {
  display: 'flex';
  align-items: 'center';
  margin-left: 'auto';
}

.avatar-container {
  padding-top: 4px;
}

.avatar,
.avatarUser,
.avatarD {
  display: fixed;
  margin: auto;
  width: 30px;
  height: 30px;
  cursor: pointer;
  border-radius: 50%;
}

.avatar {
  background-color: #16008f;
}

.avatarUser {
  background-color: #f605ff;
}

.text-colorU {
  color: #f605ff;
  /*font-family: 'Roboto', sans-serif; */
  font-family: -apple-system, BlinkMacSystemFont, segoe ui, Roboto, Helvetica, Arial,
    sans-serif, apple color emoji, segoe ui emoji, segoe ui symbol;
  -webkit-font-smoothing: antialiased;
}

.text-colorT {
  color: #16008f;
  font-family: -apple-system, BlinkMacSystemFont, segoe ui, Roboto, Helvetica, Arial,
    sans-serif, apple color emoji, segoe ui emoji, segoe ui symbol;
  -webkit-font-smoothing: antialiased;
}

.avatarD {
  background-color: #ffffff;
}

.text-colorD {
  color: #ffffff;
  font-family: -apple-system, BlinkMacSystemFont, segoe ui, Roboto, Helvetica, Arial,
    sans-serif, apple color emoji, segoe ui emoji, segoe ui symbol;
  -webkit-font-smoothing: antialiased;
}

.messageDoc {
  margin-left: 2px;
  text-justify: auto;
  display: grid;
  grid-template-rows: 13px 1fr 13px;
  font-size: 13.2px;
  height: auto;
  align-self: center;
  font-family: -apple-system, BlinkMacSystemFont, segoe ui, Roboto, Helvetica, Arial,
    sans-serif, apple color emoji, segoe ui emoji, segoe ui symbol;
  -webkit-font-smoothing: antialiased;
}

.message {
  margin: 0px 10px 0px 0px;
  display: grid;
  grid-template-columns: 50px auto;
  font-family: -apple-system, BlinkMacSystemFont, segoe ui, Roboto, Helvetica, Arial,
    sans-serif, apple color emoji, segoe ui emoji, segoe ui symbol;
  -webkit-font-smoothing: antialiased;
  width: 330px;
  padding-right: 10px;
  scroll-snap-align: end;
  /* scroll-padding-top: 3em; */
  position: relative;
  /* scroll-snap-stop: always; */
  scroll-padding: 100px 0px 0px 100px;
  /*margin-bottom: 1.5px;*/
}

.scmessage {
  display: grid;
  grid-template-columns: 50px auto;
  width: 100px;
  margin-left: 10px;
}

.message>h5 {
  font-family: 'HelveticaNeue-Light', 'Helvetica Neue Light', 'Helvetica Neue', Helvetica,
    Arial, 'Lucida Grande', sans-serif;
  font-weight: 500;
}

.message>p {
  padding-bottom: 0px;
  font-family: -apple-system, BlinkMacSystemFont, segoe ui, Roboto, Helvetica, Arial,
    sans-serif, apple color emoji, segoe ui emoji, segoe ui symbol;
  -webkit-font-smoothing: antialiased;
}

.messageDoc h5 {
  font-size: 12px;
  font-family: -apple-system, BlinkMacSystemFont, segoe ui, Roboto, Helvetica, Arial,
    sans-serif, apple color emoji, segoe ui emoji, segoe ui symbol;
  -webkit-font-smoothing: antialiased;
}

.messageDoc p {
  margin-bottom: 0px;
  font-family: -apple-system, BlinkMacSystemFont, segoe ui, Roboto, Helvetica, Arial,
    sans-serif, apple color emoji, segoe ui emoji, segoe ui symbol;
  -webkit-font-smoothing: antialiased;
}

.timeD {
  font-size: 9px;
  margin-left: 5px;
}

.timeT {
  font-size: 9px;
  margin-left: 5px;
  color: #16008f;
  font-family: 'Open Sans', sans-serif;
}

.image {
  width: 32px;
  height: 32px;
  min-width: 25px;
  min-height: 25px;
  border-radius: 50%;
  margin: auto 10px;
  cursor: pointer;
}

.changeColorToBlue,
.changeColorTowhite,
.changeColorToViolet,
.changeColorToGrey {
  margin-bottom: 4px;
  font-family: -apple-system, BlinkMacSystemFont, segoe ui, Roboto, Helvetica, Arial,
    sans-serif, apple color emoji, segoe ui emoji, segoe ui symbol;
  -webkit-font-smoothing: antialiased;
}

.changeColorToBlue>h5,
.changeColorTowhite>h5,
.changeColorToViolet>h5,
.changeColorToGrey>h5 {
  font-size: 13.5px;
  /*font-family: 'Roboto', sans-serif; font-weight: 400;*/
  font-family: -apple-system, BlinkMacSystemFont, segoe ui, Roboto, Helvetica, Arial,
    sans-serif, apple color emoji, segoe ui emoji, segoe ui symbol;
  font-weight: bold;
  -webkit-font-smoothing: antialiased;
  margin-bottom: 1px;
}

.message>.changeColorToBlue>p,
.changeColorTowhite>p,
.changeColorToViolet>p,
.changeColorToGrey>p {
  font-size: 13.3px;
  font-family: -apple-system, BlinkMacSystemFont, segoe ui, Roboto, Helvetica, Arial,
    sans-serif, apple color emoji, segoe ui emoji, segoe ui symbol;
  -webkit-font-smoothing: antialiased;
  text-align: left !important;
  line-height: 1.3;
  -ms-word-break: break-all;
  word-break: break-all;
  word-break: break-word;
  /* Sólo WebKit -NO DOCUMENTADO */
  -ms-hyphens: auto;
  /* Guiones para separar en sílabas */
  -moz-hyphens: auto;
  /*  depende de lang en <html>      */
  -webkit-hyphens: auto;
  hyphens: auto;
  padding-bottom: 3px;
}

.changeColorToBlue>span,
.changeColorTowhite>span,
.changeColorToViolet>span,
.changeColorToGrey>span {
  font-size: 11px;
  font-family: -apple-system, BlinkMacSystemFont, segoe ui, Roboto, Helvetica, Arial,
    sans-serif, apple color emoji, segoe ui emoji, segoe ui symbol;
  -webkit-font-smoothing: antialiased;
}

.changeColorToGrey {
  color: grey;
}

.changeColorToBlue {
  color: #16008f;
}

.changeColorTowhite {
  color: white;
}

.changeColorToViolet {
  color: #e305eb;
  /*nuevo color*/
}

.visitorSelect,
.newMessage,
.newVisitor,
.unselected-conversation {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  margin-right: 0px;
}

.visitorSelect {
  /*background-color: #F605FF;*/
  background-color: rgba(227, 16, 240, 0.2);
}

.newVisitor {
  background-color: #53d34f;
}

@keyframes nuevomensaje {
  0% {
    background-color: rgba(0, 238, 255, 0.25);
  }

  25% {
    background-color: #99b898;
  }

  50% {
    background-color: rgba(0, 238, 255, 0.25);
  }

  75% {
    background-color: #99b898;
  }

  100% {
    background-color: rgba(0, 238, 255, 0.25);
  }
}

.newMessage {
  animation-name: nuevomensaje;
  animation-duration: 2s;
  animation-iteration-count: infinite;
}

.nameVisitor {
  font-size: 11px;
  margin-left: 10px;
  margin-right: 12px;
  font-family: 'Open Sans', sans-serif;
  font-weight: bold;
  color: black;
}

.VisitorsInit {
  margin-top: 5px;
  display: grid;
  grid-template-columns: 12px auto;
  grid-gap: 5px;
  align-items: center;
  cursor: pointer;
  z-index: 99;
}

.unselected-conversation {
  background-color: rgba(83, 39, 187, 0.329);
}

.noselect {
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
}

.typingMsg {
  grid-column: 2;
  /*justify-self: center;*/
  padding-left: 20px;
  margin-bottom: 0px;
  margin-top: -10px;
  box-sizing: border-box;
  z-index: 99;
  font-family: 'Open Sans', sans-serif;
}

.p_typingMsg {
  padding-left: 20px;
  margin-bottom: 0px;
  line-height: 3;
  font-size: 14px;
  color: white;
  font-family: 'Open Sans', sans-serif;
}

.p_typingMsg>img {
  width: 13px;
  height: auto;
  margin-left: 3px;
  vertical-align: middle;
}

.TypingDiv {
  display: grid;
  grid-template-columns: auto auto;
  grid-template-rows: auto;
  box-sizing: border-box;
}

.div-typing {
  margin-bottom: 0px;
  margin-top: -20px;
  height: 45px;
}

.notification {
  background-color: rgba(3, 0, 252, 0.2);
  height: auto;
  max-width: 60%;
  padding: 5px;
  grid-column: 2;
  margin-left: 20px;
  box-sizing: border-box;
  visibility: block;
  margin-top: 5px;
  transition: visibility 3s;
}

.notification>p {
  font-size: 12px;
  color: white;
  margin-bottom: 0px;
  padding-left: 5px;
  font-family: 'Open Sans', sans-serif;
}

.notification p>a {
  margin: 0px;
  padding: 0px;
  display: inline-block;
}

.notification p>a>img {
  width: 18px;
  height: auto;
  vertical-align: middle;
}

.icon-3px {
  padding-top: 5px;
}

.menssages {
  background-color: blue;
  width: 300px;
  height: 600px;
}

.iconLogin {
  margin: 3px;
  color: white;
  cursor: pointer;
  padding-bottom: 3px;
  text-align: center;
}

.red {
  width: 30px;
  height: 30px;
  background-color: red;
  position: absolute;
  top: 10px;
}

.chagendata {
  background-color: rgba(0, 102, 255, 0.2);
  height: 45px;
  width: 100%;
  margin-left: 0px;
  display: grid;
  align-items: center;
  grid-template-rows: repeat(3, 1fr);
}

.item {
  margin: auto;
}

.unploadimage {
  margin: auto;
}

.gbandtext {
  margin: auto;
  vertical-align: middle;
}

.gbandtext:active {
  height: 43px;
}

.divclose {
  cursor: pointer;
  color: #fff;
  font-family: 'Nunito', sans-serif;
  position: absolute;
  top: -3px;
  right: 2px;
  font-size: 10px;
  font-weight: bold;
}

.MessageComp {
  width: 100px;
}

.bellNot {
  display: grid;
  width: 100%;
}

.div-typing>img {
  width: 45px;
  margin-left: 140px;
}

.changeuserdata {
  align-items: center;
  width: auto;
  height: 45px;
  display: flex;
  justify-content: space-between;
  padding: 3px;
  position: relative;
}

.camera-input {
  position: absolute;
  top: 0;
  display: flex;
  width: 100%;
  background-color: #0000007a;
  height: 100%;
  padding: 10px;
  box-sizing: border-box;
  z-index: 2;
  border-radius: 20px;
  flex-flow: column;
}

.ci-webcam-container {
  width: 100%;
  height: fit-content;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
}

.CameraSaveButton {
  background: transparent;
  border-radius: 5px;
  border: 1.5px solid #fff;
  margin-top: 2;
  width: fit-content;
  color: #fff;
  margin-top: 20px;
}

label img {
  width: 25px;
  cursor: pointer;
}

[type='button']:not(:disabled),
[type='reset']:not(:disabled),
[type='submit']:not(:disabled),
button:not(:disabled) {
  cursor: pointer;
  right: -170px;
}

.bg {
  background-color: royalblue;
  width: 35px;
  height: 35px;
  border-radius: 30px;
  margin: auto;
  cursor: pointer;
}

.bg:hover {
  background-color: royalblue;
  border-radius: 30px;
  transition: width 1s;
  margin: auto;
  cursor: pointer;
}

.gbandtext img {
  height: auto;
  width: auto;
  cursor: pointer;
  /*background-image: url('./chat-assets/chat-icons/cammera-icon-smaller.svg');*/
}

.gbandtext img:hover {
  transition: width 1s;
  cursor: pointer;
}

.usericon {
  display: grid;
  align-items: center;
  justify-content: center;
}

.usericon img {
  height: 25px;
  width: auto;
  cursor: pointer;
}

.usericon img:hover {
  transition: width 1s;
  cursor: pointer;
}

.loading-img {
  z-index: 5;
}

.loading {
  width: 100%;
  margin: 0px auto;
  transition: height 3s;
  height: 1px;
}

.container-messages {
  height: 90%;
  position: relative;
  width: 100%;
  overflow-y: scroll;
  overflow-x: hidden !important;
  padding-bottom: -20px;
}

.sctopmessage {
  position: relative;
  width: 40%;
  height: 200px;
}

/*admin chat*/

.whiteboard-admin-container {
  grid-gap: 5px;
  height: 80%;
  display: grid;
  grid-area: 1 / 4 / 4 / 4;
  grid-template-rows: 85% 1fr 1fr;
  grid-template-columns: auto 350px;
  z-index: 1;
  box-sizing: content-box;
  padding-right: 10px;
  position: fixed;
  right: 0;
  bottom: 0;
  margin-bottom: 90px;
  margin-right: 70px;
}

.whiteboard-admin-newsvisitors {
  width: 100%;
  margin-right: 10px;
  display: flex;
  flex-direction: column;
  align-self: flex-end;
  overflow: hidden;
  padding-bottom: 20px;
  mask-image: linear-gradient(0deg,
      rgba(0, 0, 0, 1) 0%,
      rgba(0, 0, 0, 1) 50%,
      rgba(0, 0, 0, 0) 300%);
}

.whiteboard-admin-VisitorsInit {
  margin-top: 5px;
  display: grid;
  grid-template-columns: 12px auto;
  grid-gap: 5px;
  align-items: center;
  cursor: pointer;
  z-index: 99;
}

.whiteboard-admin-messages {
  width: 510px;
  height: 100%;
  overflow: auto;
  display: grid;
  box-sizing: unset;
  align-content: end;
  -webkit-mask-image: linear-gradient(0deg,
      rgba(0, 0, 0, 1) 0%,
      rgba(0, 0, 0, 1) 60%,
      rgba(0, 0, 0, 0) 80%);
  mask-image: linear-gradient(0deg,
      rgba(0, 0, 0, 1) 0%,
      rgba(0, 0, 0, 1) 60%,
      rgba(0, 0, 0, 0) 80%);
  padding-left: 5px;
  padding-top: 18px;
  font-family: -apple-system, BlinkMacSystemFont, segoe ui, Roboto, Helvetica, Arial,
    sans-serif, apple color emoji, segoe ui emoji, segoe ui symbol;
  -webkit-font-smoothing: antialiased;
}

.whiteboard-admin-container-messages {
  height: 90%;
  position: relative;
  width: 100%;
  overflow-y: scroll;
  overflow-x: hidden !important;
  padding-bottom: -20px;
}

.whiteboard-admin-div-typing {
  grid-column: 2;
  padding-left: 20px;
  margin-bottom: 0px;
  margin-top: -20px;
  height: 45px;
}

.whiteboard-admin-chagendata {
  background-color: rgba(0, 102, 255, 0.2);
  height: 45px;
  width: 100%;
  margin-left: 0px;
  display: grid;
  align-items: center;
  grid-template-rows: repeat(3, 1fr);
}

.whiteboard-admin-chatall {
  grid-column: 2;
  padding-top: 2px !important;
  display: grid;
  grid-gap: 2px;
  grid-template-columns: 40px 40px;
  padding-left: 14px;
}

.whiteboard-admin-container>.whiteboard-admin-div-typing>.bell {
  width: 45px;
  height: auto;
  position: absolute;
  right: 35%;
}

.whiteboard-admin-textinput {
  background-color: transparent;
  height: 90%;
  color: white;
  font-size: 12px;
  border: none;
  padding-left: 5px;
  outline: none;
  margin-left: 5px;
  font-family: -apple-system, BlinkMacSystemFont, segoe ui, Roboto, Helvetica, Arial,
    sans-serif, apple color emoji, segoe ui emoji, segoe ui symbol;
  font-weight: bold;
  -webkit-font-smoothing: antialiased;
  font-weight: bold;
}

.whiteboard-admin-exterior {
  height: 28px;
  width: 324px;
  margin-left: 10px;
  border: 1px solid white;
  border-radius: 35px;
  display: grid;
  grid-template-columns: 75% 1fr;
  margin-bottom: 5px;
  margin-top: 13px;
}

.whiteboard-admin-textinput-clasico {
  background-color: transparent;
  height: 90%;
  color: grey;
  font-size: 12px;
  border: none;
  padding-left: 5px;
  outline: none;
  margin-left: 5px;
  font-family: -apple-system, BlinkMacSystemFont, segoe ui, Roboto, Helvetica, Arial,
    sans-serif, apple color emoji, segoe ui emoji, segoe ui symbol;
  font-weight: bold;
  -webkit-font-smoothing: antialiased;
  font-weight: bold;
}

.whiteboard-admin-textinput-clasico::placeholder {
  color: grey;
}

.whiteboard-admin-exterior-clasico {
  height: 28px;
  width: 324px;
  margin-left: 10px;
  border: 1px solid grey;
  border-radius: 35px;
  display: grid;
  grid-template-columns: 75% 1fr;
  margin-bottom: 5px;
  margin-top: 13px;
}

.whiteboard-admin-send-icon-message {
  margin-left: 12px !important;
  margin-top: auto;
  margin-right: 0px;
  animation: translate 0.6s ease-in;
  /*ease-in*/
}

.whiteboard-admin-send-icon {
  margin-left: 22px !important;
  margin-top: auto;
  margin-right: 0px;
  cursor: pointer;
}

.whiteboard-send-icon {
  margin-left: 22px !important;
  margin-top: auto;
  margin-right: 0px;
  cursor: pointer;
}

.whiteboard-send-icon-message {
  margin-left: 12px !important;
  margin-top: auto;
  margin-right: 0px;
  animation: translate 0.6s ease-in;
  /*ease-in*/
}

@keyframes translate {
  0% {
    transform: translate(0px, 0px);
  }

  100% {
    transform: translate(150px, -70px);
  }
}

/*admin chat*/

/* Para 960px */

@media only screen and (min-width: 980px) and (min-width: 821px) {
  .Icons-container {
    z-index: 99;
    max-width: 70%;
    position: absolute;
    justify-content: space-around;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
  }

  .Home-container {
    flex-direction: column;
  }
}

/* Para 800px */

@media only screen and (max-width: 820px) and (min-width: 621px) {
  .Login-Icons-container {
    z-index: 0 !important;
  }

  .Icons-container {
    z-index: 99;
    max-width: 20%;
    position: absolute;
    justify-content: space-around;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    align-items: center;
    margin-left: -200px;
  }
}

/* Para 480px */

@media screen and (min-width: 366px) and (max-width: 480px) {
  .whiteboard-chat-containerP>.newsvisitors {
    margin-right: 10px;
    margin-left: 15px;
    z-index: 99;
    /* width: min-content; */
  }

  .whiteboard-chat-containerP>.admin-messages {
    margin-left: 20px;
    width: 100%;
  }

  .whiteboard-chat-containerP>.div-typing {
    padding-left: 0px;
  }

  .whiteboard-chat-containerP>.div-typing>img {
    margin-top: -20px;
    margin-bottom: 0px;
    margin-left: -4px;
  }

  .whiteboard-chat-containerP>.div-typing>.chagendata {
    background-color: rgba(0, 238, 255, 0.25);
    width: 65%;
    display: grid;
    align-items: center;
    grid-template-rows: repeat(3, 1fr);
    margin-left: -2px;
  }

  .whiteboard-chat-containerP>.admin-container-messages {
    height: 100%;
    position: relative;
    width: 100%;
    overflow-y: scroll;
    padding-bottom: 20px;
    margin-left: 20px;
  }

  .whiteboard-chat-containerP>.admin-chatall {
    width: 100%;
    bottom: -10px;
    right: 6%;
    position: absolute;
  }

  .whiteboard-send-icon {
    margin-left: 0% !important;
  }

  /*ADMIN CSS PARA MOVIL*/
  .whiteboard-admin-container {
    max-width: 76%;
    left: 20%;
    bottom: 15%;
    height: 60%;
    margin-top: 0px !important;
    margin-bottom: 0px !important;
  }

  .whiteboard-admin-newsvisitors {
    margin-right: 20px;
    margin-left: 6px;
    z-index: 99;
    margin-bottom: 18px;
  }

  .whiteboard-admin-messages {
    margin-left: -2px;
    width: 100%;
    height: auto;
    margin-bottom: 10px;
  }

  .whiteboard-admin-container-messages {
    height: 100%;
    position: relative;
    width: 100%;
    overflow-y: scroll;
    margin-left: 20px;
  }

  .whiteboard-admin-div-typing {
    margin-left: -8px;
    padding-left: 0px;
  }

  .whiteboard-admin-div-typing>img {
    margin-top: -20px;
    margin-bottom: 0px;
  }

  .whiteboard-admin-chagendata {
    background-color: rgba(0, 238, 255, 0.25);
    width: 65%;
    display: grid;
    align-items: center;
    grid-template-rows: repeat(3, 1fr);
    margin-left: -10px;
    margin-top: -6px;
  }

  .whiteboard-admin-chatall {
    width: 290px;
    bottom: -10px;
    position: absolute;
  }

  .whiteboard-admin-exterior,
  .whiteboard-admin-exterior-clasico {
    margin-left: -30px !important;
    margin-right: 14px;
    width: 225px !important;
  }

  .whiteboard-admin-send-icon-message {
    margin-left: -108px;
  }

  .whiteboard-admin-send-icon {
    margin-left: 4px !important;
    width: 45px;
    padding-top: 0px;
  }
}

/* Para 320px */
@media (max-width: 365px) {

  /*ADMIN CSS PARA MOVIL*/
  .whiteboard-admin-container {
    max-width: 76%;
    left: 20%;
    bottom: 15%;
    height: 60%;
    margin-top: 0px !important;
    margin-bottom: 0px !important;
  }

  .whiteboard-admin-newsvisitors {
    margin-right: 20px;
    margin-left: 6px;
    z-index: 99;
    margin-bottom: 18px;
  }

  .whiteboard-admin-messages {
    margin-left: -2px;
    width: 100%;
    height: auto;
    margin-bottom: 10px;
  }

  .whiteboard-admin-container-messages {
    height: 100%;
    position: relative;
    width: 100%;
    overflow-y: scroll;
    margin-left: 20px;
  }

  .whiteboard-admin-div-typing {
    margin-left: -8px;
    padding-left: 0px;
  }

  .whiteboard-admin-div-typing>img {
    margin-top: -20px;
    margin-bottom: 0px;
  }

  .whiteboard-admin-chagendata {
    background-color: rgba(0, 238, 255, 0.25);
    width: 65%;
    display: grid;
    align-items: center;
    grid-template-rows: repeat(3, 1fr);
    margin-left: -28px;
    margin-top: -6px;
  }

  .whiteboard-admin-chatall {
    width: 290px;
    bottom: -10px;
    position: absolute;
  }

  .whiteboard-admin-exterior,
  .whiteboard-admin-exterior-clasico {
    margin-left: -45px !important;
    margin-right: 14px;
    width: 218px !important;
  }

  .whiteboard-admin-send-icon-message {
    margin-left: -108px;
  }

  .whiteboard-admin-send-icon {
    margin-left: 4px !important;
    width: 45px;
    padding-top: 0px;
  }
}