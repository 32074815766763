.headerButtonsAndPage {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;

  &__noEdit {
    justify-content: space-between;
    align-items: center;
    padding-right: 15px;
  }

  &__readOnly {
    // position: absolute;
    top: -1px;
    padding: 2px 0;
    width: 100%;
  }
}

.containerHeaderLeft {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.backIcon {
  margin-left: 15px;
  width: 30px;
  color: #110adc;
  cursor: pointer;

  &__readOnly {
    width: 20px;
  }
}

.homeIcon {
  height: 20px;
  margin-left: 10px;
  cursor: pointer;
  color: blue;

  @media screen and (max-width: 500px) {
    height: 17px;
  }
}

.texts {
  display: flex;
  align-items: center;
  gap: 30px;
  font-size: 18px;
  color: blue;
  margin-left: 25px;

  @media screen and (max-width: 500px) {
    margin-left: 5px;
  }
}

.containerHeaderCenter {
  color: blue;
  display: flex;
  align-items: center;
  font-size: 15px;
}

.userCurrentLevel {
  margin-bottom: 0;
}

.containerHeaderRight {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 10%;

  @media screen and (max-width: 500px) {
    width: 20%;
  }
}

.containerHeaderRightStudent {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-right: 15px;

  @media screen and (max-width: 500px) {
    width: 18%;
  }
}

.syncIcon {
  width: 25px;
  height: 22px;
  color: #110adc;
  cursor: pointer;
  margin-right: 10px;

  @media screen and (max-width: 500px) {
    height: 17px;
  }
}

.liveIcon {
  height: 22px;
  z-index: 10;
  cursor: pointer;

  @media screen and (max-width: 500px) {
    height: 17px;
  }
}

