.message {
  white-space: pre-line;
  word-wrap: break-word;
  padding-bottom: 15%;
}

.postedBy {
  text-align: left;
}

.iconContainer {
  display: flex;
  // position: relative;
  margin-top: -5%;
}

.iconOptions {
  margin: 0 12px;
}

.iconOptions:first-of-type {
  margin: 0 12px 0 0;
}

.pencil {
  height: 20px;
  width: auto;
  cursor: pointer;
}

.whiteboardNote {
  position: relative;
  overflow: hidden;

  .dragAndDrop {
    width: 20px;
    height: 20px;
    font-size: 18px;
    color: rgb(3, 41, 147);
    cursor: grab;
    user-select: none;
    position: relative;

    &:active {
      cursor: grabbing;
    }

    &:active+.noteDraggable {
      visibility: visible;
      top: 0;
      opacity: 1;

    }

    &:hover {
      z-index: 100;
      background: blue;
      visibility: hidden;
    }

    &:hover+.noteDraggable {
      cursor: grabbing;
      top: 0;
    }
  }

  .noteContainer {
    width: 100%;
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;

    &:hover {
      top: 0;
    }

    &:active {
      opacity: 1;
    }

    .noteDraggable {
      min-width: 150px;
      min-height: 150px;
      background: white;
      padding: 15px;
    }
  }
}