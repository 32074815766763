@font-face {
  font-family: 'Anton';
  src: url('./assets/fonts/Anton/Anton-Regular.ttf') format('truetype');
}
@font-face {
  font-family: 'Bangers';
  src: url('./assets/fonts/Bangers/Bangers-Regular.ttf') format('truetype');
}
@font-face {
  font-family: 'Bevan';
  src: url('./assets/fonts/Bevan/Bevan-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Calligraffitti';
  src: url('./assets/fonts/Calligraffitti/Calligraffitti-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Carter One';
  src: url('./assets/fonts/Carter_One/CarterOne-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Chakra Petch';
  src: url('./assets/fonts/Chakra_Petch/ChakraPetch-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Courgette';
  src: url('./assets/fonts/Courgette/Courgette-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Figtree';
  src: url('./assets/fonts/Figtree/Figtree-VariableFont_wght.ttf') format('truetype');
}

@font-face {
  font-family: 'Fredericka the Great';
  src: url('./assets/fonts/Fredericka_the_Great/FrederickatheGreat-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Grape Nuts';
  src: url('./assets/fonts/Grape_Nuts/GrapeNuts-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Indie Flower';
  src: url('./assets/fonts/Indie_Flower/IndieFlower-Regular.ttf') format('truetype');
}

@font-face { 
  font-family: 'Josefin Sans'; 
  src: url('./assets/fonts/Josefin_Sans/JosefinSans-VariableFont_wght.ttf') format('truetype');
}
@font-face { 
  font-family: 'Julius Sans One'; 
  src: url('./assets/fonts/Julius_Sans_One/JuliusSansOne-Regular.ttf') format('truetype');
}
@font-face { 
  font-family: 'Kalam'; 
  src: url('./assets/fonts/Kalam/Kalam-Regular.ttf') format('truetype');
}
@font-face { 
  font-family: 'Marck Script'; 
  src: url('./assets/fonts/Marck_Script/MarckScript-Regular.ttf') format('truetype');
}
@font-face { 
  font-family: 'Open Sans'; 
  src: url('./assets/fonts/Open_Sans/OpenSans-Regular.ttf') format('truetype');
}
@font-face { 
  font-family: 'Parisienne'; 
  src: url('./assets/fonts/Parisienne/Parisienne-Regular.ttf') format('truetype');
}
@font-face { 
  font-family: 'Permanent Marker'; 
  src: url('./assets/fonts/Permanent_Marker/PermanentMarker-Regular.ttf') format('truetype');
}
@font-face { 
  font-family: 'Play'; 
  src: url('./assets/fonts/Play/Play-Regular.ttf') format('truetype');
}
@font-face { 
  font-family: 'Racing Sans One'; 
  src: url('./assets/fonts/Racing_Sans_One/RacingSansOne-Regular.ttf') format('truetype');
}
@font-face { 
  font-family: 'Raleway'; 
  src: url('./assets/fonts/Anton/Anton-Regular.ttf') format('truetype');
}
@font-face { 
  font-family: 'Roboto'; 
  src: url('./assets/fonts/Roboto/Roboto-Regular.ttf') format('truetype');
}
@font-face { 
  font-family: 'Rubik Bubbles'; 
  src: url('./assets/fonts/Rubik_Bubbles/RubikBubbles-Regular.ttf') format('truetype');
}
@font-face { 
  font-family: 'Secular One'; 
  src: url('./assets/fonts/Secular_One/SecularOne-Regular.ttf') format('truetype');
}
@font-face { 
  font-family: 'Tillana'; 
  src: url('./assets/fonts/Tillana/Tillana-Regular.ttf') format('truetype');
}
@font-face {
  font-family: 'Digital-7';
  src: url('./assets/fonts/Digital_7/digital-7.ttf') format('truetype')
}
@font-face {
  font-family: 'BadScript';
  src: url('./assets/fonts/BadScript/BadScript-Regular.ttf') format('truetype')
}
@font-face {
  font-family: 'Futurette';
  src: url('./assets/fonts/Futurette/Futurette-ExtraLight.ttf') format('truetype')
}
@font-face {
  font-family: 'larabiefont';
  src: url('./assets/fonts/Larabie/larabiefont.ttf') format('truetype')
}
@font-face {
  font-family: 'mvboli';
  src: url('./assets/fonts/Mvboli/mvboli.ttf') format('truetype')
}
@font-face {
  font-family: 'zektonit';
  src: url('./assets/fonts/Zektonit/zektonit.ttf') format('truetype')
}
@font-face {
  font-family: 'roundHand';
  src: url('./assets/fonts/RoundHand/RoundHand.ttf') format('truetype')
}


.digitalBookContainer {
  width: 100%;
  height: 100%;
  padding: 5px;
  display: grid;
  grid-template-rows: auto 1fr;
}

.digitalBookContainerEdit {
  width: 100%;
  height: 100%;
  padding: 10px;
  display: grid;
  grid-template-rows: auto 1fr;
}

.whiteboardContainer {
  display: grid;
  grid-template-columns: auto 1fr;
  width: 100%;
  height: 100%;
  min-width: 100%;
  min-height: 100%;
  gap: 5px;
  // padding: 5px 0 10px;
}
