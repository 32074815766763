.viewListFilesExam__container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1500;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.closeViewListFilesExam {
  transform: translateY(100%);
  opacity: 0;
  transition: 1s;
  align-items: flex-end;
}

.click-closeViewListFilesExam {
  font-size: 2.5em;
  font-weight: 600;
  color: #ffff;
  cursor: pointer;
}

.viewListFilesExam__map-container {
  position: relative;
  width: 50%;
  max-width: 600px;
  min-width: 330px;
  height: 50%;
  max-height: 400px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  overflow-x: auto;
  gap: 3em;
  background-color: rgba(0, 0, 255, 0.15);
  --backdrop-filter: blur(3px);
  border-radius: 1em;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.viewListFilesExam__exam-article {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.viewListFilesExam__exam-view-update {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #ffff;
}

.viewListFilesExam__exam-view-update img {
  width: 4.5em;
  height: 4.5em;
}

.viewListFilesExam__exam-data {
  width: 100%;
  max-width: 400px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 0.5em;
  color: #ffff;
}

.viewListFilesExam__exam-data span {
  width: 80%;
  height: 0.15em;
  background-color: #ffff;
}

.viewListFilesExam__exam-data div {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5em;
}

.viewListFilesExam__exam-data p {
  font-size: 1.4em;
  margin: 0;
  color: #ffff;
}

.viewListFilesExam__exam-data img {
  width: 2em;
  cursor: pointer;
}

.close-list {
  position: sticky;
  top: 0;
  display: flex;
  justify-content: flex-end;
  width: 100%;
  z-index: 2; 
}


.small-screen {
  width: 16px; /* Ajusta el tamaño según tus necesidades */
  height: 16px;
}

.modal-container {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2000; /* Asegúrate de que el z-index del modal sea superior */
}

.hidden {
  visibility: hidden;
  opacity: 0;
  pointer-events: none; /* Asegúrate de que los eventos no se propaguen al contenido oculto */
}