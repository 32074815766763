.StyleTextareaResize {
  width: 100%;
  height: 100%;
  padding: 0;
  line-height: 1.3;
  letter-spacing: 0px;
  display: block;
  border: none;
  background: transparent;
  resize: none;
  top: 0;
  left: 0;
  position: relative;
  z-index: 10000;
  overflow: hidden;
}

.StyleTextareaEditing {
  width: 100%;
  height: 100%;
  padding: 0;
  line-height: 1.3;
  letter-spacing: 0px;
  color: transparent;
  display: block;
  border: none;
  background: transparent;
  resize: none;
  top: 0;
  left: 0;
  position: relative;
  z-index: 10000;
  overflow: hidden;
}