/*@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500&display=swap');*/

* {
  margin: 0;
}

:root {
  --vh: 0px;
}

.container-messages-scroll {
  width: 750px;
  height: 100%;
  overflow: auto;
}

.landing-page-cursor-pointer-icon {
  bottom: 10px;
  position: fixed;
  width: 65px;
  right: 20px;
  z-index: 3;
}

.landing-page-teacher-cursorPointer {
  bottom: 10px;
  right: 20px;
  position: fixed;
  width: 65px;
  margin-left: 10px;
  z-index: 3;
}

.landing-page-teacher-cursorPointer:hover {
  cursor: pointer;
}

.landing-page-cursor-pointer-icon:hover {
  cursor: pointer;
  background: rgb(255, 255, 255);
  background: linear-gradient(90deg,
      rgba(255, 255, 255, 0.0760679271708683) 0%,
      rgba(255, 255, 255, 0.29735644257703087) 49%,
      rgba(255, 255, 255, 0.07886904761904767) 100%);
  border-radius: 20px;
}

.landing-pages-teacher-chat-messages {
  width: 510px;
  overflow: auto;
  display: grid;
  box-sizing: unset;
  align-content: flex-end;
  -webkit-mask-image: linear-gradient(0deg,
      rgba(0, 0, 0, 1) 0%,
      rgba(0, 0, 0, 1) 60%,
      rgba(0, 0, 0, 0) 100%);
  mask-image: linear-gradient(0deg,
      rgba(0, 0, 0, 1) 0%,
      rgba(0, 0, 0, 1) 60%,
      rgba(0, 0, 0, 0) 80%);
  font-family: -apple-system, BlinkMacSystemFont, segoe ui, Roboto, Helvetica, Arial,
    sans-serif, apple color emoji, segoe ui emoji, segoe ui symbol;
  -webkit-font-smoothing: antialiased;
}

.landing-page-messages {
  min-height: 200px;
  max-height: 230px;
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden !important;
  box-sizing: unset;
  transition: all 500ms ease;
  align-content: flex-end;
  -webkit-mask-image: linear-gradient(0deg,
      rgba(0, 0, 0, 1) 0%,
      rgba(0, 0, 0, 1) 70%,
      rgba(0, 0, 0, 0) 100%);
  mask-image: linear-gradient(0deg,
      rgba(0, 0, 0, 1) 0%,
      rgba(0, 0, 0, 1) 60%,
      rgba(0, 0, 0, 0) 80%);
  font-family: -apple-system, BlinkMacSystemFont, segoe ui, Roboto, Helvetica, Arial,
    sans-serif, apple color emoji, segoe ui emoji, segoe ui symbol;
  -webkit-font-smoothing: antialiased;
}

.landing-page-newsvisitors {
  width: 100%;
  margin-right: 10px;
  display: flex;
  flex-direction: column;
  align-self: flex-end;
  overflow: hidden;
  padding-bottom: 20px;
  mask-image: linear-gradient(0deg,
      rgba(0, 0, 0, 1) 0%,
      rgba(0, 0, 0, 1) 50%,
      rgba(0, 0, 0, 0) 300%);
}

.TeacherChatNewsVisitors {
  width: 100%;
  height: 100%;
  display: flex;
  overflow-y: auto;
  justify-content: space-around;
  flex-direction: column;
  align-items: flex-start;
}

.landing-page-public-chat-container {
  border-radius: 10px;
  background-color: white;
  position: fixed;
  right: 85px;
  bottom: 0;
  /* height: 450px; */
  display: flex;
  flex-flow: column;
  justify-content: flex-end;
  z-index: 4;
  width: 25%;
  align-self: flex-end;
  z-index: 0;
  box-sizing: border-box;
  /* -webkit-mask-image: linear-gradient(0deg,#000,#000 60%,transparent 80%); 
    mask-image: linear-gradient(0deg,#000,#000 60%,transparent 80%);
    -webkit-font-smoothing: antialiased;*/
}

.landing-page-public-input-and-send {
  width: 100%;
  z-index: 4;
  padding-bottom: 7px;
  display: flex;
  flex-flow: row;
  align-self: center;
  align-items: center;
  justify-content: flex-end;
}

.landing-page-chat-teacher-plusIcon {
  display: none;
}

.landing-page-teacher-chat-visitor-list {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

@media screen and (max-width: 600px) {
  .landing-page-public-input-and-send {
    width: 95%;
    padding-bottom: 7px;
    align-self: center;
    display: flex;
    flex-flow: row;
    align-items: center;
  }

  .landing-page-cursor-pointer-icon {
    width: 55px;
    margin-left: 0;
  }

  .landing-page-chat-teacher-plusIcon {
    width: 25px;
    z-index: 1000;
    justify-self: flex-start;
    display: block;
  }
}

.landing-page-public-chat-inputs {
  width: 80%;
}

.landing-page-chat-icon {
  grid-column: 2;
  width: 60px;
  margin-bottom: -8px;
  margin-left: 0px;
  cursor: pointer;
  
  
}

.landing-page-chat-icon svg {
  stroke: #000;
  color: #000;
}

.landing-page-avatar-container {
  padding-top: 4px;
}

.landing-page-avatar,
.landing-page-avatarUser,
.avatarD {
  display: fixed;
  margin: auto;
  width: 30px;
  height: 30px;
  cursor: pointer;
  border-radius: 50%;
}

.landing-page-avatar {
  background-color: #16008f;
}

.landing-page-avatarUser {
  background-color: #f605ff;
}

.text-colorU {
  color: #f605ff;
  /*font-family: 'Roboto', sans-serif; */
  font-family: -apple-system, BlinkMacSystemFont, segoe ui, Roboto, Helvetica, Arial,
    sans-serif, apple color emoji, segoe ui emoji, segoe ui symbol;
  -webkit-font-smoothing: antialiased;
}

.text-colorT {
  color: #16008f;
  font-family: -apple-system, BlinkMacSystemFont, segoe ui, Roboto, Helvetica, Arial,
    sans-serif, apple color emoji, segoe ui emoji, segoe ui symbol;
  -webkit-font-smoothing: antialiased;
}

.avatarD {
  background-color: #ffffff;
}

.text-colorD {
  color: #000;
  font-family: -apple-system, BlinkMacSystemFont, segoe ui, Roboto, Helvetica, Arial,
    sans-serif, apple color emoji, segoe ui emoji, segoe ui symbol;
  -webkit-font-smoothing: antialiased;
}

.messageDoc {
  margin-left: 2px;
  text-justify: auto;
  display: grid;
  grid-template-rows: 13px 1fr 13px;
  font-size: 13.2px;
  height: auto;
  align-self: center;
  font-family: -apple-system, BlinkMacSystemFont, segoe ui, Roboto, Helvetica, Arial,
    sans-serif, apple color emoji, segoe ui emoji, segoe ui symbol;
  -webkit-font-smoothing: antialiased;
}

.landing-page-message {
  margin: 0px 10px 0px 0px;
  display: grid;
  grid-template-columns: 50px auto;
  font-family: -apple-system, BlinkMacSystemFont, segoe ui, Roboto, Helvetica, Arial,
    sans-serif, apple color emoji, segoe ui emoji, segoe ui symbol;
  -webkit-font-smoothing: antialiased;
  width: 330px;
  padding-right: 10px;
  scroll-snap-align: end;
  /* scroll-padding-top: 3em; */
  position: relative;
  /* scroll-snap-stop: always; */
  scroll-padding: 100px 0px 0px 100px;
  /*margin-bottom: 1.5px;*/
}

.landing-page-scmessage {
  display: grid;
  grid-template-columns: 50px auto;
  width: 100px;
  margin-left: 10px;
}

.landing-page-message>h5 {
  font-family: 'HelveticaNeue-Light', 'Helvetica Neue Light', 'Helvetica Neue', Helvetica,
    Arial, 'Lucida Grande', sans-serif;
  font-weight: 500;
}

.landing-page-message>p {
  padding-bottom: 0px;
  font-family: -apple-system, BlinkMacSystemFont, segoe ui, Roboto, Helvetica, Arial,
    sans-serif, apple color emoji, segoe ui emoji, segoe ui symbol;
  -webkit-font-smoothing: antialiased;
}

.messageDoc h5 {
  font-size: 12px;
  font-family: -apple-system, BlinkMacSystemFont, segoe ui, Roboto, Helvetica, Arial,
    sans-serif, apple color emoji, segoe ui emoji, segoe ui symbol;
  -webkit-font-smoothing: antialiased;
}

.messageDoc p {
  margin-bottom: 0px;
  font-family: -apple-system, BlinkMacSystemFont, segoe ui, Roboto, Helvetica, Arial,
    sans-serif, apple color emoji, segoe ui emoji, segoe ui symbol;
  -webkit-font-smoothing: antialiased;
}

.timeD {
  font-size: 9px;
  margin-left: 5px;
}

.timeT {
  font-size: 9px;
  margin-left: 5px;
  color: #16008f;
  font-family: 'Open Sans', sans-serif;
}

.image {
  width: 34px;
  height: 34px;
  min-width: 25px;
  min-height: 25px;
  border-radius: 50%;
  margin: auto 8px;
  cursor: pointer;
}

.landing-page-changeColorToBlue,
.landing-page-changeColorTowhite,
.landing-page-changeColorToViolet {
  margin-bottom: 4px;
  font-family: -apple-system, BlinkMacSystemFont, segoe ui, Roboto, Helvetica, Arial,
    sans-serif, apple color emoji, segoe ui emoji, segoe ui symbol;
  -webkit-font-smoothing: antialiased;
}

.landing-page-changeColorToBlue>h5,
.landing-page-changeColorTowhite>h5,
.landing-page-changeColorToViolet>h5 {
  font-size: 13.5px;
  /*font-family: 'Roboto', sans-serif; font-weight: 400;*/
  font-family: -apple-system, BlinkMacSystemFont, segoe ui, Roboto, Helvetica, Arial,
    sans-serif, apple color emoji, segoe ui emoji, segoe ui symbol;
  font-weight: bold;
  -webkit-font-smoothing: antialiased;
  margin-bottom: 1px;
}

.landing-page-message>.landing-page-changeColorToBlue>p,
.landing-page-changeColorTowhite>p,
.landing-page-changeColorToViolet>p {
  font-size: 13.3px;
  font-family: -apple-system, BlinkMacSystemFont, segoe ui, Roboto, Helvetica, Arial,
    sans-serif, apple color emoji, segoe ui emoji, segoe ui symbol;

  -webkit-font-smoothing: antialiased;

  text-align: left !important;
  line-height: 1.3;
  -ms-word-break: break-all;
  word-break: break-all;
  word-break: break-word;
  /* Sólo WebKit -NO DOCUMENTADO */

  -ms-hyphens: auto;
  /* Guiones para separar en sílabas */
  -moz-hyphens: auto;
  /*  depende de lang en <html>      */
  -webkit-hyphens: auto;
  hyphens: auto;
  padding-bottom: 3px;
}

.landing-page-changeColorToBlue>span,
.landing-page-changeColorTowhite>span,
.landing-page-changeColorToViolet>span {
  font-size: 11px;
  font-family: -apple-system, BlinkMacSystemFont, segoe ui, Roboto, Helvetica, Arial,
    sans-serif, apple color emoji, segoe ui emoji, segoe ui symbol;

  -webkit-font-smoothing: antialiased;
}

.landing-page-changeColorToBlue {
  color: #16008f;
}

.landing-page-changeColorTowhite {
  color: #000;
}

.landing-page-changeColorToViolet {
  color: #e305eb;
  /*nuevo color*/
}

.landing-page-visitorSelect,
.landing-page-newMessage,
.newVisitor,
.landing-page-unselected-conversation {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  margin-right: 0px;
}

.landing-page-visitorSelect {
  /*background-color: #F605FF;*/
  background-color: rgba(227, 16, 240, 0.2);
}

.newVisitor {
  background-color: #53d34f;
}

@keyframes nuevomensaje {
  0% {
    background-color: rgba(0, 238, 255, 0.25);
  }

  25% {
    background-color: #99b898;
  }

  50% {
    background-color: rgba(0, 238, 255, 0.25);
  }

  75% {
    background-color: #99b898;
  }

  100% {
    background-color: rgba(0, 238, 255, 0.25);
  }
}

.landing-page-newMessage {
  animation-name: nuevomensaje;
  animation-duration: 2s;
  animation-iteration-count: infinite;
}

.landing-page-nameVisitor {
  font-size: 11px;
  margin-left: 10px;
  margin-right: 12px;
  font-family: 'Open Sans', sans-serif;
  font-weight: bold;
  color: black;
}

.camera-input {
  width: '100%';
  right: 0;
  z-index: 5;
}

@media screen and (max-width: 500px) {
  .landing-page-nameVisitor {
    color: #000;
  }
}

.landing-page-VisitorsInit {
  margin-top: 5px;
  display: grid;
  grid-template-columns: 12px auto;
  grid-gap: 5px;
  align-items: center;
  cursor: pointer;
  z-index: 99;
}

.landing-page-unselected-conversation {
  background-color: rgba(83, 39, 187, 0.329);
}

.typingMsg {
  grid-column: 2;
  /*justify-self: center;*/
  padding-left: 20px;
  margin-bottom: 0px;
  margin-top: -10px;
  box-sizing: border-box;
  z-index: 99;
  font-family: 'Open Sans', sans-serif;
}

.landing-page-p_typingMsg {
  padding-left: 20px;
  margin-bottom: 0px;
  line-height: 3;
  font-size: 14px;
  color: #000;
  font-family: 'Open Sans', sans-serif;
}

.landing-page-p_typingMsg>img {
  width: 13px;
  height: auto;
  margin-left: 3px;
  vertical-align: middle;
}

.TypingDiv {
  display: grid;
  grid-template-columns: auto auto;
  grid-template-rows: auto;
  box-sizing: border-box;
}

.landing-page-teacher-chat-new-visitors {
  padding-left: 10px;
  width: 210px;
  height: 100%;
  max-height: 330px;
  overflow-y: auto;
  overflow-x: hidden;
  scrollbar-width: none;
  transition: all 500ms ease;
  -webkit-font-smoothing: antialiased;
}

.-opacity {
  opacity: 0;
  transition: all 500ms ease;
}

.landing-page-teacher-chat-new-visitors-responsive {
  display: none;
}

@media screen and (max-width: 630px) {
  .landing-page-teacher-chat-new-visitors-responsive {
    width: 35%;
    padding-left: 15px;
    position: relative;
    max-height: 100%;
    height: calc(100vh - 175px);
    scrollbar-width: none;
    display: block;
    overflow-y: auto;
    transition: all 500ms ease;
    -webkit-mask-image: linear-gradient(0deg,
        rgba(0, 0, 0, 1) 0%,
        rgba(0, 0, 0, 1) 70%,
        rgba(0, 0, 0, 0) 90%);
    mask-image: linear-gradient(0deg,
        rgba(0, 0, 0, 1) 0%,
        rgba(0, 0, 0, 1) 60%,
        rgba(0, 0, 0, 0) 80%);
    -webkit-font-smoothing: antialiased;
  }

  .camera-input {
    width: '100%';
    right: 0;
    z-index: 5;
  }

  .landing-pages-teacher-chat-messages {
    width: 510px;
    height: calc(100vh - 175px);
    overflow: auto;
    display: grid;
    box-sizing: unset;
    align-content: flex-end;
    -webkit-mask-image: linear-gradient(0deg,
        rgba(0, 0, 0, 1) 0%,
        rgba(0, 0, 0, 1) 60%,
        rgba(0, 0, 0, 0) 100%);
    mask-image: linear-gradient(0deg,
        rgba(0, 0, 0, 1) 0%,
        rgba(0, 0, 0, 1) 60%,
        rgba(0, 0, 0, 0) 80%);
    font-family: -apple-system, BlinkMacSystemFont, segoe ui, Roboto, Helvetica, Arial,
      sans-serif, apple color emoji, segoe ui emoji, segoe ui symbol;
    -webkit-font-smoothing: antialiased;
  }
}

.landing-page-div-typing {
  min-height: 50px;
  margin-bottom: 5px;
  transition: all 500ms ease;
  height: 45px;
  width: 95%;
  scrollbar-width: none;
  box-sizing: border-box;
  align-self: center;
}

.landing-page-teacher-chat-div-typing {
  min-height: 50px;
  margin: 5px;
  transition: all 500ms ease;
  height: 45px;
  width: 95%;
  scrollbar-width: none;
  box-sizing: border-box;
  align-self: center;
}

.landing-page-close-button {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.landing-page-close-icon:hover {
  background-color: rgba(221, 220, 220, 0.755);
  border-radius: 5px;
}

.landing-page-notification {
  background-color: rgba(3, 0, 252, 0.2);
  height: auto;
  max-width: 85%;
  padding: 10px 5px;
  grid-column: 2;
  margin-left: 20px;
  box-sizing: border-box;
  visibility: block;
  margin-top: 5px;
  transition: visibility 3s;
}

.landing-page-notification-inputs {
  padding: 2.5px;
  border: 0.5px solid #000;
  margin-bottom: 10px;
  border-radius: 5px;
  background: transparent;
  color: #000;
  width: 50%;
}

.landing-page-notification-form {
  display: flex;
  margin-top: 10px;
  flex-direction: column;
  align-items: flex-start;
}

.landing-page-notification>p {
  font-size: 12px;
  color: #000;
  margin-bottom: 0px;
  padding-left: 5px;
  font-family: 'Open Sans', sans-serif;
}

.landing-page-notification p>a {
  margin: 0px;
  padding: 0px;
  display: inline-block;
}

.landing-page-notification p>a>img {
  width: 18px;
  height: auto;
  vertical-align: middle;
}

.landing-page-textinput {
  background-color: transparent;
  height: 90%;
  color: #000;
  font-size: 12px;
  border: none;
  padding-left: 5.5px;
  padding-top: 4px;
  outline: none;
  margin-left: 0px;
  font-size: 17px;
}

.landing-page-textinput::placeholder {
  color: #000;
}

.landing-page-exterior {
  height: 34px;
  width: 95%;
  border: 1px solid darkgray;
  border-radius: 10px;
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: space-around;
  grid-template-columns: 75.5% 1fr;
  margin-left: auto;
  margin-right: auto;
}

.landing-page-exterior::placeholder {
  color: #000;
}

.send-icon {
  cursor: pointer;
}

.send-icon-message {
  animation: translate 0.6s ease-in;
  /*ease-in*/
}

@keyframes translate {
  0% {
    transform: translate(0px, 0px);
  }

  100% {
    transform: translate(150px, -70px);
  }
}

.icon-3px {
  padding-top: 5px;
}

.menssages {
  background-color: blue;
  width: 300px;
  height: 600px;
}

.iconLogin {
  margin: 3px;
  color: #000;
  cursor: pointer;
  padding-bottom: 3px;
  text-align: center;
}

.red {
  width: 30px;
  height: 30px;
  background-color: red;
  position: absolute;
  top: 10px;
}

.chagendata {
  background-color: cornflowerblue;
  height: 45px;
  width: 100%;
  margin-left: 0px;
  display: grid;
  align-items: center;
  grid-template-rows: repeat(3, 1fr);
}

.landing-page-chat-teacher-chagendata {
  background-color: cornflowerblue;
  height: 45px;
  width: 100%;
  display: grid;
  align-items: center;
  grid-template-rows: repeat(3, 1fr);
}

.item {
  margin: auto;
}

.unploadimage {
  margin: auto;
}

.gbandtext {
  margin: auto;
  vertical-align: middle;
}

.gbandtext:active {
  height: 43px;
}

.divclose {
  cursor: pointer;
  color: #000;
  font-family: 'Nunito', sans-serif;
  position: absolute;
  top: -3px;
  right: 2px;
  font-size: 10px;
  font-weight: bold;
}

.MessageComp {
  width: 100px;
}

.bellNot {
  display: grid;
  width: 100%;
}

.landing-page-div-typing>img {
  width: 45px;
  margin-left: 140px;
}

.changeuserdata {
  align-items: center;
  width: auto;
  height: 45px;
  display: flex;
  overflow-x: auto;
  justify-content: space-between;
  padding: 3px;
  scrollbar-width: none;
  position: relative;
}

.landing-page-chat-teacher-changeuserdata {
  align-items: center;
  width: 100%;
  height: 45px;
  display: flex;
  overflow-x: auto;
  justify-content: space-between;
  padding: 3px;
  scrollbar-width: none;
  position: relative;
}

label img {
  width: 25px;
  cursor: pointer;
}

[type='button']:not(:disabled),
[type='reset']:not(:disabled),
[type='submit']:not(:disabled),
button:not(:disabled) {
  cursor: pointer;
  right: -170px;
}

.bg {
  background-color: royalblue;
  width: 35px;
  height: 35px;
  border-radius: 30px;
  margin: auto;
  cursor: pointer;
}

.bg:hover {
  background-color: royalblue;

  border-radius: 30px;
  transition: width 1s;
  margin: auto;
  cursor: pointer;
}

.gbandtext img {
  height: auto;
  width: auto;
  cursor: pointer;
  /*background-image: url('./chat-assets/chat-icons/cammera-icon-smaller.svg');*/
}

.login-key-icon {
  width: 60px;
  height: 60px;
}

.gbandtext img:hover {
  transition: width 1s;
  cursor: pointer;
}

.usericon {
  display: grid;
  align-items: center;
  justify-content: center;
}

.usericon img {
  height: 25px;
  width: auto;
  cursor: pointer;
}

.usericon img:hover {
  transition: width 1s;
  cursor: pointer;
}

.loading-img {
  z-index: 5;
}

.loading {
  width: 100%;
  margin: 0px auto;
  transition: height 3s;
  height: 1px;
}

.Home-container {
  flex-direction: column;
  align-items: flex-end;
  /* justify-content: center; */
}

.landing-page-private-chat {
  min-height: 330px;
  display: flex;
  border-bottom: 1px solid lightgray;
}

.landing-page-teacher-chat-container {
  display: flex;
  width: 470px;
  height: 440px;
  scrollbar-width: none;
  flex-direction: column;
  justify-content: space-between;
  position: fixed;
  bottom: 0;
  right: 90px;
  background-color: #fff;
  border-radius: 10px;
}

.chat-selection-alert {
  margin: 0 30px;
  font-size: 20px;
}

.landing-page-dinamic-teacher-chat-container {
  display: flex;
  align-items: center;
  transition: all 500ms ease;
  width: 100%;
  max-height: calc(var(--vh) - 295px);
  overflow-y: auto;
  justify-content: flex-end;
}

.landing-page-teacher-chat-ct {
  width: 100%;
  height: fit-content;
}

.container-messages {
  height: 100%;
  position: relative;
  width: 100%;
  overflow-y: scroll;
  margin-bottom: 10px;
  overflow-x: hidden !important;
}

.controls {
  display: flex;
  margin-bottom: 15px;

}

.landing-page-teacher-input-and-send {
  width: 20%;
  display: flex;
  padding-bottom: 7px;
  align-items: center;
  flex-direction: row;
  justify-content: flex-end;
  box-sizing: border-box;
}

.sctopmessage {
  position: relative;
  width: 40%;
  height: 150px;
}

.chat-mask {
  visibility: hidden;
  opacity: 0;
}

.landing-page-teacher-container-messages {
  border-left: 1px solid lightgray;
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  width: 100%;
  max-height: 330px;
}

/* Para 960px */
@media only screen and (min-width: 980px) and (min-width: 821px) {
  .Icons-container {
    z-index: 0;
    max-width: 70%;
    position: absolute;
    justify-content: space-around;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
  }

  .Home-container {
    flex-direction: column;
  }
}

@media screen and (max-width: 1200px) {
  .landing-page-public-chat-container {
    width: 30%;
  }
}

@media screen and (max-width: 1000px) {
  .landing-page-public-chat-container {
    width: 40%;
  }
}

@media screen and (max-width: 890px) {
  .landing-page-public-chat-container {
    width: 50%;
  }
}

@media screen and (max-width: 650px) {
  .locked-scroll {
    overflow: hidden !important;
  }

  .chat-mask {
    top: 0;
    position: fixed;
    background-color: rgba(0, 0, 0, 0.446);
    width: 100%;
    height: 100vh;
    visibility: visible;
    opacity: 1;
  }

  .landing-page-public-chat-container {
    width: 70%;
    padding: 0 10px;
  }

  .login-key-icon {
    margin-top: 20px;
    width: 60px;
    height: 60px;
  }
}

@media screen and (max-width: 500px) {
  .landing-page-public-chat-container {
    width: 90%;
    bottom: 5px;
    left: 5%;
    z-index: 4;
  }

  .ct {
    width: 70%;
  }

  .controls {
    max-height: 35px;
  }

  .landing-page-private-chat {
    min-height: auto;
    max-height: calc(var(--vh) - 120px);
  }

  .landing-page-close-button {
    max-height: 45px;
  }

  .landing-page-teacher-chat-container {
    display: flex;
    width: 100%;
    height: var(--vh);
    scrollbar-width: none;
    flex-direction: column;
    justify-content: space-between;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    background-color: #fff;
    border-radius: 0;
    z-index: 20000;
  }

  .landing-page-teacher-container-messages {
    width: 100%;
    margin-right: 0;
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
    min-height: calc(var(--vh) - 120px);
  }
}

/* Para 800px */
@media only screen and (max-width: 820px) and (min-width: 621px) {
  .Login-Icons-container {
    z-index: 0 !important;
  }

  .Icons-container {
    z-index: 99;
    max-width: 20%;
    position: absolute;
    justify-content: space-around;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    align-items: center;

    margin-left: -200px;
  }
}

/* Para 600px */
@media only screen and (max-width: 620px) and (min-width: 501px) {
  .Icons-container {
    z-index: 0;
    max-width: 20%;
    position: absolute;
    justify-content: space-around;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    align-items: center;
    margin-right: 50px;
    margin-left: -200px;
  }
}

@media screen and (max-width: 630px) {
  #landing-page-teacher-chat-teacher-chat-new-visitor {
    position: relative;
    max-height: inherit;
  }

  .landing-page-teacher-chat-new-visitors {
    display: none;
  }

  .landing-page-teacher-input-and-send {
    width: 15%;
    justify-content: flex-end;
    padding-bottom: 0px;
  }

  .landing-page-dinamic-teacher-chat-container {
    max-height: 100%;
  }
}

/* Para 480px */
@media only screen and (max-width: 500px) and (min-width: 360px) {
  .a-login {
    display: inline;
    padding: 0px;
  }

  .Login-Icons-container {
    height: 100%;
    width: 10% !important;
    margin-top: 20px;
  }

  .bell {
    margin: 10px auto !important;
  }

  .image {
    width: 42px;
    height: 42px;
    margin: auto 2px;
  }

  .Icons-container {
    z-index: 1;
    width: 10% !important;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    margin-left: 4px;
    height: 80%;
  }

  .Icon-img {
    width: auto;
    height: 43px !important;
  }

  .Icon-imgs {
    width: auto;
    height: 54px !important;
  }

  .Icon-imgss {
    width: auto;
    height: 40px !important;
  }

  .Icon-text {
    width: auto;
    height: 35px;
    display: none;
  }

  .landing-page-newsvisitors {
    margin-right: 0px;
    margin-left: 20px;
    z-index: 99;
  }

  .landing-page-messages {
    width: 100%;
  }

  .chatall {
    width: 70% !important;
    margin-left: -10%;
    padding: 0%;
  }

  .landing-page-chat-icon {
    width: 50px !important;
    position: absolute;
    right: 1%;
    bottom: -1%;
  }

  .send-icon {
    width: 100%;
    height: 100%;
  }

  .landing-page-div-typing>img {
    margin-top: -20px;
    margin-bottom: 0px;
  }

  .chagendata {
    background-color: rgba(0, 238, 255, 0.25);
    width: 73.3%;
    margin-left: -10px;
    margin-top: 7px;
    display: grid;
    align-items: center;
    grid-template-rows: repeat(3, 1fr);
    margin-right: 5px;
  }

  .container-messages {
    height: 100%;
    position: relative;
    width: 100%;
    overflow-y: scroll;
    margin-left: 10px;
  }

  .message {
    margin: 0px 10px 0px 0px;
    display: grid;
    grid-template-columns: 50px auto;
    font-family: -apple-system, BlinkMacSystemFont, segoe ui, Roboto, Helvetica, Arial,
      sans-serif, apple color emoji, segoe ui emoji, segoe ui symbol;
    -webkit-font-smoothing: antialiased;
    width: 230px;
    padding-right: 10px;
    scroll-snap-align: end;
    /* scroll-padding-top: 3em; */
    position: relative;
    /* scroll-snap-stop: always; */
    scroll-padding: 100px 0px 0px 100px;
    margin-bottom: 1.5px;
  }

  /*ADMIN CSS PARA MOVIL*/

  .admin-Chat-container {
    max-width: 70%;
    margin-left: -15px;
  }

  .landing-page-newsvisitors {
    margin-right: 0px;
    margin-left: 20px;
    z-index: 99;
    /* width: min-content; */
  }

  .admin-messages {
    width: 100%;
  }

  .admin-chatall {
    padding-left: 0px;
  }

  .admin-chat-icon {
    margin-right: 15px;
    margin-left: -85px;
    margin-top: 6px;
    width: 55px;
  }

  .admin-send-icon {
    margin-left: 5px !important;
    width: 45px;
  }

  .landing-page-div-typing>img {
    margin-top: -20px;
    margin-bottom: 0px;
  }

  .admin-container-messages {
    height: 100%;
    position: relative;
    width: 100%;
    overflow-y: scroll;
    padding-bottom: 20px;
    margin-left: 10px;
  }

  .admin-message {
    margin: 0px 10px 0px 0px;
    display: grid;
    grid-template-columns: 50px auto;
    font-family: -apple-system, BlinkMacSystemFont, segoe ui, Roboto, Helvetica, Arial,
      sans-serif, apple color emoji, segoe ui emoji, segoe ui symbol;
    -webkit-font-smoothing: antialiased;
    width: 230px;
    padding-right: 10px;
    scroll-snap-align: end;
    /* scroll-padding-top: 3em; */
    position: relative;
    /* scroll-snap-stop: always; */
    scroll-padding: 100px 0px 0px 100px;
    margin-bottom: 1.5px;
  }

  .admin-chatall {
    margin-left: 0px;
    width: 100%;
    /* width: 370px; */
    margin-right: 20px;
  }

  .admin-newsvisitors {
    margin-right: 0px;
    margin-left: 20px;
    z-index: 99;
  }
}

/* Para 320px */
@media (max-width: 365px) {
  .Home-container {
    height: 100%;
    padding: 0px;
  }

  .a-login {
    display: inline;
    padding: 0px;
  }

  .Login-Icons-container {
    height: 60%;
    width: 10% !important;
    margin-top: 40px;
  }

  .Icons-container {
    z-index: 1;
    width: 10% !important;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;

    margin-left: 4px;
    height: 90%;
  }

  .Icon-imgs {
    width: auto;
    height: 52px !important;
  }

  .Icon-img {
    width: auto;
    height: 38px !important;
  }

  .Icon-text {
    width: auto;
    height: 35px;
    display: none;
  }

  .landing-page-newsvisitors {
    margin-right: 0px;
    margin-left: 20px;
    z-index: 99;
    /* width: min-content; */
  }

  .landing-page-messages {
    width: 100%;
  }

  .chatall {
    width: 70% !important;
    margin-left: -10%;
    padding: 0%;
  }

  .landing-page-chat-icon {
    width: 50px !important;
    position: absolute;
    right: 1%;
    bottom: -1%;
  }

  /* .exterior {
    margin-left: -5%;
    width: 195px;
    margin-top: -3%;
  } */

  .send-icon {
    margin-left: -5px !important;
  }

  .landing-page-div-typing>img {
    margin-top: -20px;
    margin-bottom: 0px;
  }

  .landing-page-div-typing {
    padding-left: 0% !important;
  }

  .chagendata {
    background-color: rgba(0, 238, 255, 0.25);
    margin-left: 0px;
    display: grid;
    align-items: center;
    grid-template-rows: repeat(3, 1fr);
  }

  .container-messages {
    height: 100%;
    position: relative;
    width: 100%;
    overflow-y: scroll;
    padding-bottom: 20px;
    margin-left: 10px;
  }

  .message {
    margin: 0px 10px 0px 0px;
    display: grid;
    grid-template-columns: 50px auto;
    font-family: -apple-system, BlinkMacSystemFont, segoe ui, Roboto, Helvetica, Arial,
      sans-serif, apple color emoji, segoe ui emoji, segoe ui symbol;
    -webkit-font-smoothing: antialiased;
    width: 230px;
    padding-right: 10px;
    scroll-snap-align: end;
    /* scroll-padding-top: 3em; */
    position: relative;
    /* scroll-snap-stop: always; */
    scroll-padding: 100px 0px 0px 100px;
    margin-bottom: 1.5px;
  }

  /*ADMIN CSS PARA MOVIL*/

  .admin-Chat-container {
    max-width: 60%;
    margin-left: 10px;
    height: 60%;

    margin-top: 0px !important;
  }

  .landing-page-newsvisitors {
    margin-right: 0px;
    margin-left: 15px;
    z-index: 99;
    /* width: min-content; */
  }

  .admin-messages {
    margin-left: 8px;
    width: 100%;
  }

  .admin-messages::-webkit-scrollbar {
    display: none;
  }

  .admin-chatall {
    padding-left: 0px;
    min-height: 45px;
    width: 100% !important;
    margin-right: 28px !important;
    margin-left: -29px !important;
  }

  .admin-chat-icon {
    margin-right: 12px;
    margin-left: -30px;
    width: 45px;
    margin-top: -7px;
  }

  .landing-page-teacher-chat-ct {
    width: 85%;
    height: -moz-fit-content;
    height: fit-content;
    right: 0;
  }

  /* .admin-exterior {
    width: 180px;
    margin-top: auto;
    height: 25px;
  } */

  .admin-send-icon {
    margin-left: -1px !important;
    width: 45px;
    padding-top: 0px;
  }

  .landing-page-div-typing>img {
    margin-bottom: 0px;
  }

  .chagendata {
    background-color: rgba(0, 238, 255, 0.25);
    display: grid;
    align-items: center;
    grid-template-rows: repeat(3, 1fr);
    width: 100%;
  }

  .admin-container-messages {
    height: 100%;
    position: relative;
    width: 100%;
    overflow-y: scroll;
    padding-bottom: 20px;
    margin-left: 10px;
  }

  .admin-message {
    margin: 0px 10px 0px 0px;
    display: grid;
    grid-template-columns: 50px auto;
    font-family: -apple-system, BlinkMacSystemFont, segoe ui, Roboto, Helvetica, Arial,
      sans-serif, apple color emoji, segoe ui emoji, segoe ui symbol;
    -webkit-font-smoothing: antialiased;
    width: 230px;
    padding-right: 10px;
    scroll-snap-align: end;
    /* scroll-padding-top: 3em; */
    position: relative;
    /* scroll-snap-stop: always; */
    scroll-padding: 100px 0px 0px 100px;
    margin-bottom: 1.5px;
  }

  .admin-chatall {
    margin-left: -10px;
    /* width: 70%; */
    width: 370px;
    margin-right: 20px;
  }

  .admin-chat-icon {
    margin-left: -110px;
  }

  .admin-newsvisitors {
    margin-right: 0px;
    margin-left: 20px;
    z-index: 99;
  }
}