.whiteboard-container-notes {
  position: absolute;
  width: 48%;
  top: 11vh;
  right: 1%;
  height: 59%;
  max-height: 441px;
}

.whiteboard-container-notes>.whiteboard-main {
  display: flex;
  height: 100%;
  position: relative;
  gap: 10px;
}

.translateNote {
  transform: translateX(0px);
  transition: all ease-out 0.5s;
  position: absolute;
}

.currentTranslate {
  transform: translateX(1000px);
  transition: all ease-out 0.5s;

}
.whiteboard-main>.whiteboard-notes--open {
  //transform: translateX(0);
  opacity: 1;
}

.whiteboard-main>.whiteboard-notes--close {
  // transform: translateX(100%);
  opacity: 0;
}

.whiteboard-main>.motionActived {
  opacity: 0;
}

.whiteboard-main>.whiteboard-notes {
  width: 50%;
  height: 100%;
  flex-shrink: 0;
  z-index: 36;
  //transition: transform 0.5s ease, opacity 0.5s ease;
}

.whiteboard-notes:active+.whiteboard-frame {
  opacity: 1;
}

.whiteboard-main>.whiteboard-frame {
  display: flex;
  width: 50%;
  flex-shrink: 0;
  height: 100%;
  border: 1px solid;
  border-style: dashed;
  border-radius: 10px;
  position: absolute;
  opacity: 0;
  visibility: hidden;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  top: 0;
  transition: visibility 1s ease, opacity 1s ease;
  z-index: -100;
}

.whiteboard-frame.active {
  opacity: 1;
  visibility: visible;
  z-index: 100;
  cursor: pointer;
}

.whiteboard-main>.whiteboard-draggable {
  width: 50%;
  height: 100%;
  flex-shrink: 0;
  z-index: 100;
  visibility: hidden;
}

.whiteboard-draggable.active {
  z-index: -100;
}

.notes {
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: space-between;
  color: rgb(12, 40, 119);
  font-family: 'Roboto';
  font-size: 14px;
  width: 100%;
  height: 100%;
  border-radius: 15px;
  padding: 10px;
  text-align: justify;
}

.notesTrue {
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: space-between;
  font-family: 'Roboto';
  font-size: 14px;
  width: 100%;
  max-height: 250px;
  height: 100%;
  background-color: rgba(11, 166, 228, 0.15);
  border-radius: 15px;
  padding: 10px;
  text-align: justify;
  margin: 10px 0;
}

.notesNormal {
  overflow: auto;
  padding: 1px;
}

.notesEdit {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  overflow: auto;
  border: 2px dashed #29299d;
  border-spacing: 12px;
  border-radius: 5px;
}

.notesEditTrue {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  overflow: auto;
  border: 2px dashed #29299d;
  border-spacing: 12px;
  border-radius: 5px;
}

.divTitleNoteEdit {
  display: flex;
  text-align: center;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  gap: 30%;
}

.edit {
  cursor: pointer;
}

.input {
  background-color: transparent;
  color: #29299d;
  width: 50%;
  border: 1px solid #29299d;
  border-left: none;
  border-top: none;
  border-right: none;
}

.divInputFor {
  display: flex;
  width: 100%;
  height: auto;
  align-items: flex-start;
  gap: 10px;
}

.edit:hover {
  transform: scale(1.3);
}

.createMode {
  position: absolute;
  padding: 0 6%;
  width: 100%;
  top: 15px;
  left: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.createModeTrue {
  position: absolute;
  padding: 0 6%;
  width: 100%;
  top: 25px;
  left: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.closedNoteBoard {
  display: none;
  height: 15px;
  cursor: pointer;
  transform: rotate(135deg);
  transition: transform 0.5s ease;
}

.closedNoteBoardTrue {
  height: 15px;
  cursor: pointer;
  transform: rotate(135deg);
  transition: transform 0.5s ease;
}

.notesNormal {
  overflow: auto;
  padding: 1px;
}


.create {
  height: 15px;
  cursor: pointer;
  transition: transform 0.5s ease;
}

.createTrue {
  height: 15px;
  cursor: pointer;
  transition: transform 0.5s ease;
}

.closedTrue {
  height: 15px;
  cursor: pointer;
  transform: rotate(135deg);
  transition: transform 0.5s ease;
}

.closedTrueNote {
  height: 15px;
  cursor: pointer;
  transform: rotate(135deg);
  display: none;
}

.closed {
  height: 15px;
  cursor: pointer;
  transform: rotate(135deg);
  transition: transform 0.5s ease;
}

.noteIcon {
  height: 20px;
  display: none;
}

.noteIconTrue {
  height: 15px;
  display: none;
}

.createNote {
  display: none;
}

.imgImage {
  height: 20px;
  width: 30px;
}

.imgMove {
  height: 20px;
  width: 30px;
}

/* Ajustes de estilo para los iconos */
.icon-small {
  height: 20px;
  width: 20px;
  cursor: pointer;
  transition: transform 0.5s ease;
}

.icon-small:hover {
  transform: scale(1.3);
}

@media screen and (max-width: 900px) {
  .createMode {
    width: 95%;
    padding: 0;
    margin: 0;
    justify-content: end;
    top: 12px;
    gap: 15px;
  }

  .createModeTrue {
    left: 5%;
    width: 90%;
    justify-content: space-between;
  }

  .notes {
    z-index: 100000;
    background-color: transparent;
  }

  .notesTrue {
    /*position: fixed;*/

    margin: 0;
    padding: 0;
    top: 5%;
    left: 5%;
    width: 90%;
    height: 90%;
    max-height: unset;
    min-height: 550px;
    z-index: 100000;
    backdrop-filter: blur(10px);
    border-radius: 0px;
    opacity: 1;
    visibility: visible;
    transform: translateX(0);
  }

  .create {
    display: none;
  }

  .closed {
    display: none;
  }

  .closedTrue {
    display: none;

  }

  .closedNoteBoardTrue {
    display: none;
  }

  .closedNoteBoard {
    display: none;
  }

  .createTrue {
    display: block;
    height: 15px;
  }

  .createNote {
    display: block;
    font-size: 16px;
    padding: 0;
    padding-bottom: 5%;
    margin: 0 0 0 50%;
    color: #270fc5;
  }


  .notesEditTrue {
    min-height: 510px;
  }

  .notesEdit {
    min-height: 510px;
  }

  .imgImage {
    height: 15px;
    width: 20px;
  }

  .imgMove {
    height: 15px;
    width: 20px;
  }

  .icon-small {
    height: 20px;
    width: 20px;
  }
}

@media screen and (max-width: 576px) {
  .whiteboard-main>.whiteboard-notes {
    z-index: 10000;
  }

  .whiteboard-container-notes {
    position: absolute;
    width: 100%;
    top: 0;
    right: 1%;
    height: 100%;
    max-height: 100%;
  }

  .whiteboard-main>.whiteboard-draggable {
    width: 0%;
    height: 1%;
    flex-shrink: 0;
    z-index: 100;
    visibility: hidden;
  }

  .whiteboard-draggable.active {
    z-index: -100;
  }

  .notesTrue {
    position: relative;
    margin: 0;
    padding: 0;
    top: 0%;
    left: -1%;
    width: 200%;
    height: 100%;
    max-height: unset;
    min-height: 550px;
    z-index: 100000;
    backdrop-filter: blur(10px);
    border-radius: 0px;
    transition: all ease-in 1s;

  }

}