.containerWhiteboardMain {
  display: flex;
  align-items: start;
  width: 100%;
  height: 100%;
  min-width: 100%;
  position: relative;
  overflow: hidden;
  cursor: auto !important;
}

.containerWhiteboardComponent {
  // width: 100%;
  // height: 100%;
  width: auto;
  height: auto;
  // min-width: 100%;
  position: relative;
  overflow: hidden;
  cursor: auto !important;
}

.editingText {
  width: 100%;
  height: 100%;
  min-width: 100%;
  position: relative;
  overflow: hidden;
  cursor: move !important;
}

.containerWhiteboardReadingComponent {
  width: 100%;
  height: 100%;
}

.editingTextReading {
  width: 100%;
  height: 100%;
  flex-grow: 1;
  position: relative;
}

.containerStage {
  // width: 100%;
  // height: 100%;
  border-radius: 5px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  cursor: auto !important;
  & > div { background: white; }
}
.containerStageMiniature {
  width: 100%;
  height: 100%;
  background: white;
  border-radius: 5px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  cursor: grab !important;
  &:active {
    cursor: grabbing !important;
  }
}
.containerStageRead {
  background: white;
  border-radius: 5px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  cursor: grab !important;
  &:active {
    cursor: grabbing !important;
  }
}
.containerStageText { 
  width: 100%;
  height: 100%;
  border-radius: 5px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  cursor: crosshair !important;
  & > div { background: white; }
}

.textarea {
  width: 200px;
  height: 100px;
  resize: none;
  padding: 5px;
}

.icon {
  width: 10px;
  height: 60px;
  user-select: none;
  cursor: pointer;
}





