.modern-model__page-icon {
  position: absolute;
  width: 100%;
  height: 36px;
  display: flex;
  justify-content: space-between;
  padding: 5px 1px 8px 1px;
  margin-bottom: 0px;
  bottom: 5px;
  z-index: 23;
}

.clasic-model__page-icon {
  position: fixed;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 1px 8px 1px;
  bottom: 0px;
  overflow: hidden;
  z-index: 23;
}

.page-icon-item {
  cursor: pointer;
  width: 32px;
  height: 29px;
  position: relative;
}

.page-flecha-icon-item {
  cursor: pointer;
  width: 35px;
  height: 37px;
  position: relative;
  margin-top: -6px;
}

 
@media screen and (min-width: 768px) and (max-width: 1020px) {
  .page-icon-item,
  .page-flecha-icon-item {
    cursor: pointer;
    width: 29px;
    height: 23px;
    margin-top: 0px;
  }
  
}

@media only screen and (max-width: 767px) and (min-width: 482px) {
  .page-icon-item,
  .page-flecha-icon-item {
    cursor: pointer;
    width: 33px;
    height: 28px;
    margin-top: 0px;
  }
}

@media (max-width: 480px) {
  .page-icon-item,
  .page-flecha-icon-item {
    cursor: pointer;
    width: 23px;
    height: 20px;
    margin-top: 0px;
  }
}
