.testComponents__container {
  position: fixed;
  width: 17%;
  height: 29%;
  background-color: rgba(0, 0, 255, 0.15);
  top: 31em;
  left: -50em;
  right: 0;
  bottom: 0;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 1em;
  animation-name: fadeInUp;
  animation-duration: 1s;

  @media (max-width: 850px) {
    width: 60%;
    height: 25%;
    top: 0em;
    left: 0em;
  }

  @media (min-width: 1500px) {
    width: 16%;
    height: 25%;
    top: 35em;
    left: -60em;
  }

  @media (min-width: 1600px) {
    width: 16%;
    height: 25%;
    top: 35em;
    left: -60em;
  }

  @media (min-width: 1700px) {
    width: 17%;
    height: 25%;
    top: 35em;
    left: -60em;
  }
}

@keyframes fadeInUp {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.testComponents__article {
  position: relative;
  width: auto;
  min-width: 300px;
  max-width: 500px;
  height: auto;
  min-height: 250px;
  border-radius: 1.5em;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2em;
  padding: 2em;
}

.testComponents__article span {
  position: absolute;
  top: 2.2em;
  right: 2.4em;
  color: #ffffff;
  font-size: 1.3em;
  font-weight: 400;
  cursor: pointer;

  @media (min-width: 1500px) {
    top: 1.9em;
    right: 1.2em;
  }

  @media (min-width: 1700px) {
    top: 1.8em;
    right: 0.7em;
  }
}

.testComponents__article img {
  width: 4em;
  height: 4em;
}

.testComponets__icon {
  width: 40%;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1em;
  cursor: pointer;
  transform: translateX(0);
  opacity: 1;
  transition: 0.5s;
}

.testComponets__icon-translate {
  position: absolute;
  transform: translateX(-300%);
  opacity: 0;
  transition: 0.5s;
}

.testComponets__icon p {
  font-size: 1.5em;
  color: #ffffff;
  margin: 0;
  text-align: center;
}

.page-icon-item {
  padding-top: 0em;
  margin-bottom: 0.8em;
  transform: scale(1.2); /* Escala el SVG al doble de su tamaño original */
}

.test_fing {
  padding-top: -1em;
}

.testComponents_onClose {
  position: absolute;
  top: 0.5px;
  right: 0.5px;
  cursor: pointer;
  color: white;
  z-index: 8;
}