.resizableEditing + div {
  border: none;
  outline-style: solid !important;
  outline-width: 2px !important;
  outline-color: #7FCFFF !important;
  transform-origin: top left;

  & .square {
    display: none;
  }

  & .resizable-handler {
    width: 14px !important;
    height: 14px !important;
    background: #fff;
    border: 2px solid #7FCFFF;
    position: absolute;
  }

  & .t, & .b {
    cursor: ns-resize;
  }

  & .l, & .r {
    cursor: e-resize;
  }

  & .tl {
    cursor: nwse-resize;
  }

  & .bl {
    cursor: nesw-resize
  }

  & .tr {
    cursor: nesw-resize;
  }
  
  & .br {
    cursor: se-resize;
  }
}

.resizableEditing + div::after {
  content: '' !important;
  display: block !important;
  width: 105%;
  height: 105%;
  position: absolute;
  top: 0;
  left: 0;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.resizableResize + div {
  outline-style: solid !important;
  outline-width: 2px !important;
  outline-color: #7FCFFF !important;
  border: none;

  & .rotate {
    width: 12px;
    height: 12px;
    font-size: 18px;
    display: inline-block;
    top: -55px;
    border: 2px solid #7FCFFF;
    background: white;
    cursor: crosshair;

    & i {
      background-image: none !important;
    }

    &::after {
      content: '';
      display: block;
      position: absolute;
      width: 2px;
      height: 36px;
      background: #7FCFFF;
      top: 100%;
      left: 50%;
      transform: translate(-50%, 0%);
    }
  }

  & .square {
    display: none;
  }

  & .resizable-handler {
    width: 14px !important;
    height: 14px !important;
    background: #fff;
    border: 2px solid #7FCFFF;
    position: absolute;
  }
}

.resizableResize + div::after {
  content: '' !important;
  display: block !important;
  width: 105%;
  height: 105%;
  position: absolute;
  top: 0;
  left: 0;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}