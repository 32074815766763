/* ConfirmPublicExamModal.css */
.overlay_ConfirmPublicExamModal {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 99999;
  }
  
  .overlay_ConfirmPublicExamModal .modalContent {
    background: rgba(7, 7, 249, 0.19);
    padding: 20px;
    border-radius: 8px;
    width: 300px;
    color: white;
    position: relative;
    z-index: 99999;
  }
  
  .overlay_ConfirmPublicExamModal .modalButtons {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center; 
    padding: 35px 10px;
}
  
  .overlay_ConfirmPublicExamModal .cancelButton, .overlay_ConfirmPublicExamModal
  .confirmButton {
    margin: 0 10px;
    padding: 7px 0px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    color: white;
    background: rgba(7, 7, 249, 0.0);
    font-weight: 100;
    font-size: 16.5px;
    font-family: 'Roboto';
  }
  
  .overlay_ConfirmPublicExamModal .click_close {
    position: absolute;
    top: 0.2px;
    right: 0.2px;
    cursor: pointer;
    color: white;
  }