.userData{
    display: flex;
    justify-content: center;
}

.saveButton {
    font-size: 14px;
    color: #fff;
    padding: 2px 6px;
    border: 1px solid #fff;
    border-radius: 5px;
    background-color: transparent;
}

.saveButton:active{
  transform: scale(0.95);
}

.formNotification {
  position: absolute;
  bottom: 0;
  left: 5px;
  width: 200px;

  p {
    font-size: 0.8rem;
    color: rgb(145, 20, 20);
  }
}

.save{
  width: 100%;
  padding-bottom: 10px;
  display: flex;
  justify-content: flex-end;
  padding-right: 2.5%;
}

