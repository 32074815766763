.wrapper {
  position: absolute;
  left: 50%;
  bottom: 100%;
}
wrapper h1 {
  font-size: 1.625em;
  color: black;
  text-align: center;
  position: absolute;
  left: 51%;
  /* I think this works for centering? */
  top: calc(52% - 64px);
  -webkit-transform: translate(-50%, calc(-50% - 3.5em));
  -ms-transform: translate(-50%, calc(-50% - 3.5em)) rotate(4deg);
  transform: translate(-50%, calc(-50% - 3.5em)) rotate(3deg);
}

@media (max-width: 600px) {
  .wrapper {
    position: absolute;
    left: 50%;
    bottom: 85%;
  }
}
.wrapper h1 span {
  position: absolute;
  min-width: 13px !important;
}

@media (max-width: 767px) {
  .App .speed-meter-test svg.speedometer g:nth-child(3) {
    transform: translate(50%, 81%) !important
  }

  .App .btn-start-test {
    margin-top: -125px !important;
  }

  .wrapper h1 {
    top: calc(62% - 64px);
  }

}

@media (max-width: 380px) {
  .wrapper h1 {
    left: 51%;
  }
}

@media (max-width: 340px) {
  .wrapper h1 {
    font-size: 20px;
  }

  .App .speed-meter-test svg.speedometer g:nth-child(3) {
    transform: translate(42%, 81%) !important
  }
}

@media (max-width: 1200px) and (min-height: 1100px) {
  .wrapper h1 {
    left: 55%;
  }
}

@media (max-width: 990px) and (min-height: 1100px) {
  .wrapper h1 {
    left: 51%;
  }
}

@media (max-width: 1200px) and (max-height: 720px) {
  .wrapper h1 {
    left: 58%;
  }
}

@media (min-width: 1000px) and (max-height: 880px) {
  .wrapper h1 {
    left: 52%;
  }
}

@media (max-width: 720px) and (max-height: 780px) {
  .wrapper h1 {
    left: 52%;
  }
  
}

@media (min-width: 1200px) {
  .wrapper h1 {
    left: 51%;
  }
}

@media (min-width: 1300px) {
  .wrapper h1 {
    left: 54%;
  }
}

@media (min-width: 1500px) {
  .wrapper h1 {
    left: 51%;
  }
}
