/*@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500&display=swap');*/

* {
  margin: 0;
}

.chat-container {
  z-index: 999;
}

.noselect {
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Old versions of Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */
}

.message-private {
  margin: 0px 10px 0px 0px;
  display: grid;
  grid-template-columns: 50px auto;
  font-family: -apple-system, BlinkMacSystemFont, segoe ui, Roboto, Helvetica, Arial,
    sans-serif, apple color emoji, segoe ui emoji, segoe ui symbol;
  -webkit-font-smoothing: antialiased;
  width: 380px;
  padding-right: 10px;
  scroll-snap-align: end;
  /* scroll-padding-top: 3em; */
  position: relative;
  /* scroll-snap-stop: always; */
  scroll-padding: 100px 0px 0px 100px;
  /*
  background-color: rgba(0, 238, 255, 0.25);*/
  padding-top: 4px;
  cursor: pointer;
  margin-bottom: 4px;
}

.message-privat {
  background-color: rgba(62, 66, 66, 0.25);
  border-radius: 5px 10px 10px 10px;
  min-width: 120px;
  padding-left: 10px;
  padding-top: 10px;
  padding-right: 3px;
}

.message-privat::after {
  content: '';
  display: block;
  border-right: 7px solid rgba(62, 66, 66, 0.219);
  border-top: 7px solid transparent;
  border-bottom: 8px solid transparent;
  position: absolute;
  left: 43px;
  top: 10px;
  z-index: 3;
}

.message-private:focus {
  background-color: rgba(18, 121, 238, 0.109);
  color: black !important;
}

.input-message-private {
  background-color: rgba(212, 18, 238, 0.171);
}

.message-private>p {
  font-size: 13px;
  color: white;
  margin-bottom: 0px;
  padding-left: 5px;
  font-family: 'Open Sans', sans-serif;
}

.message-private>p>b {
  cursor: pointer;
  text-transform: capitalize;
  text-decoration: underline;
}

.message-private p>a {
  margin: 0px;
  padding: 0px;
  display: inline-block;
}

.message-private p>a>img {
  width: 18px;
  height: auto;
  vertical-align: middle;
}

::-webkit-scrollbar {
  background-color: transparent;
  width: 2px;
}

::-webkit-scrollbar-track {
  background: transparent;
  border-radius: 15px;
}

::-webkit-scrollbar-thumb {
  background: transparent;
  border-radius: 15px;
}

::-webkit-scrollbar-thumb:hover {
  background: transparent;
}

::-webkit-scrollbar {
  display: none;
}

.container-messages-scroll {
  width: 750px;
  height: 100%;
  overflow: auto;
}


.whiteboard-chat-public-clasico-container-wp {
  z-index: 10;
  top: 15px;
  height: 80%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 25%;
  z-index: 50;
  box-sizing: content-box;
  position: fixed;
  right: 0;
  bottom: 0;
  margin-bottom: 80px;
  margin-right: 120px;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background: linear-gradient(
    to bottom,
    rgba(24, 24, 24, 0),  /* Totalmente transparente en la parte superior */
    rgba(131, 131, 131, 0.050) 50%,  /* Color sólido en el centro */
    rgba(41, 41, 41, 0.007) 100%  /* Totalmente transparente en la parte inferior */
);
}

.whiteboard-chat-public-container-wp {
  z-index: 2;
  top: 15px;
  height: 80%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 25%;
  z-index: 50;
  box-sizing: content-box;
  position: fixed;
  right: 20px;
  bottom: 0;
  margin-bottom: 80px;
  margin-right: 120px;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.wp-chat-conten-1 {
  width: 100%;
  height: 82%;
}

.wp-chat-conten-2 {
  width: 100%;
  height: 10%;
  margin-bottom: 5px;
}

.wp-chat-conten-2>img {
  width: 48px;
  display: block;
  margin: 0 auto;
}

.wp-chat-conten-3 {
  width: 100%;
  height: 8%;
}

* {
  scrollbar-width: none;
}

.close-chat-btn {
  display: none;

}

.messages-wp {
  width: 100%;
  height: 100%;
  overflow: auto;
  display: grid;
  box-sizing: unset;
  align-content: end;
  -webkit-mask-image: linear-gradient(0deg,
      rgba(0, 0, 0, 1) 0%,
      rgba(0, 0, 0, 1) 60%,
      rgba(0, 0, 0, 0) 80%);
  mask-image: linear-gradient(0deg,
      rgba(0, 0, 0, 1) 0%,
      rgba(0, 0, 0, 1) 60%,
      rgba(0, 0, 0, 0) 80%);
  font-family: -apple-system, BlinkMacSystemFont, segoe ui, Roboto, Helvetica, Arial,
    sans-serif, apple color emoji, segoe ui emoji, segoe ui symbol;
  -webkit-font-smoothing: antialiased;
}

.chatall {
  grid-column: 2;
  padding-top: 2px !important;
  display: grid;
  grid-gap: 2px;
  grid-template-columns: 40px 40px;
  padding-left: 14px;
}

.chat-banner {
  display: 'flex';
  align-items: 'center';
  margin-left: 'auto';
}

.avatar-container {
  padding-top: 4px;

}

.avatar,
.avatarUser,
.avatarD {
  display: fixed;
  margin: auto;
  width: 30px;
  height: 30px;
  cursor: pointer;
  border-radius: 50%;
}

.avatar {
  background-color: #16008f;
}

.avatarUser {
  background-color: #f605ff;
}

.text-colorU {
  color: #f605ff;
  /*font-family: 'Roboto', sans-serif; */
  font-family: -apple-system, BlinkMacSystemFont, segoe ui, Roboto, Helvetica, Arial,
    sans-serif, apple color emoji, segoe ui emoji, segoe ui symbol;
  -webkit-font-smoothing: antialiased;
}

.text-colorT {
  color: #16008f;
  font-family: -apple-system, BlinkMacSystemFont, segoe ui, Roboto, Helvetica, Arial,
    sans-serif, apple color emoji, segoe ui emoji, segoe ui symbol;
  -webkit-font-smoothing: antialiased;
}

.avatarD {
  background-color: #ffffff;
}

.text-colorD {
  color: #ffffff;
  font-family: -apple-system, BlinkMacSystemFont, segoe ui, Roboto, Helvetica, Arial,
    sans-serif, apple color emoji, segoe ui emoji, segoe ui symbol;
  -webkit-font-smoothing: antialiased;
}

.messageDoc {
  margin-left: 2px;
  text-justify: auto;
  display: grid;
  grid-template-rows: 13px 1fr 13px;
  font-size: 13.2px;
  height: auto;
  align-self: center;
  font-family: -apple-system, BlinkMacSystemFont, segoe ui, Roboto, Helvetica, Arial,
    sans-serif, apple color emoji, segoe ui emoji, segoe ui symbol;
  -webkit-font-smoothing: antialiased;
}

.message {
  margin: 0px 10px 0px 0px;
  display: grid;
  grid-template-columns: 50px auto;
  font-family: -apple-system, BlinkMacSystemFont, segoe ui, Roboto, Helvetica, Arial,
    sans-serif, apple color emoji, segoe ui emoji, segoe ui symbol;
  -webkit-font-smoothing: antialiased;
  padding-right: 10px;
  scroll-snap-align: end;
  /* scroll-padding-top: 3em; */
  position: relative;
  /* scroll-snap-stop: always; */
  scroll-padding: 100px 0px 0px 100px;
  /*margin-bottom: 1.5px;*/
}

.messageYo {
  margin: 0px 10px 0px 0px;
  display: grid;
  grid-template-columns: 50px auto;
  font-family: -apple-system, BlinkMacSystemFont, segoe ui, Roboto, Helvetica, Arial,
    sans-serif, apple color emoji, segoe ui emoji, segoe ui symbol;
  -webkit-font-smoothing: antialiased;
  width: 330px;
  padding-right: 10px;
  scroll-snap-align: end;
  /* scroll-padding-top: 3em; */
  position: relative;
  /* scroll-snap-stop: always; */
  scroll-padding: 100px 0px 0px 100px;
  /*margin-bottom: 1.5px;*/
  margin-left: 50px;
}

.scmessage {
  display: grid;
  grid-template-columns: 50px auto;
  width: 100px;
  margin-left: 10px;
}

.message>h5 {
  font-family: 'HelveticaNeue-Light', 'Helvetica Neue Light', 'Helvetica Neue', Helvetica,
    Arial, 'Lucida Grande', sans-serif;
  font-weight: 500;
}

.message>p {
  padding-bottom: 0px;
  font-family: -apple-system, BlinkMacSystemFont, segoe ui, Roboto, Helvetica, Arial,
    sans-serif, apple color emoji, segoe ui emoji, segoe ui symbol;
  -webkit-font-smoothing: antialiased;
}

.messageDoc h5 {
  font-size: 12px;
  font-family: -apple-system, BlinkMacSystemFont, segoe ui, Roboto, Helvetica, Arial,
    sans-serif, apple color emoji, segoe ui emoji, segoe ui symbol;
  -webkit-font-smoothing: antialiased;
}

.messageDoc p {
  margin-bottom: 0px;
  font-family: -apple-system, BlinkMacSystemFont, segoe ui, Roboto, Helvetica, Arial,
    sans-serif, apple color emoji, segoe ui emoji, segoe ui symbol;
  -webkit-font-smoothing: antialiased;
}

.timeD {
  font-size: 6px;
  margin-left: 5px;
}

.timeT {
  font-size: 9px;
  margin-left: 5px;
  color: #16008f;
  font-family: 'Open Sans', sans-serif;
}

.image {
  width: 32px;
  height: 32px;
  min-width: 25px;
  min-height: 25px;
  border-radius: 50%;
  margin: auto 10px;
  cursor: pointer;
}

.changeColorToBlue,
.changeColorTowhite,
.changeColorToViolet,
.changeColorToGrey {
  margin-bottom: 4px;
  font-family: -apple-system, BlinkMacSystemFont, segoe ui, Roboto, Helvetica, Arial,
    sans-serif, apple color emoji, segoe ui emoji, segoe ui symbol;
  -webkit-font-smoothing: antialiased;
}

.changeColorToBlue>h5,
.changeColorTowhite>h5,
.changeColorToViolet>h5,
.changeColorToGrey>h5 {
  font-size: 15px;
  /*font-family: 'Roboto', sans-serif; font-weight: 400;*/
  font-family: -apple-system, BlinkMacSystemFont, segoe ui, Roboto, Helvetica, Arial,
    sans-serif, apple color emoji, segoe ui emoji, segoe ui symbol;
  font-weight: bold;
  -webkit-font-smoothing: antialiased;
  margin-bottom: 1px;
}

.message>.changeColorToBlue>p,
.changeColorTowhite>p,
.changeColorToViolet>p,
.changeColorToGrey>p {
  font-size: 14px;
  font-family: -apple-system, BlinkMacSystemFont, segoe ui, Roboto, Helvetica, Arial,
    sans-serif, apple color emoji, segoe ui emoji, segoe ui symbol;
  -webkit-font-smoothing: antialiased;
  text-align: left !important;
  line-height: 1.3;
  -ms-word-break: break-all;
  word-break: break-all;
  word-break: break-word;
  /* Sólo WebKit -NO DOCUMENTADO */
  -ms-hyphens: auto;
  /* Guiones para separar en sílabas */
  -moz-hyphens: auto;
  /*  depende de lang en <html>      */
  -webkit-hyphens: auto;
  hyphens: auto;
  padding-bottom: 3px;
}

.changeColorToBlue>span,
.changeColorTowhite>span,
.changeColorToViolet>span,
.changeColorToGrey>span {
  font-size: 14px;
  font-family: -apple-system, BlinkMacSystemFont, segoe ui, Roboto, Helvetica, Arial,
    sans-serif, apple color emoji, segoe ui emoji, segoe ui symbol;
  -webkit-font-smoothing: antialiased;
}

.changeColorToGrey {
  color: grey;
}

.changeColorToBlue {
  color: #16008f;
}

.changeColorTowhite {
  color: white;
}

.changeColorToViolet {
  color: #e305eb;
  /*nuevo color*/
}

.visitorSelect,
.newMessage,
.newVisitor,
.unselected-conversation {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  margin-right: 0px;
}

.visitorSelect {
  /*background-color: #F605FF;*/
  background-color: rgba(227, 16, 240, 0.2);
}

.newVisitor {
  background-color: #53d34f;
}

@keyframes nuevomensaje {
  0% {
    background-color: rgba(0, 238, 255, 0.25);
  }

  25% {
    background-color: #99b898;
  }

  50% {
    background-color: rgba(0, 238, 255, 0.25);
  }

  75% {
    background-color: #99b898;
  }

  100% {
    background-color: rgba(0, 238, 255, 0.25);
  }
}

.newMessage {
  animation-name: nuevomensaje;
  animation-duration: 2s;
  animation-iteration-count: infinite;
}

.nameVisitor {
  font-size: 15px;
  margin-left: 10px;
  margin-right: 12px;
  font-family: 'Open Sans', sans-serif;
  font-weight: bold;
  color: #000;
}

@media screen and (max-width: 1100px) {
  .nameVisitor {
    color: #fff;
  }
}

@media screen and (max-width: 500px) {
  .nameVisitor {
    color: #fff;
  }
}

.VisitorsInit {
  margin-top: 5px;
  display: grid;
  grid-template-columns: 12px auto;
  grid-gap: 5px;
  align-items: center;
  cursor: pointer;
  z-index: 99;
}

.unselected-conversation {
  background-color: rgba(83, 39, 187, 0.329);
}

.typingMsg {
  grid-column: 2;
  /*justify-self: center;*/
  padding-left: 20px;
  margin-bottom: 0px;
  margin-top: -10px;
  box-sizing: border-box;
  z-index: 99;
  font-family: 'Open Sans', sans-serif;
}

.p_typingMsg {
  padding-left: 20px;
  margin-bottom: 0px;
  line-height: 3;
  font-size: 14px;
  color: white;
  font-family: 'Open Sans', sans-serif;
}

.p_typingMsg>img {
  width: 15px;
  height: auto;
  margin-left: 3px;
  vertical-align: middle;
}

.TypingDiv {
  display: grid;
  grid-template-columns: auto auto;
  grid-template-rows: auto;
  box-sizing: border-box;
}

.div-typing {
  scrollbar-width: none;
  margin-bottom: 15px;
  margin-top: -20px;
  height: 45px;
}

@media screen and (max-width: 500px) {
  .div-typing {
    display: flex;
    flex-flow: column;
    align-items: center;
  }
}

.notification {
  background-color: rgba(3, 0, 252, 0.2);
  height: auto;
  max-width: 60%;
  padding: 5px;
  grid-column: 2;
  margin-left: 20px;
  box-sizing: border-box;
  visibility: block;
  margin-top: 5px;
  transition: visibility 3s;
}

.notification>p {
  font-size: 12px;
  color: white;
  margin-bottom: 0px;
  padding-left: 5px;
  font-family: 'Open Sans', sans-serif;
}

.notification p>a {
  margin: 0px;
  padding: 0px;
  display: inline-block;
}

.notification p>a>img {
  width: 18px;
  height: auto;
  vertical-align: middle;
}

.whiteboard-public-textinput {
  background-color: transparent;
  height: 100%;
  width: 80%;
  color: white;
  font-size: 15px;
  border: none;
  padding-left: 5px;
  outline: none;
  margin-left: 5px;
  font-family: -apple-system, BlinkMacSystemFont, segoe ui, Roboto, Helvetica, Arial,
    sans-serif, apple color emoji, segoe ui emoji, segoe ui symbol;
  /*font-weight: bold;*/
  -webkit-font-smoothing: antialiased;
}

.whiteboard-textinput-clasico {
  background-color: transparent;
  height: 90%;
  width: 80%;
  color: grey;
  font-size: 12px;
  border: none;
  padding-left: 5px;
  outline: none;
  margin-left: 5px;
  font-family: -apple-system, BlinkMacSystemFont, segoe ui, Roboto, Helvetica, Arial,
    sans-serif, apple color emoji, segoe ui emoji, segoe ui symbol;
  font-weight: bold;
  -webkit-font-smoothing: antialiased;
  font-weight: bold;
}
.whiteboard-exterior {
  height: 29px;
  width: 100%;
  border: 1px solid white;
  border-radius: 35px;
  display: flex;
  justify-content: space-between;
}


.whiteboard-textinput-clasico::placeholder {
  color: grey;
}

.whiteboard-exterior-clasico {
  height: 29px;
  width: 100%;
  border: 1px solid grey;
  border-radius: 35px;
  display: flex;
  justify-content: space-between;
}

.whiteboard-send-icon {
  margin-top: auto;
  margin-right: 0px;
  cursor: pointer;
}

.whiteboard-send-icon-message {
  margin-left: 15px !important;
  margin-top: auto;
  margin-right: 0px;
  animation: translate 0.6s ease-in;
  /*ease-in*/
}

@keyframes translate {
  0% {
    transform: translate(0px, 0px);
  }

  100% {
    transform: translate(150px, -70px);
  }
}

.icon-3px {
  padding-top: 5px;
}

.menssages {
  background-color: blue;
  width: 300px;
  height: 600px;
}

.iconLogin {
  margin: 3px;
  color: white;
  cursor: pointer;
  padding-bottom: 3px;
  text-align: center;
}

.red {
  width: 30px;
  height: 30px;
  background-color: red;
  position: absolute;
  top: 10px;
}

.chagendata-w {
  background-color: rgba(0, 102, 255, 0.2);
  height: 45px;
  min-width: 100%;
  margin-left: 0px;
  display: grid;
  align-items: center;
  grid-template-rows: repeat(3, 1fr);
}

.item {
  margin: auto;
}

.unploadimage {
  margin: auto;
}

.gbandtext {
  margin: auto;
  vertical-align: middle;
}

.AcceptDelete {
  background-color: #ea21e056;
  border-radius: 10px;
  padding: 10px;
  display: flex;
  position: absolute;
  z-index: 5;
  bottom: 110px;
  backdrop-filter: blur(2px);
  right: 0;
  width: 100%;
  margin: 0 auto;
  align-items: center;
  flex-flow: column;
  justify-content: space-between;
}

.accept-delete-title {
  color: #fff;
  font-size: 1.2rem;
  margin-bottom: 0;
}

.accept-delete-button {
  color: #fff;
  margin-bottom: 0;
  font-size: 1rem;
}

.accept-delete-button:hover {
  cursor: pointer;
}

.accept-buttons {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-top: 10px;
}

.gbandtext:active {
  height: 43px;
}

.divclose {
  cursor: pointer;
  color: #fff;
  font-family: 'Nunito', sans-serif;
  position: absolute;
  top: -3px;
  right: 2px;
  font-size: 10px;
  font-weight: bold;
}

.MessageComp {
  width: 100px;
}

.bellNot {
  display: grid;
  width: 100%;
}

.div-typing>img {
  width: 45px;
  margin-left: 140px;
}

label img {
  width: 25px;
  cursor: pointer;
}

[type='button']:not(:disabled),
[type='reset']:not(:disabled),
[type='submit']:not(:disabled),
button:not(:disabled) {
  cursor: pointer;
  right: -170px;
}

.bg {
  background-color: royalblue;
  width: 35px;
  height: 35px;
  border-radius: 30px;
  margin: auto;
  cursor: pointer;
}

.bg:hover {
  background-color: royalblue;
  border-radius: 30px;
  transition: width 1s;
  margin: auto;
  cursor: pointer;
}

.gbandtext img {
  height: auto;
  width: auto;
  cursor: pointer;
  /*background-image: url('./chat-assets/chat-icons/cammera-icon-smaller.svg');*/
}

.gbandtext img:hover {
  transition: width 1s;
  cursor: pointer;
}

.usericon {
  display: grid;
  align-items: center;
  justify-content: center;
}

.usericon img {
  height: 25px;
  width: auto;
  cursor: pointer;
}

.usericon img:hover {
  transition: width 1s;
  cursor: pointer;
}

.loading-img {
  z-index: 5;
}

.loading {
  width: 100%;
  margin: 0px auto;
  transition: height 3s;
  height: 1px;
}

.container-messages-wp {
  height: 100%;
  position: relative;
  width: 100%;
  overflow-y: scroll;
  overflow-x: hidden !important;
  padding-bottom: -20px;
  scrollbar-color: rgba(0, 0, 0, .5) rgba(0, 0, 0, 0);
  scrollbar-width: thin;

}

.sctopmessage {
  position: relative;
  width: 40%;
  height: 200px;
}

/*PC */

@media (min-width: 1280px) {

  .whiteboard-chat-public-container-wp,
  .whiteboard-chat-public-clasico-container-wp {
    width: 25%;
  }

  .whiteboard-chat-public-container-wp,
  .whiteboard-chat-public-clasico-container-wp {

    margin-right: 120px;
  }

  .wp-chat-conten-3 {
    bottom: 75px;
    right: 0;
    width: 25%;
    height: 8%;
    z-index: 100;
    position: fixed;
    margin-right: 120px;
  }

}

/*latop */

@media (min-width: 1024px) and (max-width: 1280px) {

  .whiteboard-chat-public-container-wp,
  .whiteboard-chat-public-clasico-container-wp {
    width: 30%;
  }

  .wp-chat-conten-3 {
    bottom: 75px;
    right: 0;
    width: 30%;
    height: 8%;
    z-index: 100;
    position: fixed;
    margin-right: 120px;
  }

}

/* tablet portart*/

@media (min-width: 481px) and (max-width: 1024px) {

  .whiteboard-chat-public-container-wp,
  .whiteboard-chat-public-clasico-container-wp {
    width: 40%;
    margin-bottom: 100px;
  }

  .wp-chat-conten-3 {
    bottom: 75px;
    right: 0;
    width: 40%;
    height: 8%;
    z-index: 100;
    position: fixed;
    margin-right: 88px;
  }
}

/* tablet landscape*/

@media (min-width: 481px) and (max-width: 1024px) and (orientation: landscape) {

  .whiteboard-chat-public-container-wp,
  .whiteboard-chat-public-clasico-container-wp {
    width: 40%;
    margin-bottom: 100px;
  }

  .wp-chat-conten-3 {
    bottom: 70px;
    right: 100px;
    width: 40%;
    height: 6%;
    z-index: 100;
    position: fixed;
    margin-right: 8px;
  }

}

/*movil mediano  */

@media screen and (min-width: 320px) and (max-width: 480px) {

  .whiteboard-chat-public-container-wp,
  .whiteboard-chat-public-clasico-container-wp {
    width: 103%;
    height: 90%;
    left: 52%;
    transform: translate(-50%, -50%);
    z-index: 1000;
    backdrop-filter: blur(10px);
  }

  .changeColorToBlue>h5,
  .changeColorTowhite>h5,
  .changeColorToViolet>h5,
  .changeColorToGrey>h5 {
    font-size: 18px;
  }

  .message>.changeColorToBlue>p,
  .changeColorTowhite>p,
  .changeColorToViolet>p,
  .changeColorToGrey>p {
    font-size: 14px;
    width: 100%;
  }

  .whiteboard-chat-public-clasico-container-wp {
    left: 52%;
  }

  .sctopmessage {
    display: none;
  }

  .close-chat-btn {
    position: absolute;
    width: 25px;
    top: 25px;
    margin-left: 80%;
    background: transparent;
    border: none;
    cursor: pointer;
    color: #270fc5;
    z-index: 100;
    display: flex;
  }

  .close-chat-btn:hover {
    transform: scale(1.3);
  }

  .wp-chat-conten-2 {
    padding: 1%;
    width: 97%;
    height: 15%;
    margin-top: 27px;
    backdrop-filter: blur(10px);
    z-index: 100000;
  }

  .wp-chat-conten-3 {
    bottom: 4px;
    padding: 10px;
    right: 0;
    width: 100%;
    height: 11%;
    z-index: 1000;
    position: fixed;
    margin-right: 0%;
    backdrop-filter: blur(10px);
  }

  .messages-wp {
    position: fixed;
    margin: 0%;
    padding: 1%;
    top: -6%;
    left: 0%;
    width: 100%;
    height: 85%;
    max-height: unset;
    min-height: 450px;
    backdrop-filter: blur(10px);
    border-radius: 0px;
    opacity: 1;
    visibility: visible;
    transform: translateX(0);
    -webkit-mask-image: linear-gradient(0deg,
        rgba(0, 0, 0, 1) 0%,
        rgba(0, 0, 0, 1) 60%,
        rgba(0, 0, 0, 0) 80%);
    mask-image: linear-gradient(0deg,
        rgba(0, 0, 0, 1) 0%,
        rgba(0, 0, 0, 1) 60%,
        rgba(0, 0, 0, 0) 80%);
  }

  .whiteboard-exterior {
    height: 40px;

  }

  .message-privat {
    max-width: 75%;
  }

  .message {
    width: 100%;
  }

  .whiteboard-chat-public-container-wp {
    top: 44%;
  }

  .whiteboard-chat-public-clasico-container-wp {
    top: 45%;
  }
}