.deleteListFileExam__contianer {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 0 !important;
}

.deleteListFileExam__contianer p {
    color: rgb(147, 238, 10);
    font-size: 1.2em;

}

.deleteListFileExam__optionDelete {
    display: flex;
    gap: 3em !important;
}

.deleteListFileExam__optionDelete p {
    font-size: 1.3em;
    cursor: pointer;
}