.seccion-header{
    min-height: 345px;
    background-color: #57d0f5;
}

.imgHeader{
    background-image: url(../../assets/img/imgprueba.jpeg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
}

.seccion-header button{
    background-color: #fbff15;
    width: 150px;
    border-radius: 10px;
    margin-bottom: 10px;
}

.seccionTacometro {
    height: 365px;
    background-color: #57d0f5;
    display: flex;
    justify-content: center;
}

.seccion-redes{
    display: flex;
    justify-content: center;
}

.imageRedes {
    width: 40px;
}

.seccion-con{
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    padding: 15px 0;
    
    background-color: #fff;
}

.container-inputs input{
 height: 35px;
 font-size: larger;
}

.tomar-exm{
 font-size: medium;
 height: 35px;
}
