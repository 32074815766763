.containerHeaderEditMode {
    display: grid;
    grid-template-columns: 1fr auto;
    width: 100%;
}

.headerLeft {
    display: grid;
    grid-template-columns: 180px 1fr;
    align-items: center;
}

.editToolsAndData {
    display: grid;
    align-items: center;
    grid-template-columns: auto auto 1fr;
    gap: 15px;
}

.numberPage{
    flex: none;
    color: blue;
    margin-bottom: 0;
    font-size: 25px;
}

.user {
    font-size: 16px;
    color: blue;
    display: flex;
    margin: unset;

    @media screen and (max-width: 500px) {
        position: fixed;
        top: 0;
        left: 10px;
    }
}

.backIcon {
    width: 25px;
    cursor: pointer;
}

@media screen and (max-width: 500px) {
    .liveIcon {
        position: fixed;
        top: 0;
        right: 10px;
    }
}