.container{
	width: 100%;
	height: 100%;
	display: grid;
	grid-template-rows: auto 1fr;
	overflow: auto;
	gap: 5vw;
	padding: 10px 0;
	
	.containerLevels {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 5rem;
	}

	.backContainer{
		width: 100%;
		padding: 0 20px;
		cursor: pointer;
	}
	
	.titleContainer{
		width: 100%;
		display: flex;
		justify-content: center;

		& > p{
			font-size: 23px;
			color: blue;
			font-weight: bold;
			text-align: center;
			
			@media screen and (max-width: 700px) {
				& {
					font-size: 25px;
				}
			}

			@media screen and (max-width: 600px) {
				& {
					font-size: 20px;
					color: blue;
				}
			}
		}

		@media screen and (max-width: 600px) {
			& {
				width: 90%;
			}
		}
	}

	.pageContainer{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 6rem;
	
		.levelsContainer{
			width: 60%;
			display: flex;
			flex-direction: row;
			flex-wrap: wrap;
			align-items: center;
			justify-content: space-around;
			gap: 15px;
			
			@media screen and (max-width: 600px) {
				width: 80%; /* Cambio del ancho al 80% */
			  }
	
			.levelBox{
				display: flex;
				flex-direction: column;
				align-items: center;
				justify-content: space-between;
				transition: all 500ms ease;
				margin: 0 20px;

				margin-bottom: 20px;

				&:hover{
					cursor: pointer;
					transform: scale(1.2);
					transition: all 500ms ease;
				}
	
				.level{
					background-color: rgb(0, 0, 255, 0.2);
					width: 50px;
					height: 50px;
					border-radius: 50%;
					display: flex;
					flex-direction: column;
					align-items: center;
					justify-content: center;
					
					& > p{
						color: white;
						font-size: 27.5px;
						margin-bottom: 0;
						font-weight: bold;
					}

					& > span{
						color: white;
						font-size: 22.5px;
						margin-bottom: 0;
						font-weight: bold;
					}
				}
	
				& > p{
					color: blue;
					margin-bottom: 0;
					font-size: 16px;
					font-weight: bold;
					margin-top: 10px;
				}
			}
	
		}
	}

	.message{
		width: 100%;
		display: flex;
		flex-direction: row;
		justify-content: flex-end;
		margin-right: 25px;
		color: blue;

		&>p {
			max-width: 300px;
			font-size: 14px;

			@media screen and (max-width: 600px) {
				& {
					max-width: 100%;
					width: 90%;
					text-align: center;
					color: blue;
				}
			}
		}

		@media screen and (max-width: 600px) {
			& {
				justify-content: center;
				margin-right: 0;
			}
		}
	}
}
