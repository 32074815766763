/*styles para los cambios de modo del canvas*/
.whiteBoard-container {
  touch-action: none;
}

.modern-model {
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: flex-start;
}

.clasic-model {
  width: 100vw;
  height: 100vh;
  position: relative;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: flex-start;
  overflow: hidden;
}

.filter {
  position: absolute;
  top: 5%;
  left: 5%;
  width: 90%;
  height: 90%;
  z-index: -1;
}

.filterBlur1 {
  backdrop-filter: blur(5px);
}
.filterBlur2 {
  backdrop-filter: blur(10px);
}
.filterBlur3 {
  backdrop-filter: blur(20px);
}

.modern-model__toolbar {
  width: 40px;
  height: 92%;
}

.modern-model__canvas {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0px;
  left: 0px;
}

.modern-model__bottombar {
  width: 100%;
  position: absolute;
  bottom: 0px;
  z-index: 1;
}

.clasic-model__canvas {
  height: auto;
  width: 100%;
  position: relative;
  top: 0px;
  left: 0px;
}

.clasic-model__bottombar {
  width: 100%;
  height: 6%;
  padding-bottom: 3px;
}

.clasic-model__toolbar {
  width: 2%;
  height: 92%;
  padding-top: 10px;
}

/*changer model clasic or modern*/

.app-model {
  width: 100%;
  height: 100%;
  background: url('../../assets/background/desktop.jpeg') no-repeat fixed;
  background-size: cover;
  position: fixed;
  font-family: 'Open Sans', sans-serif !important;
  display: flex;
  justify-content: center;
  align-items: flex-start;
}

@media screen and (max-width: 750px) {
  .app-model {
    justify-content: center;
    align-items: center;
  }
}

.app-model__crystal-wp {
  top: 5%;
  left: 5%;
  width: 90%;
  height: 90%;
  padding: 0px;
  background-color: rgba(0, 0, 0, 0.1);
  transition: background-color 1s;
  border: 1px solid #fff;
  box-shadow: 0 0 20px 10px rgba(255, 255, 255, 0.55) inset;
  overflow: hidden;
}

/*styles del contenedor chat en pizarra*/

.showChat {
  display: block;
}

.hiddenChat {
  display: none;
}

@media (max-width: 576px) {
  .app-model__crystal-wp {
    width: 100%;
    left: 0%;
  }
}
@media screen and (min-width: 1024px) {
  .app-model__crystal-wp {
    position: absolute;
    top: 5%;
    left: 5%;
    width: 90%;
    height: 90%;
    box-sizing: border-box;
    background-color: rgba(0, 0, 0, 0.1);
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .app-model__crystal-wp {
    position: absolute;
    top: 5%;
    left: 5%;
    width: 90%;
    height: 90%;
    box-sizing: border-box;
    background-color: rgba(0, 0, 0, 0.1);
  }
}

@media (min-width: 481px) and (max-width: 1024px) and (orientation: landscape) {
  .app-model__crystal-wp {
    position: absolute;
    top: 5%;
    left: 5%;
    width: 90%;
    height: 90%;
    box-sizing: border-box;
    background-color: rgba(0, 0, 0, 0.1);
  }
}

@media screen and (min-width: 320px) and (max-width: 480px) {
  .app-model__crystal-wp {
    position: absolute;
    top: 5%;
    left: 5%;
    width: 90%;
    height: 87%;
    background-color: rgba(0, 0, 0, 0.1);
  }

  .clasic-model__canvas {
    width: 100%;
    height: 100%;
  }
  .showChat {
    width: 90%;
  }
}



