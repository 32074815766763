.results__header {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.header-labels {
  text-align: center;
  margin-bottom: 20px;

  @media screen and (max-width: 750px) {
    margin-bottom: 0px;
  }
}

.results__score {
  font-size: 2em;
  font-weight: bold;
}

.tachometer-replacement {
  width: 100%;
  display: flex;
  justify-content: center;
}

.tachometer {
  width: 100%;
  display: flex;
  justify-content: center;
}

.results {
  flex-direction: row;

  .loading-container {
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .text-color-blue {
    color: blue;
    margin: 0;
  }

  .tag {
    display: flex;
    flex-direction: row;
    margin-bottom: 5px;
    justify-content: flex-start;

    &:nth-child(4) {
      @media screen and (max-width: 750px) {
        & {
          height: 24px;
        }
      }
    }

    .stroke {
      margin-right: 5px;
      font-size: 18px !important;

      @media screen and (max-width: 1100px) {
        & {
          font-size: 25px !important;
        }
      }

      @media screen and (max-width: 1100px) {
        & {
          font-size: 20px !important;
        }
      }

      @media screen and (max-width: 750px) {
        & {
          font-size: 16px !important;
        }
      }
    }

    @media screen and (max-width: 750px) {
      & {
        margin-bottom: 10px;
      }
    }

    @media screen and (max-height: 700px) {
      & {
        max-height: 20px;
      }
    }

    @media screen and (max-height: 600px) {
      & {
        max-height: 20px;
      }
    }
  }

  .text-color-blue-font-reduced {
    margin: 0;
    font-size: 18px !important;

    @media screen and (max-width: 1100px) {
      & {
        font-size: 25px !important;
      }
    }

    @media screen and (max-width: 1100px) {
      & {
        font-size: 20px !important;
      }
    }

    @media screen and (max-width: 800px) {
      & {
        font-size: 18px !important;
      }
    }

    @media screen and (max-width: 750px) {
      & {
        font-size: 20px !important;
      }
    }

    @media screen and (max-width: 600px) {
      & {
        font-size: 17.5px !important;
      }
    }
  }

  &__levels {
    td {
      padding: 0 10px;
    }
  }

  .__standard {
    margin-bottom: 20px;
    margin-top: 5px;
    width: 74.2% !important;
    background-color: rgb(162, 231, 255);

    @media screen and (max-width: 1180px) {
      & {
        width: 80% !important;
      }
    }

    @media screen and (max-width: 750px) {
      & {
        width: 90% !important;
      }
    }
  }

  .__green {
    margin-bottom: 20px;
    margin-top: 5px;
    width: 74.2% !important;
    background-color: #baff5c99;

    @media screen and (max-width: 1180px) {
      & {
        width: 80% !important;
      }
    }

    @media screen and (max-width: 750px) {
      & {
        width: 90% !important;
      }
    }
  }

  .__blue {
    margin-bottom: 20px;
    margin-top: 5px;
    width: 74.2% !important;
    background-color: #d2eaff;

    @media screen and (max-width: 1180px) {
      & {
        width: 80% !important;
      }
    }

    @media screen and (max-width: 750px) {
      & {
        width: 90% !important;
      }
    }
  }

  .__yellow {
    margin-bottom: 20px;
    margin-top: 5px;
    width: 74.2% !important;
    background-color: rgb(239, 255, 115);

    @media screen and (max-width: 1180px) {
      & {
        width: 80% !important;
      }
    }

    @media screen and (max-width: 750px) {
      & {
        width: 90% !important;
      }
    }
  }

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin: 5px 0;
    margin-top: 0;
    align-self: center;

    @media screen and (max-width: 1180px) {
      & {
        width: 91%;
      }
    }

    @media screen and (max-width: 750px) {
      & {
        width: 100%;
      }
    }

    .tachometer-replacement {
      width: 100%;
      height: 35%;

      @media screen and (max-width: 750px) {
        & {
          display: flex;
          align-items: flex-start;
          height: 80px;
        }
      }

      @media screen and (max-height: 600px) {
        & {
          height: 70px;
        }
      }

      @media screen and (max-height: 550px) {
        & {
          height: 65px;
        }
      }
    }

    .tachometer {
      position: absolute;

      @media (min-width: 1700px) {}

      @media (max-width: 750px) {
        transform: scale(0.9);
        position: absolute;
        top: -2em;
      }
    }

    .header-labels {
      width: 66%;
      display: flex;
      margin-top: 9em;
      flex-direction: row;
      align-items: center;
      justify-content: space-around;

      @media (max-width: 750px) {
        margin-top: 0em;
      }

      @media (min-width: 1500px) {
        margin-top: 10em;
      }

      @media (min-width: 1600px) {
        margin-top: 12em;
      }

      @media (min-width: 1700px) {
        margin-top: 15em;
      }

      @media screen and (max-width: 750px) {
        & {
          width: 68%;
        }
      }

      @media screen and (max-width: 600px) {
        & {
          width: 66%;
        }
      }

      &>h1 {
        font-size: 40px;
        color: blue;
        margin: 0;

        @media screen and (max-width: 1100px) {
          & {
            font-size: 30px;
          }
        }

        @media screen and (max-width: 800px) {
          & {
            font-size: 30px;
          }
        }

        @media screen and (max-width: 600px) {
          & {
            font-size: 28px;
          }
        }
      }

      .results__score {
        font-size: 40px;
        color: #b8b8b851;
        display: flex;
        align-items: center;
        justify-content: center;

        &>p {
          color: blue;
          margin-bottom: 0;
        }

        @media screen and (max-width: 1100px) {
          & {
            font-size: 30px;
          }
        }

        @media screen and (max-width: 800px) {
          & {
            font-size: 30px;
          }
        }

        @media screen and (max-width: 600px) {
          & {
            font-size: 28px;
          }
        }
      }
    }
  }

  &__feedback {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: space-around;
    height: 70vh;

    @media screen and (max-width: 750px) {
      padding: 0 10px;
      padding-top: 5px;
      height: 82vh;
    }

    .suggestions {
      min-height: 37vh !important;

      @media screen and (max-height: 700px) {
        & {
          min-height: 30vh !important;
        }
      }

      @media screen and (max-height: 600px) {
        & {
          min-height: 35vh !important;
        }
      }
    }

    .sections {
      &__null {
        background-color: #fff;

        min-height: 17vh;
        padding: 1px 20px;
        border: 1px solid #000;
        margin-bottom: 10px;

        &>p {
          font-size: 17px;
          display: flex;
          flex-direction: row;
          align-items: center;
          width: fit-content;

          @media screen and (max-width: 500px) {
            & {
              width: 100%;
            }
          }

          &>strong {
            margin-right: 20px;
          }
        }

        @media screen and (max-width: 1180px) {
          & {
            width: 90%;
          }
        }

        @media screen and (max-width: 1000px) {
          & {
            width: 93%;
          }
        }

        @media screen and (max-width: 800px) {
          & {
            width: 95%;
          }
        }

        @media screen and (max-width: 750px) {
          & {
            margin-top: -3em;
            width: 100%;
            margin-bottom: 0px;
            padding: 5px 10px;
          }
        }
      }

      &__green {
        background-color: #8aff0091;

        min-height: 18vh;
        height: fit-content;
        padding: 5px 20px;
        margin-bottom: 20px;

        &>p {
          font-size: 17px;
          display: flex;
          flex-direction: row;
          align-items: center;
          width: fit-content;

          @media screen and (max-width: 500px) {
            & {
              width: 100%;
              font-size: 14px;
            }
          }

          &>strong {
            margin-right: 20px;
          }
        }

        @media screen and (max-width: 1180px) {
          & {
            width: 90%;
          }
        }

        @media screen and (max-width: 1000px) {
          & {
            width: 93%;
          }
        }

        @media screen and (max-width: 800px) {
          & {
            width: 95%;
          }
        }

        @media screen and (max-width: 750px) {
          & {
            width: 100%;
            min-height: 21vh;
            margin-bottom: 0px;
            padding: 5px 10px;
          }
        }
      }

      &__blue {
        background-color: #d2eaff;

        min-height: 18vh;
        padding: 5px 20px;
        margin-bottom: 20px;

        &>p {
          font-size: 17px;
          display: flex;
          flex-direction: row;
          align-items: center;
          width: fit-content;

          @media screen and (max-width: 500px) {
            & {
              width: 100%;
            }
          }

          &>strong {
            margin-right: 20px;
          }
        }

        @media screen and (max-width: 1180px) {
          & {
            width: 90%;
          }
        }

        @media screen and (max-width: 1000px) {
          & {
            width: 93%;
          }
        }

        @media screen and (max-width: 800px) {
          & {
            width: 95%;
          }
        }

        @media screen and (max-width: 750px) {
          & {
            width: 100%;
            min-height: 21vh;
            margin-bottom: 0px;
            padding: 5px 10px;
          }
        }
      }

      &__yellow {
        background-color: rgb(239, 255, 115);

        min-height: 18vh;
        padding: 5px 20px;
        margin-bottom: 20px;

        &>p {
          font-size: 17px;
          display: flex;
          flex-direction: row;
          align-items: center;
          width: fit-content;

          @media screen and (max-width: 500px) {
            & {
              width: 100%;
            }
          }

          &>strong {
            margin-right: 20px;
          }
        }

        @media screen and (max-width: 1180px) {
          & {
            width: 90%;
          }
        }

        @media screen and (max-width: 1000px) {
          & {
            width: 93%;
          }
        }

        @media screen and (max-width: 800px) {
          & {
            width: 95%;
          }
        }

        @media screen and (max-width: 750px) {
          & {
            width: 100%;
            min-height: 21vh;
            margin-bottom: 0px;
            padding: 5px 10px;
          }
        }
      }

      &__standard {
        background-color: rgb(162, 231, 255);

        min-height: 18vh;
        padding: 5px 10px;
        margin-bottom: 20px;

        &>p {
          font-size: 17px;
          display: flex;
          flex-direction: row;
          align-items: center;
          width: fit-content;

          @media screen and (max-width: 500px) {
            & {
              width: 100%;
            }
          }

          &>strong {
            margin-right: 20px;
          }
        }

        @media screen and (max-width: 1180px) {
          & {
            width: 90%;
          }
        }

        @media screen and (max-width: 1000px) {
          & {
            width: 93%;
          }
        }

        @media screen and (max-width: 800px) {
          & {
            width: 95%;
          }
        }

        @media screen and (max-width: 750px) {
          & {
            width: 100%;
            min-height: 21vh;
            margin-bottom: 0px;
            padding: 5px 10px;
          }
        }
      }
    }
  }

  .social-feedback-title {
    font-size: 24px;
    font-weight: bold;
    color: blue;

    @media screen and (max-width: 750px) {
      margin-top: -4em;
    }
  }

  &__social {
    width: 40%;
    min-height: 12vh;
    display: flex;
    flex-direction: column;
    align-items: center;

    @media screen and (max-width: 500px) {
      & {
        min-height: 5vh;
      }
    }

    &__container {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-around;

      @media screen and (max-width: 750px) {
        margin-top: -2.5em;
      }
    }

    .ws {
      margin-top: -15px;
      width: 110px;
      height: 110px;

      @media screen and (max-width: 500px) {
        & {
          width: 48px;
          height: 48px;
        }
      }
    }

    .pn {
      width: 45px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .icon {
        width: 45px;
        height: 45px;

        @media screen and (max-width: 500px) {
          & {
            width: 35px;
            height: 35px;
          }
        }
      }
    }

    .social-icon {
      width: 80px;
      height: 80px;

      @media screen and (max-width: 800px) {
        & {
          width: 50px;
          height: 50px;
        }
      }

      @media screen and (max-width: 500px) {
        & {
          width: 40px;
          height: 40px;
        }
      }
    }

    @media screen and (max-width: 1000px) {
      & {
        width: 60%;
      }
    }

    @media screen and (max-width: 750px) {
      & {
        margin-top: -3em;
        width: 90%;
      }
    }
  }

  .twinkleText {
    animation-name: twinkle;
    animation-duration: 2s;
    animation-timing-function: linear;
    animation-iteration-count: infinite;

    -webkit-animation-name: twinkle;
    -webkit-animation-duration: s;
    -webkit-animation-timing-function: linear;
    -webkit-animation-iteration-count: infinite;
  }

  @-moz-keyframes twinkle {
    0% {
      opacity: 1;
    }

    50% {
      opacity: 0.3;
    }

    100% {
      opacity: 1;
    }
  }

  @-webkit-keyframes twinkle {
    0% {
      opacity: 1;
    }

    50% {
      opacity: 0.3;
    }

    100% {
      opacity: 1;
    }
  }

  @keyframes twinkle {
    0% {
      opacity: 1;
    }

    50% {
      opacity: 0.3;
    }

    100% {
      opacity: 1;
    }
  }
}