.container1 {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    min-height: 400px;
    width: 100%;
    font-weight: bold;
    padding: 20px;
  }

  @media (min-width: 768px) {
    .container1 {
      flex-direction: row;
    }
  }

  .item {
    text-align: center;
    display: flex;
    justify-content: center;
    flex-direction: column;
  }
  
  .item p {
    font-size: 25px;
  }

  .image-container {
    margin: auto;
    width: 200px;
    height: 200px;
    border: 1px solid #000; /* Puedes ajustar el color del borde */
    border-radius: 50%;
    padding: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .image {
   
    margin: 0 auto;
  }


  /* styles.css */
.section2-container {
    min-height: 170px;
    background-color: #f3f4f6; /* bg-gray-100 */
    width: 100%;
    display: flex;
    flex-direction: column;
    font-weight: bold;
  }
  
  .text-section {
    background-color: #a5f3fc; /* bg-cyan-200 */
    padding: 24px; /* p-6 */
    display: flex;
    justify-content: center;
    text-align: center;
  }
  
  .text2-section {
    display: flex;
    align-items: center; /* items-center */
    justify-content: center; /* justify-center */
    gap: 40px; /* gap-10 */
    background-color: #fde047; /* bg-yellow-200 */
    padding: 20px; /* p-6 */
    min-height: 96px; /* h-24 */
    text-align: center;
  }

  @media (max-width: 700px) {
    .text2-section {
      
    }
  }
  
  .icon2 {
    width: 40px; /* w-10 */
  }
  

  /*seccion 3*/


  /* styles.css */
.section3-container {
    min-height: 400px; /* min-h-[400px] */
    padding: 28px; /* p-7 */
    display: grid; /* grid */
    grid-template-columns: repeat(2, 1fr); /* grid-cols-2 */
    justify-items: center; /* justify-items-center */
    width: 100%; /* w-full */
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1); /* shadow */
    border: 1px solid #d1d5db; /* border */
    gap: 36px; /* gap-9 */
    background: yellowgreen;
  }
  
  @media (min-width: 768px) {
    .section3-container {
      grid-template-columns: repeat(3, 1fr); /* md:grid-cols-3 */
    }
  }
  
  .feature-item {
    height: 40px; /* h-10 */
    width: 160px; /* w-40 */
    text-align: center;
  }
  
  .feature-icon {
    height: 80px; /* h-[80px] */
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .icon {
    width: 80px; /* w-20 */
  }


  /* testimonios*/

  .testimonios {
    display: flex;
    justify-items: center;
    flex-direction: column;
    
    
  }
  .testimonios1{
    display: flex;
    justify-items: center;
    flex-direction: column;
    height: 180px;
    
  }

  .testimonios img {
    width: 50px;
    margin-right: 20px;
    border: #000 1px solid;
    border-radius: 100%;
  }
  .testimonios1 img {
    width: 50px;
    margin-right: 20px;
    border: #000 1px solid;
    border-radius: 100%;
  }
  .vertestimonio {
    display: flex;
    justify-items: center;
    flex-direction: column;
  }
  
  .texttestimonio{
    height: 90px;
    overflow: hidden;
  }

  .container-card{
    display: grid;
    grid-template-columns: repeat(2, 1fr)
  }

  @media (max-width: 700px ) {
    .container-card {
      grid-template-columns: repeat(1, 1fr)

    }
  }




/* Footer container */
.footer-container {
  grid-row-start: 3;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #e5e7eb; /* bg-gray-200 */
  min-height: 400px;
  width: 100%;
}

/* Footer content */
.footer-content {
  display: flex;
  width: 100%;
  height: 100%;
  padding: 12px;
  justify-content: space-around;
  align-items: center;
  flex-direction: column; /* flex-col */
}

@media (min-width: 768px) {
  .footer-content {
    flex-direction: row; /* md:flex-row */
  }
}

/* Logo section */
.footer-logo {
  text-align: center;
  padding: 36px; /* p-9 */
  align-self: flex-start;
  font-weight: bold;
}

.logo-icon {
  width: 56px; /* w-14 */
  margin-bottom: 8px; /* mb-2 */
}

/* Footer sections */
.footer-sections {
  display: flex;
  justify-content: space-around;
  width: 75%; /* w-9/12 */
  padding: 24px; /* p-6 */
  border-top: 1px solid #d1d5db; /* border-t border-gray-300 */
  flex-direction: column;
  gap: 28px; /* gap-7 */
}

@media (min-width: 768px) {
  .footer-sections {
    flex-direction: row; /* md:flex-row */
  }
}

/* Section title */
.section-title {
  font-weight: bold;
  margin-bottom: 8px; /* mb-2 */
}

/* List styling */
.list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.list li {
  margin-bottom: 8px; /* space-y-2 */
}

/* Links */
.link {
  color: #3b82f6; /* text-blue-500 */
  text-decoration: none;
}

.link:hover {
  text-decoration: underline;
}

/* Social links */
.social-links {
  display: flex;
  gap: 16px; /* gap-4 */
  align-items: center;
  flex-direction: row; /* flex-row */
}

@media (min-width: 768px) {
  .social-links {
    flex-direction: column; /* md:flex-col */
  }
}

/* Social icons */
.social-icon {
  width: 40px; /* w-10 */
}


/* Container for the process section */
.process-container {
  width: 100%; /* w-full */
  min-height: 200px; /* min-h-[200px] */
  background-color: #22d3ee; /* bg-cyan-400 */
  text-align: center; /* text-center */
  color: #4b5563; /* text-gray-600 */
  padding: 20px; /* p-5 */
}

/* Title for the process section */
.process-title {
  font-size: 3rem; /* text-5xl */
  margin-bottom: 20px;
}

/* Container for steps */
.process-steps {
  width: 100%; /* w-full */
  display: flex;
  gap: 20px; /* gap-5 */
  justify-content: space-around; /* justify-between */
  padding: 24px; /* p-6 */
}

/* Each step item */
.step-item {
  text-align: center;
  font-size: x-large;
  transform: translateX(-1500px);
  transition: transform 1s ease-in-out, opacity 1s ease-out;
}

.step-item:hover {
  transform: scale(1.2);
}
.step-item-visible {
  transform: translateX(0);

}
/* Step icon */
.step-icon {
  width: 160px; /* w-40 */
  margin-bottom: 10px;
}


/*boton flotante de whatsapp*/
.wppFloat{
  position: fixed;
  bottom: 20%;
  right: 3%;
  /*animation: move 1s linear infinite;*/
}

/*@keyframes move {

  0% {
    transform: translateX(10px);
  }
  10% {
    transform: translateX(-10px);
  }
  20% {
    transform: translateX(10px);
  }
  30% {
    transform: translateX(-10px);
  }
  40% {
    transform: translateX(0);
  }
  50% {
    transform: translateX(-0);
  }
  60% {
    transform: translateX(0);
  }
  70% {
    transform: translateX(-0);
  }
  80% {
    transform: translateX(10px);
  }
  90% {
    transform: translateX(-10px);
  }
  100% {
    transform: translateX(10px);
  }
  
}*/