.evaluationsTable__container {
  width: 100%;
  height: 100%;
}

.evaluationsTable__ul {
  width: 100%;
  display: flex;
  color: white;
  padding: 0;
    
}

.evaluationsTable__ul li {
  display: block;
  width: 100%;
  height: 100%;
  align-items: center;
  text-align: center;
  font-size: 0.9em;
}

