.imputs{
    color: #fff;
    background-color: transparent;
    display: flex;
    justify-content: space-between;
    padding: 4px 0 4px 0;
    font-size: 14px;
}

.imputs:first-of-type{
    padding-top: 8px;
}