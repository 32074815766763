.exam-modal-overlay {
  background: transparent;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.exam-modal-content {
  background-color: #fff;
  width: 80%;
  height: 80%;
  border-radius: 8px;

  &.custom-background {
    /* Estilos cuando TbBackground ha sido clickeado */
    background-color: transparent; /* Cambia el color de fondo según tu necesidad */
  }

  @media (max-width:750px) {
    width: 98%;
    height: 98%;
  }

 
}

.exam-modal-content iframe {
  margin-top: -2.8em;
  width: 100%;
  height: 100%;
  border: none;

  @media (min-width: 1500px) {
    margin-top: -3em;
  }

  @media (min-width: 1600px) {
    margin-top: -3.2em;
  }
  @media (min-width: 1700px) {
    margin-top: -3.4em;
  }
}

.icon-container {
  position: relative;
  display: flex;
  justify-content: space-between; /* Esto distribuye los elementos a los extremos */
  align-items: center; /* Alinea los elementos verticalmente */
  width: 100%;
  padding-right: 6px;
  padding-left: 5px;
}

.icon {
  //margin-bottom: 1em; /* Añade margen entre los iconos */
  cursor: pointer;
}

.click-changeViewListFilesExam {
  padding-top: 0.1em;
  padding-left: 0.6em;
}
