html {
  /*overflow: hidden;*/
  width: 100%;
  height: 100%;
}

*::-webkit-scrollbar {
  display: none;
}

body {
  background: transparent;
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
  scrollbar-width: none;
  position: relative;
  font-family: 'Open Sans', sans-serif !important;
}

body::-webkit-scrollbar {
  scrollbar-width: none;
  display: none;
}


@-webkit-keyframes blink {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}


@keyframes blink {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.flicker {
  -webkit-animation: blink 1s infinite;
          animation: blink 1s infinite;
}