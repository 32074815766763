.bran-spinner {
  border: 2px solid black;
  border-right-color: transparent;
}

.bran-loading {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  .spinner-border {
    height: 50px;
    width: 50px;
  }
}
