.bottonContainerProfile {
  display: flex;
  position: relative;
  justify-content: center;
  height: 50%;
  min-height: 124px;;
  width: 300px;
}

.containerBottonContainerProfile{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.phoneticLab {
  display: flex;
  position: absolute;
  flex-direction: column;
  align-items: center;
  top: 5%;
  left: 15%;
  font-size: 15px;
  color: blue;
  z-index: 10;
  cursor: pointer;
}


.biling {
  display: flex;
  position: absolute;
  flex-direction: column;
  align-items: center;
  left: 48%;
  top:40%;
  font-size: 15px;
  color: blue;
  z-index: 10;
  cursor: pointer;
}


.smallCircle {
  position: relative;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: rgba(0, 0, 255, 0.2);
  // border: 8px solid rgba(255,255,255,0.3);
  box-shadow: 0 0 0 7px rgba(255, 255, 255, 0.3);
  margin-bottom: 5px;
}

@media screen and (max-width: 900px) {
  
}

@media screen and (max-width: 600px) {
  
  .bottonContainerProfile{
    width: 90vw;
    justify-content: space-between;
    min-height: 0;
  }
  
  .phoneticLab {
    position: unset;
    top: 0;
    left:0;
  }
  
  
  .biling {
    position: unset;
    left: 0;
    top:0;
  }
  
}
