.realTools {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  position: relative;
  z-index: 1000;
}

.buttons {
  margin: 0 5px;
  width: 35px;
  height: 35px;
  max-width: 35px;
  max-height: 35px;
  cursor: pointer;
  user-select: none;
  position: relative;
  @media screen and (max-width: 500px) {
    width: 28px;
  }
}

.buttonsMenu {
  width: max-content;
  position: absolute;
  top: calc(100% + 3px);
  left: 0;
  background: white;
  padding: 2px;
  max-height: 150px;
  overflow-y: auto;

  & ul {
    display: flex;
    flex-direction: column;
    padding: 0;
    margin: 0;
  }

  & li {
    display: flex;
    flex-direction: column;
    padding: 5px 15px;
    &:hover {
      background: #d7d7d7
    }
  }
}

.textControls {
  display: flex;
  justify-content: center;
  align-items: center;
  & > p {
    font-size: 9px;
  }
}

.testButton {
  width: 40px;
  height: 40px;
  border-radius: 7.5px;
  border: 3px solid #110adc;
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  justify-content: space-around;

  background-color: transparent;

  & > p {
    color: #110adc;
    font-weight: 700;
    margin-bottom: 0;
  }

  & > input {
    background-color: transparent;
    width: 37px;
    box-sizing: border-box;
    outline: none;
    border: 0;
    font-size: 9px;
    text-align: center;
    color: #110adc;
    font-weight: 700;

    &::placeholder {
      font-size: 11px;
      color: #110adc;
      font-weight: 700;
      text-align: center;
    }
  }

  @media screen and (max-width: 500px) {
    width: 28px;
    height: 28px;
    border: 2px solid rgb(35, 35, 255);
    border-radius: 5px;
  }
}

