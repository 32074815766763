@import 'assets/scss/main/_variables';


.shadow {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

}

.contentContainer {
  @extend %mode-crystal;
  padding: 10px 0;
  width: 800px;
  height: 500px;
  position: fixed;
  top: 0;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  border-radius: 10px;
  backdrop-filter: blur(2px);
  z-index: 1001;

  @media screen and (max-width: 750px) {
    & {
      width: 30em;

    }
  }
}

.contentContainerPurple {
  @extend %mode-crystal-purple;
  padding: 10px 0;
  width: 800px;
  height: 500px;
  position: fixed;
  top: 0;
  left: 50%;
  top: 50%;
  overflow-y: auto;
  transform: translate(-50%, -50%);
  border-radius: 10px;
  backdrop-filter: blur(2px);
  z-index: 1001;
}