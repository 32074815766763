.bar 
  position: relative
  width: 100%
  display: flex
  align-items: center
  background: rgba(255, 255, 255, 0.1)
  border-radius: 15px
  z-index: 1
  cursor: pointer

.bar__time__left 
  display: inline-block
  font-size: 20px
  width: 70px
  padding: 0 3px
  text-align: center
  color: rgba(112,25,202)
  position: absolute
  left: 0
  overflow: hidden
  z-index: 1

.bar__time__right
  display: inline-block
  font-size: 20px
  width: 70px
  padding: 0 3px
  text-align: center
  color: rgba(112,25,202)
  position: absolute
  right: 0
  overflow: hidden
  z-index: 1

.waveform 
  display: flex
  align-items: center
  height: 50%
  width: calc(100% - 150px)  
  margin: 0 auto
  position: relative
  overflow: visible 
  cursor: pointer

.waveform__bar 
  width: 3px
  margin: 0 1px
  background: rgba(112,25,202,0.6)
  background: yelllow

.waveform__bar--filled 
  background: rgba(112,25,202)

.waveform__slider 
  position: absolute
  border-radius: 5px
  width: 3px
  height: 160px
  background: rgba(112,25,202)
  transition: left 0.1s
  cursor: pointer
  overflow: visible
  z-index: 1
  

.waveform__slider::before 
  content: ''
  position: absolute
  right: -18px
  width: 42px
  height: 42px
  background: rgba(112,25,202)
  border-radius: 50%
  top: 95%
  color: #fff
  font-size: 12px
  z-index: 1

  
.waveform__slider::after 
  content: var(--cur-time, '')
  position: absolute
  right: -21px
  width: 40px
  height: 40px
  top: 102%
  color: white
  font-size: 12px
  z-index: 1

.no-pointer-events 
  pointer-events: none


@media (max-width: 600px) 
  .bar 
    padding: 3px
    width: 160%
    display: flex
    align-items: center
    height: 100px !important
  
  .waveform 
    width: calc(100% - 100px)
    margin-left: auto
    margin-right: auto

  .bar__time__right
    display: inline-block
    width: 60px
    text-align: center
    color: #A71FC2
    position: absolute
    right: 0
    overflow: hidden
    z-index: 100005
    
  .bar__time__left 
    display: inline-block
    width: 40px
    text-align: center
    color: #A71FC2
    position: absolute
    left: 0
    overflow: hidden
    z-index: 100005
    
  .waveform__bar 
    width: 4px
    margin: 0 1px
  
@media (max-height: 600px)
  .bar
    height: 100px 
  .waveform__slider
    height: 120px  

  .waveform__slider::after 
    font-size: 14px
  
  .waveform__slider::after 
    top: 101%


   