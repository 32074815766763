.english-test-take-exam {
  font: 100%/1.5 Source Sans Pro, Arial, Helvetica Neue, Helvetica, 'sans-serif';

  --img-width: auto;
  --img-height: auto;
  --color-black: black;
  --color-green: #61fb69;
  --color-blue: #025fda;
  --color-white: white;

  --border-color: var(--color-blue);
  --text-color: var(--color-blue);
  --player-color: var(--color-blue);
  --background-color: transparent;
  
  
  text-align: center;
  background-color: var(--background-color);
  min-height: 100vh;
  color: var(--text-color);
  width: 100%;
  font-size: 1.2rem;
  height: 90vh;

  .checkmark-input {
    color: #000;
  }

  .take-exam-container {
    width: 100%;
    max-width: 100%;
    height: 100%;
    max-height: 100%;
    padding: 0;
    overflow-y: hidden;
    overflow-x: hidden;
    scroll-snap-type: y mandatory;

    .exam-questions-max-parent {
      height: 100%;
      width: 100%;
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      justify-content: flex-end;
      padding: 0.625em;

      scroll-snap-align: start;
      overflow: hidden;

      @media (max-width: 1100px) {
        padding-bottom: 2.5em;
        min-height: 95%;
        width: 100%;
      }
    }

    @media (max-width: 1100px) {
      height: 95%;
      width: 100%;
      max-width: 100%;
      padding: 0;
      overflow-y: scroll;
      overflow-x: hidden;
      scroll-snap-type: y mandatory;

      .exam-questions-max-parent {
        width: 100%;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding-left: 0.625em;
        padding-right: 0.625em;
        scroll-snap-align: start;
      }
    }
  }

  .--j-flex-end {
    justify-content: flex-end;
  }

  .--j-center {
    justify-content: center;
  }

  #exam-form {
    opacity: 1;
    transition: all 250ms ease;

    &__animateX {
      transform: translateX(-120%);
      transition: all 350ms ease;
    }

    &__animate-X {
      transform: translateX(120%);
      transition: all 150ms ease;
    }

    &__animateX-jump {
      transform: translateX(200%);
      transition: none;
    }

    &__animate-X-jump {
      transform: translateX(-200%);
      transition: none;
    }
  }

  .__opacity-0 {
    opacity: 0 !important;
    transition: all 500ms ease;
  }

  .respond-question-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    border: 1px solid var(--border-color);
    position: relative;
    top: -1.625em;
    background-color: transparent;
    width: 100%;
    margin: auto;
    height: 100%;
  }

  textarea {
    resize: none;
    width: 100%;
  }

  .command__text {
    font-weight: bold;
  }

  input,
  textarea {
    background-color: transparent;
    padding: 0.188px;
    text-align: left;
    color: var(--text-color);
  }

  .form-body {
    box-sizing: border-box;
    margin-top: -7em;
    padding-right: 0 !important;
    padding-left: 0 !important;

    @media (max-width: 1100px) {
      margin-top: -5em;
    }
  }

  .question-navigator {
    justify-content: flex-end;
    align-items: flex-end;
    display: flex;
    align-self: flex-end;
    gap: 2em;

    @media  (max-width: 1100px) {
      padding-top: 0.5em;
      gap: 2em;
    }
    


    .arrow-drop-down {
      width: 3.125em;
      height: 3.125em;
      margin: 0 auto;
      color: #e7e7e7;

      @media  (max-width: 1100px) {
       
        .arrow-drop-down {
          width: 2rem; /* Ajusta el ancho del elemento */
          height: 2rem; /* Ajusta la altura del elemento */
        }

        .next-question,
        .prev-question {
          font-size: 0.9rem; /* Ajusta el tamaño de fuente */
          min-width: 2.5rem; /* Ajusta el ancho mínimo */
          height: 1.5rem; /* Ajusta la altura */
        }
      }
    }

    .next-question,
    .next-question:focus,
    .prev-question,
    .prev-question:focus {
      color: var(--color-white);
      background-color: var(--color-blue);
      border-radius: 0.313em;
      min-width: 3.438em;
      font-size: 1.1rem;
      height: 1.688em;

      @media (min-width: 1500px) {
        font-size: 1.3em;
      }

      @media (min-width: 1600px) {
        font-size: 1.3em;
      }

      @media (min-width: 1700px) {
        font-size: 1.3em;
      }
    }

    .next-question {
      a {
        color: var(--color-white);
      }
    }
  }

  .tachometer-container {
    width: 100%;
    position: fixed;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;

    @media (max-width: 1100px) {
      .tachometer-container {
        position: relative; /* Cambia la posición a relativa */
        height: 100px; /* Ajusta la altura según tus necesidades */
      }

      .arch-speedometer {
        width: 80%; /* Ajusta el ancho al 80% */
        margin: 0 auto;
        transform: translateY(-8px);/* Centra horizontalmente */
      }
    }
  }

  .tachometer-replacement {
    width: 100%;
    height: auto;
    min-height: auto;

    &__no-image {
      width: 100%;
      height: 10%;

      @media screen and (max-width: 1100px) {
        .tachometer-replacement {
          height: 150px; /* Ajusta la altura según tus necesidades */
        }

        .tacho {
          transform: scale(0.8); /* Ajusta el tamaño del elemento */
          position: relative; /* Cambia la posición a relativa */
          top: 0; /* Elimina el desplazamiento hacia arriba */
          margin: 0 auto; /* Centra horizontalmente */
        }
      }
    }
  }

  //nuevos/7

  .header-question {
 
    width: 75%;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    padding-top: 3em;
    padding-left: 2em;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999;

    @media (max-width: 1100px) {
      padding-top: 9em;
      padding-left: 0;
      width: 97%;
      height: 10%;
      justify-content: flex-end;
      padding-left: 0.2em;
    }
  }
}