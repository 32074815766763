.Table {
  width: 100%;
  height: 400px;
  height: 100%;
  padding-top: 13px;
  
  }
.container {
  display: flex;
  position: relative;
  flex-grow: 1;
  flex-direction: column;
  justify-content: flex-start;
  height: 100%;
  width: 50%;
  padding-bottom: 5%;
  background-color: rgba(165, 207, 255, 0); 
}
.titlesTable {
  height: 16px;
  width: 100%;
  display: flex;
  background-color: rgb(36, 116, 236, 0.2);
 color:rgb(5, 245, 245);
 font-weight:100;
 flex-direction: row;
 justify-content: flex-start;
}
.titlesTable p {
  display: block;
  width: 100%;
  height: 100%;
  text-align: center;
}



@media (max-width: 1250px) {
  .Table {
    position: relative;
    width: 100%;
    height: auto;
  }


}

@media screen and (max-width: 900px) {
  .container {
    width: 100%;
  }

  
}


@media screen and (max-width: 600px) {
  .titlesTable {
    padding: 0 10px;
  }

}

@media screen and (max-width: 500px) {
  .titlesTable {
    padding: 0 10px;
  }

}
